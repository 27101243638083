import React, { useEffect, useState } from 'react';
import {
	Dialog,
	IconButton,
	DialogTitle,
	DialogContent,
	makeStyles,
	DialogContentText,
	Box,
	Grid,
	Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import HeadlineText from './HeadlineText';
// import AddUserForm from '../../forms/AddUserForm';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { toggleFlightModal, toggleGSTModal } from '../../reducers/UiReducer';
import LoginForm from './LoginForm';
import TitleText from './TitleText';
import BodyText from './BodyText';
import AddGSTForm from './AddGSTForm';
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		backdropFilter: 'blur(2px)',
		'&MuiDialog-paperScrollPaper': {
			borderRadius: 5,
		},
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(3),
		padding: 0,
		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(2.5),
		},
		color: theme.palette.grey[500],
	},
}));

const FlightDetailModal = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const { search } = useSelector((state) => state.home);
	const { gst } = useSelector((state) => state.ui);
	const [flight, setFlight] = useState(true);
	const [baggage, setBaggage] = useState(false);
	const [fare, setFare] = useState(false);
	const { createflight } = useSelector((state) => state.ui);
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const handleClose = () => {
		dispatch(toggleGSTModal(false));
	};
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	let appnt = [];
	// if (search) {
	// 	console.log('first');
	// 	search.data &&
	// 		search.data.Response &&
	// 		search.data.Response &&
	// 		search.data.Response.Results &&
	// 		search.data.Response.Results[0].map((app, index) => {
	// 			if (index === flightId) {
	// 				appnt.push({
	// 					...app,
	// 				});
	// 			}
	// 		});
	// }
	// useEffect(() => {
	// 	console.log('Hello Hi');
	// }, [createflight]);
	console.log('APPNT', appnt);
	return (
		<Dialog
			PaperProps={{
				style: { borderRadius: 10, maxWidth: 1500 },
			}}
			TransitionProps={{
				style: { backgroundColor: 'transparent' },
			}}
			onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			className={classes.root}
			open={gst}>
			<DialogTitle id='customized-dialog-title' onClose={handleClose}>
				{/* <Box textAlign='center'> */}
				<Box display='flex' justifyContent='space-between'>
					<TitleText>Add GST Details</TitleText>
					<IconButton
						aria-label='close'
						className={classes.closeButton}
						onClick={handleClose}>
						<CloseIcon style={{ color: '#0035F0' }} fontSize='large' />
					</IconButton>
				</Box>
				{/* </Box> */}
			</DialogTitle>
			<DialogContent>
				<AddGSTForm />
			</DialogContent>
		</Dialog>
	);
};

export default FlightDetailModal;
