import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Button,
	Box,
	InputAdornment,
	Tabs,
	Tab,
	withStyles,
	FormControlLabel,
	Checkbox,
	Divider,
	Grid,
	Avatar,
	Autocomplete,
	TextField as TextFieldTwo,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { Autocomplete } from 'formik-material-ui-lab';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import BodyText from './BodyText';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
// import { loginDispatch } from '../reducers/UsersReducer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SuccessModal from '../components/reusableComponents/SuccessModal';
import ErrorModal from '../components/reusableComponents/ErrorModal';
import {
	agentSignupDispatch,
	travellerSignupDispatch,
} from '../reducers/UserReducer';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { fetchcancelticket } from '../reducers/HomeReducer';
import { toggleCancelModal } from '../reducers/UiReducer';
// import { useDispatch } from 'react-redux';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const CancellationType = ['NoShow-1', 'FlightCancelled-2', 'Others-3'];

// function getStyles(name: string, personName: readonly string[], theme: Theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid rgba(0, 50, 91, 0.5)',
			},
		},
		'& .MuiFormHelperText-root': {
			backgroundColor: '#F6F8FB',
			margin: 0,
			paddingTop: 10,
		},
		'& .MuiSelect-icon': {
			color: '#E61855',
		},
		'& div': {
			fontSize: 18,
		},
		'& input': {
			padding: '12.5px 14px',
		},
		'& .MuiSelect-select': {
			padding: '12.5px 14px',
		},

		backgroundColor: 'white',
		// marginBottom: 20,
		width: '100%',
		// marginTop: 10,
		borderRadius: 15,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
			marginTop: 8,
		},
	},
	autoselectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
				borderRadius: 25,
			},
		},
		backgroundColor: 'white',
		marginBottom: 30,
		width: '100%',
		marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
			marginTop: 8,
		},
	},
	InputPropsStyle: {
		backgroundColor: '#F6F8FB',
		borderRadius: 8,
		fontSize: 18,
	},
	inputPropsStyle: {
		// padding: 14,
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#F6F8FB',
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		padding: 14,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
}));
// const country = ['INDIA', 'SRILANKA'];
const GreenCheckbox = withStyles({
	root: {
		color: '#39c9bb',
		'&$checked': {
			color: '#39c9bb',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const country = ['Jharkhand', 'Kolkata'];
const title = [
	'Refund with Airline Penalty-1',
	'Partial Cancellation-2',
	// 'Change Itinerary/Reissue-3',
];
const TicketCancelForm = () => {
	const classes = useStyles();
	// const theme = useTheme();
	const [tabvalue, setValue] = React.useState(0);
	//   const handleChange = (event, newValue) => {
	//     setValue(newValue);
	//   };
	const [check, setCheck] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const handleCheck = (event) => {
		setCheck(event.target.checked);
	};
	const { travellermodal, selectedBH } = useSelector((state) => state.ui);
	//   const {  } = useSelector((state) => state.user);
	const [currentStep, SetCurrentStep] = useState('Step 1');
	console.log('bookingDetails', JSON.parse(selectedBH.bookingDetails));
	const bookingDetails = JSON.parse(selectedBH.bookingDetails);
	console.log('ss', bookingDetails);

	const theme = useTheme();
	const [personName, setPersonName] = React.useState([]);
	const [personName2, setPersonName2] = React.useState([]);

	const handleChange = (event: SelectChangeEvent<typeof personName>) => {
		const {
			target: { value },
		} = event;
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};
	const handleChange2 = (event: SelectChangeEvent<typeof personName2>) => {
		const {
			target: { value },
		} = event;
		setPersonName2(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};
	const handleFormSubmit = (data, actions) => {
		console.log(data);
		let formData = {
			orderId: selectedBH.orderId,
			userEmail: selectedBH.userEmail,
			partiallyCancelled: data.RequestType === '1' ? 0 : 1,
			BookingId: bookingDetails?.Response.Response.BookingId,
			RequestType: data.RequestType,
			CancellationType: data.CancellationType,
			Sectors: personName2.map((item) => {
				return {
					Origin: item.split('-')[0],
					Destination: item.split('-')[1],
				};
			}),
			TicketId: personName,
			Remarks: data.remark,
		};
		console.log('formData', formData);
		dispatch(fetchcancelticket(formData));
		dispatch(toggleCancelModal(false));
	};
	console.log('first', personName);
	console.log('sec', personName2);
	return (
		<>
			<Formik
				initialValues={{}}
				validate={(values) => {
					const errors = {};

					// if (!values.currentpassword) {
					// 	errors.currentpassword = 'Required';
					// }
					// if (!values.newpassword) {
					// 	errors.newpassword = 'Required';
					// }
					// if (!values.confirmnewpassword) {
					// 	errors.confirmnewpassword = 'Required';
					// }

					return errors;
				}}
				onSubmit={handleFormSubmit}>
				{({ submitForm, isSubmitting, values, errors }) => (
					<>
						<Form>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ marginTop: 20 }}>
								<Grid item xs={12} lg={6}>
									{/* <h5>Title</h5> */}
									<Field
										component={TextField}
										select
										name='RequestType'
										type='text'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}>
										{title.map((item, index) => (
											<MenuItem key={index} value={item}>
												{item}
											</MenuItem>
										))}
									</Field>
								</Grid>
								<Grid item xs={12} lg={6}>
									{/* <h5>Title</h5> */}
									<Field
										component={TextField}
										select
										name='CancellationType'
										type='text'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}>
										{CancellationType.map((item, index) => (
											<MenuItem key={index} value={item}>
												{item}
											</MenuItem>
										))}
									</Field>
								</Grid>
							</Grid>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} lg={12}>
									<h5>Please Select Passenger</h5>
									<FormControl sx={{ m: 1, width: '100%' }}>
										{/* <InputLabel id="demo-multiple-chip-label">Select</InputLabel> */}
										<Select
											//   labelId="demo-multiple-chip-label"
											id='demo-multiple-chip'
											multiple
											value={personName}
											onChange={handleChange}
											input={<OutlinedInput id='select-multiple-chip' />}
											renderValue={(selected) => (
												<Box
													sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
													{selected.map((value) => (
														<Chip key={value} label={value} />
													))}
												</Box>
											)}
											MenuProps={MenuProps}>
											{bookingDetails &&
												bookingDetails?.Response?.Response?.FlightItinerary?.Passenger?.map(
													(item) => (
														<MenuItem
															key={item.FirstName}
															value={item.FirstName}
															//   style={getStyles(name, personName, theme)}
														>
															{`${item.FirstName}${item.Ticket.TicketId}`}
														</MenuItem>
													)
												)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} lg={12}>
									<h5>Please Select Refunded Sector</h5>
									<FormControl sx={{ m: 1, width: '100%' }}>
										{/* <InputLabel id="demo-multiple-chip-label">Select</InputLabel> */}
										<Select
											//   labelId="demo-multiple-chip-label"
											id='demo-multiple-chip'
											multiple
											value={personName2}
											onChange={handleChange2}
											input={<OutlinedInput id='select-multiple-chip' />}
											renderValue={(selected) => (
												<Box
													sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
													{selected.map((value) => (
														<Chip key={value} label={value} />
													))}
												</Box>
											)}
											MenuProps={MenuProps}>
											{bookingDetails &&
												bookingDetails?.Response?.Response?.FlightItinerary?.Segments?.map(
													(item) => (
														<MenuItem
															key={`${item.Origin.Airport.AirportCode}-${item.Destination.Airport.AirportCode}`}
															value={`${item.Origin.Airport.AirportCode}-${item.Destination.Airport.AirportCode}`}
															//   style={getStyles(name, personName, theme)}
														>
															{`${item.Origin.Airport.AirportCode}-${item.Destination.Airport.AirportCode}`}
														</MenuItem>
													)
												)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} lg={12}>
									<h5>Remark</h5>
									<Field
										component={TextField}
										name='remark'
										type='text'
										variant='outlined'
										className={classes.selectField}
										style={{ height: 80 }}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={2}></Grid>
								<Grid item xs={8}>
									<Button
										className={classes.createButton}
										onClick={submitForm}
										style={{ marginTop: 5, fontSize: 14 }}
										// disabled={isSubmitting}
									>
										Submit
									</Button>
								</Grid>
								<Grid item xs={2}></Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default TicketCancelForm;