import { axiosInstance } from '../Axios';
import axios from 'axios';
const { createSlice } = require('@reduxjs/toolkit');

const UatReducers = createSlice({
	name: 'uat',
	initialState: {
		success: false,
		error: null,
		loadinguat: false,
		balance: null,
	},
	reducers: {
		getbalanceRequest(state, action) {
			state.loadinguat = true;
		},
		getbalanceSuccess(state, action) {
			state.loadinguat = false;
			state.balance = action.payload;
			state.error = null;
		},
		getbalanceFail(state, action) {
			state.loadinguat = false;
			state.error = action.payload;
		},
	},
});

const { actions } = UatReducers;
const config = {
	headers: {
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': process.env.REACT_APP_APIKEY,
	},
};

export const { getbalanceRequest, getbalanceSuccess, getbalanceFail } = actions;

export const getbalanceDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(getbalanceRequest());
		let bodyData2 = {
			clientid: 'Test1001',
			clientsecret: '3jMA6M49B',
			agentid: '10006',
		};
		const { data } = await axios.post(
			'https://uat.srplusdigital.com/api/travel/getbalance',
			bodyData2
		);

		dispatch(getbalanceSuccess(data));
	} catch (error) {
		dispatch(
			getbalanceFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default UatReducers;
