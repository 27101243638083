import { axiosInstance } from '../Axios';
import { flightMarupAmount } from './HomeReducer';
import {
	toggleAPIFailedModal,
	toggleFDConfirmModal,
	toggleSessionExpiredModal,
} from './UiReducer';
import { walletBalanceAgentDispatch } from './UserReducer';

const { createSlice } = require('@reduxjs/toolkit');
export const storeData = async (key, value) => {
	try {
		const jsonValue = JSON.stringify(value);
		sessionStorage.setItem(`${key}`, jsonValue);
	} catch (e) {
		// saving error
	}
};

export const getData = async (key) => {
	try {
		const jsonValue = sessionStorage.getItem(`${key}`);
		return jsonValue != null ? JSON.parse(jsonValue) : null;
	} catch (e) {
		// error reading value
	}
};
const FDReducers = createSlice({
	name: 'user',
	initialState: {
		success: false,
		error: null,
		loadingfd: false,
		fdresult: null,
		fdsector: sessionStorage.getItem('sector')
			? JSON.parse(sessionStorage.getItem('sector'))
			: null,
		fdbook: null,
		fdbookDetail: null,
		fddebit: null,
		fdaddBooking: null,
		holdresult: null,
		holdBook: null,
	},
	reducers: {
		searchRequest(state, action) {
			state.loadingfd = true;
		},
		searchSuccess(state, action) {
			state.loadingfd = false;
			state.fdresult = action.payload;
			state.error = null;
		},
		searchFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		sectorRequest(state, action) {
			state.loadingfd = true;
		},
		sectorSuccess(state, action) {
			state.loadingfd = false;
			state.fdsector = action.payload;
			storeData('sector', action.payload);
			state.error = null;
		},
		sectorFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		bookRequest(state, action) {
			state.loadingfd = true;
		},
		bookSuccess(state, action) {
			state.loadingfd = false;
			state.fdbook = action.payload;
			state.error = null;
		},
		bookFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		bookDetailRequest(state, action) {
			state.loadingfd = true;
		},
		bookDetailSuccess(state, action) {
			state.loadingfd = false;
			state.fdbookDetail = action.payload;
			state.error = null;
		},
		bookDetailFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		debitRequest(state, action) {
			state.loadingfd = true;
		},
		debitSuccess(state, action) {
			state.loadingfd = false;
			state.fddebit = action.payload;
			state.error = null;
		},
		debitFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		addBookingRequest(state, action) {
			state.loadingfd = true;
		},
		addBookingSuccess(state, action) {
			state.loadingfd = false;
			state.fdaddBooking = action.payload;
			state.error = null;
		},
		addBookingFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		holdRequest(state, action) {
			state.loadingfd = true;
		},
		holdSuccess(state, action) {
			state.loadingfd = false;
			state.holdresult = action.payload;
			state.error = null;
		},
		holdFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
		holdBookRequest(state, action) {
			state.loadingfd = true;
		},
		holdBookSuccess(state, action) {
			state.loadingfd = false;
			state.holdBook = action.payload;
			state.error = null;
		},
		holdBookFail(state, action) {
			state.loadingfd = false;
			state.error = action.payload;
		},
	},
});

const { actions } = FDReducers;
const config = {
	headers: {
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': process.env.REACT_APP_APIKEY,
	},
};

export const {
	searchRequest,
	searchSuccess,
	searchFail,
	sectorRequest,
	sectorSuccess,
	sectorFail,
	bookRequest,
	bookSuccess,
	bookFail,
	bookDetailRequest,
	bookDetailSuccess,
	bookDetailFail,
	debitRequest,
	debitSuccess,
	debitFail,
	addBookingRequest,
	addBookingSuccess,
	addBookingFail,
	holdRequest,
	holdSuccess,
	holdFail,
	holdBookRequest,
	holdBookSuccess,
	holdBookFail,
} = actions;

export const fdSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(searchSuccess(null));
		dispatch(searchRequest());

		const { data } = await axiosInstance.post(
			'flyaiq/flightsearch',
			bodyData,
			config
		);
		dispatch(searchSuccess(data));
	} catch (error) {
		dispatch(
			searchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const HoldSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(holdRequest());

		const { data } = await axiosInstance.post(
			'FlyHold/flightsearch',
			bodyData,
			config
		);
		dispatch(holdSuccess(data));
	} catch (error) {
		dispatch(
			holdFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fdSectorDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(sectorRequest());

		const { data } = await axiosInstance.post(
			'flyaiq/flightroutes',
			bodyData,
			config
		);
		dispatch(sectorSuccess(data));
	} catch (error) {
		dispatch(
			sectorFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fdBookDispatch =
	(bodyData, history, markup) => async (dispatch) => {
		try {
			dispatch(bookRequest());

			const { data } = await axiosInstance.post(
				'flyaiq/flightbook',
				bodyData,
				config
			);
			dispatch(bookSuccess(data));
			if (data?.result?.status === 'success') {
				dispatch(flightMarupAmount(markup));
				let form = {
					bookingId: data.result.booking_id,
					orderId: bodyData.orderId,
					paymentId: bodyData.paymentId,
				};
				dispatch(fdBookDetailDispatch(form, history));
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
		} catch (error) {
			// dispatch(toggleSessionExpiredModal(true));
			dispatch(toggleSessionExpiredModal(true));
			dispatch(
				bookFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const fdBookDetailDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(bookDetailRequest());

		const { data } = await axiosInstance.post(
			'flyaiq/flightbookingdetail',
			bodyData,
			config
		);
		dispatch(bookDetailSuccess(data));
		if (data.status === 200) {
			history.push({
				pathname: '/ticketfd',
				state: data.result.data,
			});
		} else {
			dispatch(toggleSessionExpiredModal(true));
		}
	} catch (error) {
		dispatch(toggleSessionExpiredModal(true));
		dispatch(
			bookDetailFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fdDebitAmountDispatch =
	(bodyData, formData, history, agentlogin, formaddBooking) =>
	async (dispatch) => {
		try {
			dispatch(debitRequest());

			const { data } = await axiosInstance.post(
				'flight/debitflightpaymentforaiq',
				bodyData,
				config
			);

			dispatch(debitSuccess(data));
			if (data.status === 200) {
				dispatch(
					walletBalanceAgentDispatch(
						agentlogin && agentlogin.result.result.email,
						agentlogin && agentlogin.result.result.id
					)
				);
				if (formaddBooking.supplier === 'MFB') {
					dispatch(holdBookingDispatch(formData));
				} else {
					dispatch(fdBookDispatch(formData, history, bodyData));
				}
			} else {
				dispatch(toggleAPIFailedModal(true));
			}
			// if (data?.result?.status === 'success') {
			// 	let form = {
			// 		bookingId: data.result.booking_id,
			// 	};
			// 	dispatch(fdBookDetailDispatch(form, history));
			// }
		} catch (error) {
			dispatch(toggleAPIFailedModal(true));
			dispatch(
				debitFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FDAddBookingDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(addBookingRequest());

		const { data } = await axiosInstance.post(
			'flight/addbookingflight',
			bodyData,
			config
		);
		dispatch(addBookingSuccess(data));
		if (data.status === 200) {
			// dispatch(holdBookingDispatch(formDataHold));
			dispatch(toggleFDConfirmModal(true));
		} else {
			dispatch(toggleAPIFailedModal(true));
		}
		// if (data.status === 200) {
		// 	if (payment === 'offline') {
		// 		dispatch(togglePGModal(true));
		// 	} else if (payment === 'payworldline') {
		// 		dispatch(toggleEasebuzzModal(true));
		// 	}
		// } else {
		// 	dispatch(toggleAPIFailedModal(true));
		// }
	} catch (error) {
		dispatch(toggleAPIFailedModal(true));
		// dispatch(toggleAPIFailedModal(true));
		dispatch(
			addBookingFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const holdBookingDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(holdBookRequest());

		const { data } = await axiosInstance.post(
			'FlyHold/flightbooking',
			bodyData,
			config
		);
		dispatch(holdBookSuccess(data));
	} catch (error) {
		dispatch(
			holdBookFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export default FDReducers;
