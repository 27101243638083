const addINtBooking = (
	location,
	agentlogin,
	payment,
	payableAmout,
	priceDetails,
	selectedFlightINT,
	dispatch,
	FlightAddBookingDispatch,
	PGCharge,
	BookingFormData,
	TBOINTPA,
	fareQuote
) => {
	const FareQuotePrice = Number(
		Number(
			Math.round(fareQuote?.result?.Response?.Results?.Fare.Total_Amount)
		) -
			Number(
				Math.round(fareQuote?.result?.Response?.Results?.Fare.MFB_Discount)
			)
	);
	const OriginalPrice = Number(
		Number(Math.round(location.state.intFlight.Fare.Total_Amount)) -
			Number(Math.round(location.state.intFlight.Fare.MFB_Discount))
	);
	let formaddBooking = {
		orderId: location.state.orderID,
		supplier: location.state.intFlight.Supplier,
		userEmail: location.state.formData.email,
		userPhone: location.state.formData.phone,
		user_type: agentlogin && agentlogin.status === 200 ? 'agent' : 'guest',
		origin: location.state.returnBody.Segments[0].Origin,
		destination: location.state.returnBody.Segments[0].Destination,
		travelDate: location.state.returnBody.Segments[0].PreferredDepartureTime,
		returnDate: location.state.returnBody.Segments[1].PreferredDepartureTime,
		journeyType: 'roundtrip',
		origin1: location.state.returnBody.Segments[1].Origin,
		destination1: location.state.returnBody.Segments[1].Destination,
		travelDate1: '',
		origin2: '',
		destination2: '',
		travelDate2: '',
		origin3: '',
		destination3: '',
		travelDate3: '',
		PGCharge:
			payment === 'payworldline' ? Number((Number(TBOINTPA) * 2.5) / 100) : 0,
		class: 'Economy',
		nop: `${location.state.returnBody.AdultCount} | ${location.state.returnBody.ChildCount} | ${location.state.returnBody.InfantCount} `,
		airlineType: '',
		carrier: location.state.intFlight.AirlineCode
			? location.state.intFlight.AirlineCode
			: location.state.intFlight.Airline_Code,
		fareType: 'R',
		eTicketType: 'eTicket',
		actualFare:
			location.state.intFlight.Supplier === 'TBO'
				? Number(Math.round(location.state.intFlight.Fare.Total_Amount)) -
				  Number(Math.round(location.state.intFlight.Fare.markup)) -
				  Number(Math.round(location.state.intFlight.Fare.partnerMarkup)) -
				  Number(Math.round(location.state.intFlight.Fare.agentMarkup)) -
				  Number(Math.round(location.state.intFlight.Fare.MFB_Discount))
				: Number(Math.round(location.state.intFlight.Fares.Total_Amount)) -
				  Number(Math.round(location.state.intFlight.Fares.markup)) -
				  Number(Math.round(location.state.intFlight.Fares.partnerMarkup)) -
				  Number(Math.round(location.state.intFlight.Fares.agentMarkup)),
		markup:
			location.state.intFlight.Supplier === 'TBO'
				? Math.round(location.state.intFlight.Fare.markup)
				: Math.round(location.state.intFlight.Fares.markup),
		baseFare:
			location.state.intFlight.Supplier === 'TBO'
				? Number(Math.round(location.state.intFlight.Fare.Total_Amount)) -
				  Number(Math.round(location.state.intFlight.Fare.partnerMarkup)) -
				  Number(Math.round(location.state.intFlight.Fare.agentMarkup)) -
				  Number(Math.round(location.state.intFlight.Fare.MFB_Discount))
				: Number(Math.round(location.state.intFlight.Fares.Total_Amount)) -
				  Number(Math.round(location.state.intFlight.Fares.partnerMarkup)) -
				  Number(Math.round(location.state.intFlight.Fares.agentMarkup)),
		partnerMarkup:
			location.state.intFlight.Supplier === 'TBO'
				? Math.round(location.state.intFlight.Fare.partnerMarkup)
				: Math.round(
						location.state.intFlight.Fares.fdMarkup
							? location?.state?.intFlight?.Fares?.fdMarkup
							: location.state.intFlight.Fares.partnerMarkup
				  ),
		agentMarkup:
			location.state.intFlight.Supplier === 'TBO'
				? Math.round(location.state.intFlight.Fare.agentMarkup)
				: Math.round(location.state.intFlight.Fares.agentMarkup),
		totalFare:
			location.state.intFlight.Supplier === 'TBO'
				? Number(Math.round(location.state.intFlight.Fare.Total_Amount)) -
				  Number(Math.round(location.state.intFlight.Fare.agentMarkup)) -
				  Number(Math.round(location.state.intFlight.Fare.MFB_Discount))
				: Number(Math.round(location.state.intFlight.Fares.Total_Amount)) -
				  Number(Math.round(location.state.intFlight.Fares.agentMarkup)),
		payableAmount:
			payment === 'payworldline'
				? Number(payableAmout)
				: Number(payableAmout) - Number(Number((Number(TBOINTPA) * 2.5) / 100)),
		ticketCost:
			payment === 'payworldline'
				? Number(payableAmout) +
				  Number(Math.round(location.state.intFlight.Fare.agentMarkup))
				: Number(payableAmout) - Number(Number((Number(TBOINTPA) * 2.5) / 100)),
		totalEarn: 0,
		cashBack: '0.00',
		couponDiscount: 0.0,
		couponDetails: '',
		pricingDetails: priceDetails,
		selectedFlight: selectedFlightINT,
		airlineType: location.state.intFlight.Airline_Code
			? location.state.intFlight.Block_Ticket_Allowed
				? 'LCC'
				: 'GDS'
			: location.state.intFlight.IsLCC
			? 'LCC'
			: 'GDS',
		selectedPax: BookingFormData,
		availableOnNewPrice:
			Number(FareQuotePrice) > Number(OriginalPrice)
				? true
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? true
				: false,
		priceChange: Number(
			Number(Math.round(FareQuotePrice)) - Number(Math.round(OriginalPrice))
		),
		priceValue:
			Number(Math.round(FareQuotePrice)) > Number(Math.round(OriginalPrice))
				? 'PRICEUP'
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? 'PRICEDOWN'
				: 'NOCHANGE',
		ticketSent: 0,
		paymentMode: agentlogin
			? payment === 'offline'
				? 'OFFLINE'
				: 'ONLINE'
			: 'ONLINE',
		agent_id:
			agentlogin && agentlogin.status === 200
				? agentlogin && agentlogin.result.result.id
				: 0,
	};
	dispatch(FlightAddBookingDispatch(formaddBooking, payment));
	// console.log("add booking", formaddBooking);
};
export default addINtBooking;
