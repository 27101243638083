const LastNameValidation = (lastname) => {
	let errorM =
		'Last name can not contain title (Mr, Mstr, Mrs, Ms, Miss, Master, DR, CHD, MST, PROF, Inf) in beginning.';
	if (lastname === 'MR') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'mr') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Mr') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	}
	if (lastname === 'MRS') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'mrs') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Mrs') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	}
	if (lastname === 'MSTR') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'mstr') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Mstr') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	}
	if (lastname === 'MS') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'ms') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Ms') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	}
	if (lastname === 'MISS') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'miss') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Miss') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	}
	if (lastname === 'MASTER') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'master') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Master') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	}
	if (lastname === 'DR') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'dr') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Dr') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	}
	if (lastname === 'CHD') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'chd') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Chd') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	}
	if (lastname === 'MST') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'mst') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Mst') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	}
	if (lastname === 'PROF') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'prof') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else if (lastname === 'Prof') {
		return {
			isValid: true,
			errorMessage: errorM,
		};
	} else {
		return {
			isValid: false,
		};
	}
};

export default LastNameValidation;
