import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery,useTheme,Box,Divider } from "@material-ui/core";
import { hotelInfoDispatch, hotelRoomInfoDispatch } from "../../reducers/HotelReducer";
import "./searchItem.css";

const ReviewHotelItem = ({item,i}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const {hotelresult} = useSelector((state)=>state.hotel)
  return (
    <div className="searchItem" style={{display:matches?'':'flex'}}>
      <img
        src={item.HotelPicture}
        alt=""
        className="siImg"
      />
      <div className="siDesc">
        <h1 className="siTitle">{item.HotelName}</h1>
        <span className="siDistance">{item.HotelAddress}</span>
        <span className="siTaxiOp">{i.RoomPromotion}</span>
        <span className="siSubtitle">
          Studio Apartment with Air conditioning
        </span>
        <span className="siFeatures">
          Entire studio • 1 bathroom • 21m² 1 full bed
        </span>
        <span className="siCancelOp">Free cancellation </span>
        <span className="siCancelOpSubtitle">
          You can cancel later, so lock in this great price today!
        </span>
      </div>
      <div className="siDetails" style={{justifyContent:'none'}}>
        <div className="siRating">
          <span>Excellent</span>
          <button>{item.StarRating}</button>
        </div>
        <div className="siDetailTexts">
          {location?.state?.room?.map((i,index)=>{
            return <>
            <Divider style={{marginTop:index===0?25:0,background:'grey'}}/>
          <Box display='flex'  style={{justifyContent:'space-around'}}>
         
          <span className="siPrice" style={{fontSize:18}}>{`Room ${index+1}: `}</span>
          {/* <span className="siPrice" style={{fontSize:18}}>{index===0?location?.state?.adultH[0]:index===1?location?.state?.adultH[1]:index===2?location?.state?.adultH[2]:index===3?location?.state?.adultH[3]:''} Adult, {index===0?location?.state?.childH[0]:index===1?location?.state?.childH[1]:index===2?location?.state?.childH[2]:index===3?location?.state?.childH[3]:''} Child</span> */}
          </Box>
          <Divider style={{background:'grey'}}/>
            </>
          })}
        
        </div>
      </div>
    </div>
  );
};

export default ReviewHotelItem;
