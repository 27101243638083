import React from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Grid,
  Box,
  Divider
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleCancellationPolicyModal,
} from "../../reducers/UiReducer";
import BodyText from "./BodyText";
import { useHistory, useLocation } from "react-router-dom";
import TitleText from "./TitleText";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    backdropFilter: "blur(2px)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      top: theme.spacing(2.5),
    },
    color: theme.palette.grey[500],
  },
  createButton: {
    backgroundColor: "#E61855",
    color: "white",
    borderColor: "#E61855",
    borderRadius: 15,
    width: "100%",
    padding: 14,
    fontSize: 16,
    marginBottom: 20,
    "&:hover": {
      backgroundColor: "#E61855",
    },
  },
}));

const CancellationPolicyModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    cancellationpolicy,
    selectedRoomInfo
  } = useSelector((state) => state.ui);
  const handleClose = () => {
    dispatch(toggleCancellationPolicyModal(false));
  };
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
      TransitionProps={{
        style: { backgroundColor: "transparent" },
      }}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      className={classes.root}
      open={cancellationpolicy}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogContent
        style={{
          paddingBottom: 20,
        }}
      >
        <TitleText textStyle={{ fontWeight: 700, textAlign: "center" }}>
        </TitleText>
        <h2 style={{ textAlign: "center", color: "rgb(239, 51, 57)" }}>
          Cancellation Policy
        </h2>
        <Divider style={{background:'grey'}}/>
        <Box display="flex" justifyContent="space-evenly" mt={'10px'}>
          <Box>
            <span style={{color:'rgb(12, 47, 84)',fontWeight:'bold'}}>Charge:</span>
            <span style={{marginLeft:10,color:'rgb(12, 47, 84)',fontWeight:'bold'}}>₹ {selectedRoomInfo?.CancellationPolicies[0]?.Charge}</span>
          </Box>
          <Box>
            <span style={{color:'rgb(12, 47, 84)',fontWeight:'bold'}}>Currency:</span>
            <span style={{marginLeft:10,color:'rgb(12, 47, 84)',fontWeight:'bold'}}>{selectedRoomInfo?.CancellationPolicies[0]?.Currency}</span>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-evenly" mt={'10px'}>
          <Box>
            <span style={{color:'rgb(12, 47, 84)',fontWeight:'bold'}}>FromDate:</span>
            <span style={{marginLeft:10,color:'rgb(12, 47, 84)',fontWeight:'bold'}}>{selectedRoomInfo?.CancellationPolicies[0]?.FromDate}</span>
          </Box>
          <Box>
            <span style={{color:'rgb(12, 47, 84)',fontWeight:'bold'}}>ToDate:</span>
            <span style={{marginLeft:10,color:'rgb(12, 47, 84)',fontWeight:'bold'}}>{selectedRoomInfo?.CancellationPolicies[0]?.ToDate}</span>
          </Box>
        </Box>
        <p style={{marginTop:20,textAlign:'center',color:'rgb(12, 47, 84)'}}>
        {selectedRoomInfo?.CancellationPolicy}
        </p>
        <Grid container style={{ justifyContent: 'center' }}>
					<Box
						textAlign='center'
						style={{ cursor: 'pointer' }}
						mt={2}
						onClick={() => dispatch(toggleCancellationPolicyModal(false))}>
						<BodyText textStyle={{ color: '#E61855', fontWeight: 600 }}>
							Cancel
							<Divider style={{ backgroundColor: '#E61855' }} />
						</BodyText>
					</Box>
				</Grid>
      </DialogContent>
     
    </Dialog>
  );
};

export default CancellationPolicyModal;
