import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, useTheme, useMediaQuery, Divider } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 1000,
	bgcolor: 'background.paper',
	// border: '2px solid #000',
	// boxShadow: 24,
	outline: 'none',
	boxShadow: '0px 4px 27px 0px #00000012',
	p: 3,
	borderRadius: 2,
};

export default function LastNameFormatModal({ open, handleOpen, handleClose }) {
	const [status, SetStatus] = useState('login');
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { fareQuote } = useSelector((state) => state.home);

	return (
		<div>
			<Modal
				open={open}
				sx={{ padding: 10 }}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: matches ? '100%' : 1000,
						bgcolor: 'background.paper',
						outline: 'none',
						boxShadow: '0px 4px 27px 0px #00000012',
						p: 3,
						borderRadius: 2,
					}}>
					<h2
						id='parent-modal-title'
						style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>
						LastNameFormat
					</h2>
					<Divider />
					<div
						style={{ fontSize: 14, marginTop: 20 }}
						dangerouslySetInnerHTML={{
							__html:
								fareQuote &&
								fareQuote?.result &&
								fareQuote?.result?.Response &&
								fareQuote?.result?.Response?.Results?.LastNameFormat,
						}}
					/>
				</Box>
			</Modal>
		</div>
	);
}
