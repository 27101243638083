import React from 'react';
import { Box } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import {
	travellerloginSuccess,
	agentloginSuccess,
	walletSuccess,
	walletagentSuccess,
} from '../../reducers/UserReducer';
import { setSection, setSiderBar } from '../../reducers/UiReducer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const TopNav = ({ handleTab, tab }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const { section } = useSelector((state) => state.ui);
	const { agentlogin } = useSelector((state) => state.user);
	// console.log('location', location);
	return (
		<>
			<nav className='navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4'>
				<div className='d-flex align-items-center'>
					<i
						onClick={() => {
							tab ? handleTab(false) : handleTab(true);
						}}
						className='fas fa-align-left primary-text fs-4 me-3'
						id='menu-toggle'></i>
					<h2 className='fs-5 m-0'>{location?.state?.title}</h2>
				</div>
				<Box display='flex' ml={'25px'}>
					<Box
						display='flex'
						alignItems='center'
						onClick={() => {
							dispatch(setSection('flight'));
							history.push('/');
						}}>
						<i
							class='fas fa-plane-departure'
							style={{
								color: section === 'flight' ? 'orange' : '#0A95FF',
								cursor: 'pointer',
							}}></i>
						<span
							style={{
								color: section === 'flight' ? 'orange' : '#0A95FF',
								marginLeft: 5,
								cursor: 'pointer',
							}}>
							Flight
						</span>
					</Box>
					{/* <Box
						display='flex'
						alignItems='center'
						ml={'20px'}
						onClick={() => {
							dispatch(setSection('bus'));
							history.push('/');
						}}>
						<i
							class='fas fa-bus'
							style={{
								color: section === 'bus' ? 'orange' : '#0A95FF',
								cursor: 'pointer',
							}}></i>
						<span
							style={{
								color: section === 'bus' ? 'orange' : '#0A95FF',
								marginLeft: 5,
								cursor: 'pointer',
							}}>
							Buses
						</span>
					</Box>
					<Box
						display='flex'
						alignItems='center'
						ml={'20px'}
						onClick={() => {
							dispatch(setSection('hotel'));
							history.push('/');
						}}>
						<i
							class='fas fa-building'
							style={{
								color: section === 'hotel' ? 'orange' : '#0A95FF',
								cursor: 'pointer',
							}}></i>

						<span
							style={{
								color: section === 'hotel' ? 'orange' : '#0A95FF',
								marginLeft: 5,
								cursor: 'pointer',
							}}>
							Hotels
						</span>
					</Box>

					<Box
						display='flex'
						alignItems='center'
						ml={'20px'}
						mr={'30px'}
						onClick={() => history.push('/')}>
						<a
							href='https://www.marinersforex.com/holiday-packages'
							target='_blank'>
							<i
								class='fas fa-umbrella-beach'
								style={{ color: '#0A95FF', cursor: 'pointer' }}></i>
							<span
								style={{ color: '#0A95FF', marginLeft: 5, cursor: 'pointer' }}>
								Holidays
							</span>
						</a>
					</Box> */}
				</Box>
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'>
					<span className='navbar-toggler-icon'></span>
				</button>

				<div className='collapse navbar-collapse' id='navbarSupportedContent'>
					<ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
						<li className='nav-item dropdown'>
							<a
								style={{ fontSize: 14 }}
								className='nav-link dropdown-toggle second-text fw-bold'
								href='#'
								id='navbarDropdown'
								role='button'
								data-bs-toggle='dropdown'
								aria-expanded='false'>
								<i className='fas fa-user me-2'></i>
								{agentlogin && agentlogin?.result?.result?.name}
							</a>
							<ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
								<li>
									<div
										onClick={() => {
											history.push('/editprofile');
											dispatch(setSiderBar('project'));
										}}
										className='dropdown-item'
										style={{ cursor: 'pointer' }}>
										Profile
									</div>
								</li>
								<li>
									<div className='dropdown-item' style={{ cursor: 'pointer' }}>
										Settings
									</div>
								</li>
								<li>
									<div
										className='dropdown-item'
										style={{ cursor: 'pointer' }}
										onClick={() => {
											dispatch(travellerloginSuccess(null));
											dispatch(agentloginSuccess(null));
											dispatch(walletSuccess(null));
											dispatch(walletagentSuccess(null));
											sessionStorage.removeItem('userInfoAgent');
											history.push('/');
										}}>
										Logout
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</nav>
		</>
	);
};

export default TopNav;
