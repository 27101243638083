import React from 'react';
import {
	Dialog,
	DialogContent,
	makeStyles,
	Grid,
	Button,
	Box,
} from '@material-ui/core';
import ErrorModalIcon from '../../assets/images/ErrorModalIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
	toggleErrorModal,
	setErrorMessage,
	toggleBPNRModal,
	togglePriceChangeModal,
	setPriceSection,
} from '../../reducers/UiReducer';
// import HeadlineText from './HeadlineText';
import BodyText from './BodyText';
import { useHistory, useLocation } from 'react-router-dom';
import TitleText from './TitleText';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		backdropFilter: 'blur(2px)',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(2.5),
		},
		color: theme.palette.grey[500],
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		padding: 14,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
}));

const PriceChangeModal = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { agentlogin } = useSelector((state) => state.user);
	const { farequotetripjack } = useSelector((state) => state.tripjack);
	const {
		errorModal,
		errorMessage,
		priceChange,
		bookingmessage,
		selectedOrigin,
		selectedDepart,
		origin,
	} = useSelector((state) => state.ui);
	const { fareQuoteEtrav, fareQuote, fareQuoteReturn } = useSelector(
		(state) => state.home
	);
	const handleClose = () => {
		dispatch(toggleBPNRModal(false));

		dispatch(setErrorMessage(''));
	};
	let etrav2 = location.state.selectedonward
		? location.state.selectedonward[0].Airline_Code &&
		  location.state.selectedReturn[0].Airline_Code
		: '';
	let etrvotbor = location.state.selectedonward
		? location.state.selectedonward[0].Airline_Code &&
		  location.state.selectedReturn[0].AirlineCode
		: '';
	let etravrtboo = location.state.selectedonward
		? location.state.selectedReturn[0].Airline_Code &&
		  location.state.selectedonward[0].AirlineCode
		: '';
	let tbo2 = location.state.selectedonward
		? location.state.selectedonward[0].AirlineCode &&
		  location.state.selectedReturn[0].AirlineCode
		: '';
	console.log('location', location);
	let formData = {
		AdultCount: location.state.body?.AdultCount,
		ChildCount: location.state.body?.ChildCount,
		InfantCount: location.state.body?.InfantCount,
		DirectFlight: false,
		OneStopFlight: false,
		JourneyType: 1,
		PreferredAirlines: null,
		Segments: [
			{
				Origin: selectedOrigin.airport_code,
				Destination: selectedDepart.airport_code,
				FlightCabinClass: 1,
				PreferredDepartureTime: origin,
			},
		],
		Sources: 'WEB',
		agent_id: agentlogin
			? agentlogin.status === 200
				? agentlogin?.result.result.id
				: 0
			: 0,
	};
	return (
		<Dialog
			PaperProps={{
				style: {
					borderRadius: 20,
				},
			}}
			TransitionProps={{
				style: { backgroundColor: 'transparent' },
			}}
			// onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			className={classes.root}
			open={priceChange}
			fullWidth={true}
			maxWidth={'sm'}>
			<DialogContent
				style={{
					paddingBottom: 20,
					paddingTop: 50,
				}}>
				<TitleText textStyle={{ fontWeight: 700, textAlign: 'center' }}>
					{/* Oops! */}
				</TitleText>
				<h2 style={{ textAlign: 'center', color: 'rgb(239, 51, 57)' }}>
					Flight Fare has been Changed
				</h2>
				<h2
					style={{
						textAlign: 'center',
						color: 'rgb(239, 51, 57)',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					Total Amount
				</h2>

				<Box display='flex' justifyContent='space-evenly'>
					<h2 style={{ textDecoration: 'line-through' }}>{`₹${
						location.state.OneflightData
							? location.state.OneflightData?.Fares
								? location?.state?.OneflightData?.Fares.Total_Amount
								: location.state.OneflightData?.Fare
								? Number(
										Number(location.state.OneflightData.Fare.Total_Amount) -
											Number(location.state.OneflightData.Fare.MFB_Discount)
								  )
								: location.state.OneflightData.Supplier === 'TJK'
								? location.state?.OneflightData?.totalPriceList?.fd
										?.Total_Amount
								: ''
							: tbo2
							? Number(
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.Total_Amount
										)
									) +
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.Total_Amount
											)
										) -
										Number(
											Number(
												Math.round(
													location.state.selectedonward[0].Fare.MFB_Discount
												)
											) +
												Number(
													Math.round(
														location.state.selectedReturn[0].Fare.MFB_Discount
													)
												)
										)
							  )
							: etrav2
							? Number(
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.Total_Amount
										)
									) +
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.Total_Amount
											)
										)
							  )
							: location.state.intFlight
							? location.state.intFlight.Supplier === 'TBO'
								? Number(
										Number(location.state.intFlight.Fare.Total_Amount) -
											Number(location.state.intFlight.Fare.MFB_Discount)
								  )
								: location.state.intFlight.Supplier === 'ETR'
								? Number(
										Math.round(location.state.intFlight.Fares.Total_Amount)
								  )
								: ''
							: ''
					}`}</h2>
					<h2>{`₹${
						location.state.OneflightData
							? fareQuoteEtrav
								? fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight.Fares
										.Total_Amount
								: location.state.OneflightData.Supplier === 'TJK'
								? farequotetripjack?.result?.tripInfos[0]?.totalPriceList.fd
										.Total_Amount
								: fareQuote?.result?.Response?.Results.Fare.Total_Amount
							: tbo2
							? Number(
									Number(
										Math.round(
											fareQuote?.result?.Response?.Results?.Fare.Total_Amount
										)
									) +
										Number(
											Math.round(
												fareQuoteReturn?.result?.Response?.Results.Fare
													.Total_Amount
											)
										) -
										Number(
											Number(
												Math.round(
													fareQuote?.result?.Response?.Results?.Fare
														.MFB_Discount
												)
											) +
												Number(
													Math.round(
														fareQuoteReturn?.result?.Response?.Results.Fare
															.MFB_Discount
													)
												)
										)
							  )
							: etrav2
							? Number(
									Number(
										Math.round(
											fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight
												.Fares.Total_Amount
										)
									) +
										Number(
											Math.round(
												fareQuoteEtrav?.result?.AirRepriceResponses[1].Flight
													.Fares.Total_Amount
											)
										)
							  )
							: location.state.intFlight
							? location.state.intFlight.Supplier === 'TBO'
								? Number(
										Number(
											Math.round(
												fareQuote
													? fareQuote?.result?.Response?.Results.Fare
															.Total_Amount
													: location.state.intFlight.Fare.Total_Amount
											)
										) -
											Number(
												Math.round(
													fareQuote
														? fareQuote?.result?.Response?.Results.Fare
																.MFB_Discount
														: location.state.intFlight.Fare.MFB_Discount
												)
											)
								  )
								: location.state.intFlight.Supplier === 'ETR'
								? Number(
										Math.round(
											Number(
												Number(
													Math.round(
														fareQuoteEtrav
															? fareQuoteEtrav?.result?.AirRepriceResponses[0]
																	.Flight.Fares.Total_Amount
															: location.state.intFlight.Fares.Total_Amount
													)
												) +
													Number(
														Math.round(
															(Number(
																Number(
																	Math.round(
																		fareQuoteEtrav
																			? fareQuoteEtrav?.result
																					?.AirRepriceResponses[0].Flight.Fares
																					.Total_Amount
																			: location.state.intFlight.Fares
																					.Total_Amount
																	)
																)
															) *
																2.5) /
																100
														)
													)
											)
										)
								  )
								: ''
							: ''
					}`}</h2>
				</Box>
				<h5 style={{ textAlign: 'center', color: 'rgb(239, 51, 57)' }}>
					Do you want to Continue?
				</h5>
			</DialogContent>
			<Grid container style={{ marginTop: 20 }}>
				<Grid item xs={2}></Grid>
				<Grid item xs={8}>
					<Button
						className={classes.createButton}
						onClick={() => {
							dispatch(togglePriceChangeModal(false));
							dispatch(setPriceSection(false));
						}}
						style={{ marginTop: 5, fontSize: 14 }}
						// disabled={isSubmitting}
					>
						PROCCED
					</Button>
				</Grid>
				<Grid item xs={2}></Grid>
			</Grid>
			<Grid container style={{ marginTop: 5 }}>
				<Grid item xs={2}></Grid>
				<Grid item xs={8}>
					<Button
						className={classes.createButton}
						onClick={() =>
							history.push({
								pathname: `/oneway/${selectedOrigin.airport_code}/${selectedDepart.airport_code}/${origin}`,
								state: {
									body: formData,
									OriginO: origin,
								},
							})
						}
						style={{ marginTop: 5, fontSize: 14 }}
						// disabled={isSubmitting}
					>
						Choose Another Flight
					</Button>
				</Grid>
				<Grid item xs={2}></Grid>
			</Grid>
		</Dialog>
	);
};

export default PriceChangeModal;
