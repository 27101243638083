import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import {
	agenttransactionhistoryDispatch,
	supplierGETDispatch,
} from '../../reducers/UserReducer';
import {
	setSelectedSector,
	setSiderBar,
	toggleEditSectorModal,
} from '../../reducers/UiReducer';
// import EditSectorModal from './EditSectorModal';
const tableHeaders = [
	'Origin',
	'Destination',
	'Departure Date',
	'Departure Time',
	'Arrival Time',
	'Seat',
	'Airline Name',
	'Airline Code',
	'Airline Number',
	'Fare',
	'Action',
];

const SectorList = () => {
	const { transagent, agentlogin, loadinguser, supplierlogin, sector } =
		useSelector((state) => state.user);
	const [val, setVal] = useState('');
	const [data, setData] = useState('');
	const [start, setSDate] = useState(new Date());
	const [end, setEDate] = useState(new Date());
	const dispatch = useDispatch();
	useEffect(() => {
		let form = {
			supplierId: `S-${supplierlogin && supplierlogin.result.result.id}`,
		};
		dispatch(supplierGETDispatch(form));
		dispatch(setSiderBar('sectorlist'));
	}, []);
	let tranData = [];
	if (loadinguser) {
		console.log('first');
	} else {
		// tranData= transagent &&
		// transagent.result &&
		// transagent.result.result?.length > 0 &&
		// transagent.result.result?.slice().sort((a,b)=>{
		//   return a.id - b.id
		// })
	}
	// console.log('tranData', tranData);
	const handleDate = (event) => {
		// console.log("first",event.target.value)
		setSDate(new Date(event.target.value));
	};
	const handleDate2 = (event) => {
		// console.log("first2",event.target.value)
		setEDate(new Date(event.target.value));
	};
	const handleFilter = () => {
		let results = [];
		results =
			transagent &&
			transagent.result &&
			transagent.result.result.filter((item) => {
				var datef = new Date(item.date);
				return datef >= start && datef <= end;
			});
		setData(results);
		console.log('results', results);
	};
	const handleSearch = (event) => {
		let value = event.target.value.toLowerCase();
		let results = [];
		console.log('filter', value);
		// setVal(value);

		if (event.target.value.toLowerCase() === 'new') {
			results =
				transagent &&
				transagent.result &&
				transagent.result.result?.length > 0 &&
				transagent.result.result?.slice().sort((a, b) => {
					return b.id - a.id;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'old') {
			results =
				transagent &&
				transagent.result &&
				transagent.result.result?.length > 0 &&
				transagent.result.result?.slice().sort((a, b) => {
					return a.id - b.id;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'lh') {
			results =
				transagent &&
				transagent.result &&
				transagent.result.result?.length > 0 &&
				transagent.result.result?.slice().sort((a, b) => {
					return a.amount - b.amount;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'hl') {
			results =
				transagent &&
				transagent.result &&
				transagent.result.result?.length > 0 &&
				transagent.result.result?.slice().sort((a, b) => {
					return b.amount - a.amount;
				});
			setData(results);
		} else {
			results =
				transagent &&
				transagent.result &&
				transagent.result.result.filter((item) => {
					return item.type.toLowerCase().includes(value.toLowerCase());
				});
			setData(results);
		}
		console.log('results', results);
	};
	console.log('Result', data);
	return (
		<>
			<div className='row ' style={{ padding: 15 }}>
				<div className='col'>
					<table className='table bg-white rounded shadow-sm  table-hover'>
						<thead>
							<tr>
								<th scope='col' width='50'>
									#
								</th>
								{tableHeaders.map((i) => {
									return (
										<th scope='col' style={{ fontFamily: 'monospace' }}>
											{i}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{data && data.length > 0
								? data.map((i, index) => {
										return (
											<tr>
												<th
													scope='row'
													style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{'1'}
												</th>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{'DEL'}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{'CCU'}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.doj}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.commission}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.markup}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.amount}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.avail_bal}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.payment_type}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.date.split(' ')[0]}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.hold_status}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.trans_section}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.description}
												</td>
											</tr>
										);
								  })
								: sector &&
								  sector.result?.length > 0 &&
								  sector.result?.map((i, index) => {
										return (
											<tr>
												<th
													scope='row'
													style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.id}
												</th>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.origin}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.destination}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.departureDate}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.departureTime}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.arrivalTime}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.seat}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.airlineName}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.airlineCode}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{i.airlineNumber}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{`₹${i.fare}`}
												</td>
												<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
													{/* {`₹${i.fare}`} */}
													<i
														class='fa-solid fa-eye'
														style={{ cursor: 'pointer' }}
														onClick={() => {
															// dispatch(toggleFlightDetailModal(true));
															// dispatch(setBookingHistoryID(i.id));
															//   dispatch(toggleFlightDetailModal(true));
															//   dispatch(setBookingHistoryID(i.id));
														}}></i>
													<i
														class='fas fa-edit'
														style={{ cursor: 'pointer', marginLeft: 10 }}
														onClick={() => {
															// dispatch(setSelectedSector(i));
															// dispatch(toggleEditSectorModal(true));
														}}></i>
												</td>
											</tr>
										);
								  })}
						</tbody>
					</table>
				</div>
			</div>
			{/* <EditSectorModal /> */}
		</>
	);
};

export default SectorList;
