import HomeReducer from './HomeReducer';
import BusReducer from './BusReducer';
import UiReducer from './UiReducer';
import EtravReducer from './EtravReducer';
import UserReducer from './UserReducer';
import TripjackReducer from './TripjackReducer';
import HotelReducer from './HotelReducer';
import FDReducer from './FDReducer';
import UatReducers from './UatReducer';

const rootReducer = {
	ui: UiReducer.reducer,
	home: HomeReducer.reducer,
	bus: BusReducer.reducer,
	etrav: EtravReducer.reducer,
	user: UserReducer.reducer,
	tripjack: TripjackReducer.reducer,
	hotel: HotelReducer.reducer,
	promo: FDReducer.reducer,
	uat: UatReducers.reducer,
};

export default rootReducer;
