import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Box } from '@material-ui/core';
import Slide from '@mui/material/Slide';
// import '../../sass/pages/_home.scss';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch, useSelector } from 'react-redux';
import {
	setSeatSelected,
	setSelectedformData,
	setSeatSelected2,
	setSelectSeat,
} from '../../reducers/UiReducer';
import { useLocation } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement,
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});
const select = ['2E', '3E'];
export default function FullScreenSeat({
	nameindex,
	items,
	indexN,
	setFieldValue,
	type,
}) {
	const [open, setOpen] = React.useState(false);
	const { ssrTBO } = useSelector((state) => state.home);
	const { selectedSeats, selectedSeatItem, selectedSeats2, selectedSeatItem2 } =
		useSelector((state) => state.ui);
	const [selectedSeat, setSelectedSeat] = useState({});
	const location = useLocation();
	console.log('selectedSeats', selectedSeats);
	console.log('items', items);
	const dispatch = useDispatch();
	// const { errors, setFieldValue } = formik;
	const [condition, setCondition] = useState(false);
	const [seat, setSeat] = useState();
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleChange = (e) => {
		let { name, checked, type, value } = e.target;
		console.log('name', name);
		console.log('checked', checked);
		console.log('type', type);
		console.log('value', value);

		setSelectedSeat({
			// ...selectedSeat,
			[name]: (type = 'checkbox' ? checked : ''),
		});
	};
	console.log('selectedSeat', selectedSeat);
	let selectSeat = [];

	if (seat && seat.length > 0 && ssrTBO) {
		items?.RowSeats?.map((i) => {
			i.Seats.map((s) => {
				seat.map((it) => {
					if (it === s.Code) {
						// dispatch(setSelectSeat(s))
						selectSeat.push({
							...s,
						});
					}
				});
			});
		});
	}
	console.log('selectSeat', selectSeat);
	const handleClose = () => {
		setOpen(false);
		dispatch(setSeatSelected(null));
		// dispatch(setSelectedformData(formData));
	};
	useEffect(() => {
		console.log('selectedSeat', selectedSeat);
		// if (selectedSeats.length < formData.length) {
		// for (let x in selectedSeat) {
		// 	console.log('selectedSeat[x]', selectedSeat[x]);
		// 	if (selectedSeat[x] === false) {
		// 		setCondition(false);
		// 		continue;
		// 	} else {
		// 		setCondition(true);
		// 		break;
		// 	}
		// }

		// const a = [];
		// for (let y in selectedSeat) {
		// 	console.log('y', y);
		// 	if (selectedSeat[y] == false) {
		// 		continue;
		// 	} else {
		// 		a.push(y);
		// 	}
		// }

		// setSeat(a);

		// dispatch(setSelectSeat(a));
		// }
	}, [selectedSeat]);
	if (selectedSeats) {
		selectedSeats.map((i, index) => {
			console.log('fjfdjjf', i);
		});
	}

	// console.log('formData', formData);
	console.log('selectedSeatItem', selectedSeatItem);
	console.log('selectedSeatItem2', selectedSeatItem2);
	console.log('selectedSeats', selectedSeats);
	let newData = [];
	if (true)
		return (
			<div
				style={{
					// boxShadow: 'rgb(0 0 0 / 15%) 0px 0.5rem 1rem',
					background: 'white',
					padding: '10px',
					marginTop: '10px',
				}}>
				<Button variant='outlined' onClick={handleClickOpen}>
					Select Seat
				</Button>
				{/* {indexN === 0 ? (
					selectedSeats.length > 0 ? (
						<span style={{ marginLeft: 30 }}>
							Seat Preference:
							{selectedSeatItem?.map(
								(i) => `${i.Origin} - ${i.Destination} : ${i.Code},`
							)}
						</span>
					) : (
						''
					)
				) : (
					''
				)}
				{indexN === 1 ? (
					selectedSeats2.length > 0 ? (
						<span style={{ marginLeft: 30 }}>
							Seat Preference:
							{selectedSeatItem2?.map(
								(i) => `${i.Origin} - ${i.Destination} : ${i.Code},`
							)}
						</span>
					) : (
						''
					)
				) : (
					''
				)} */}
				<Dialog
					fullScreen
					open={open}
					onClose={handleClose}
					TransitionComponent={Transition}>
					<AppBar sx={{ position: 'relative' }}>
						<Toolbar>
							<IconButton
								edge='start'
								color='inherit'
								onClick={handleClose}
								aria-label='close'>
								<CloseIcon />
							</IconButton>
							<Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
								{`Seat Selection ${items?.RowSeats[0]?.Seats[0]?.Origin}-${items?.RowSeats[0]?.Seats[0]?.Destination}`}
							</Typography>
							<Button autoFocus color='inherit' onClick={handleClose}>
								Done
							</Button>
						</Toolbar>
					</AppBar>
					<Grid container style={{ alignItems: 'baseline' }}>
						<Grid item xs={1}></Grid>
						<Grid item xs={12} lg={3} style={{ paddingLeft: 20 }}>
							<Box>{/* <h4>Pax Details</h4> */}</Box>

							{/* {formData?.map((i, index) => {
								return (
									<>
										<Box
											style={{
												background: 'aliceblue',
												padding: '20px',
												marginTop: 10,
											}}>
											<span style={{ fontWeight: 'bold' }}>
												{' '}
												{`${i.title} ${i.firstname} ${i.lastname}`}
											</span>
											<Box>
												<span style={{ fontWeight: 'bold' }}>{`Seat Number: ${
													indexN === 0
														? selectedSeatItem?.length > 0
															? selectedSeatItem[index]?.Code
															: ''
														: indexN === 1
														? selectedSeatItem2?.length > 0
															? selectedSeatItem2[index]?.Code
															: ''
														: ''
												}, ₹${
													indexN === 0
														? selectedSeatItem?.length > 0
															? selectedSeatItem[index]?.Price
															: ''
														: indexN === 1
														? selectedSeatItem2?.length > 0
															? selectedSeatItem2[index]?.Price
															: ''
														: ''
												}`}</span>
												<br></br>

												<br></br>
											</Box>
										</Box>
									</>
								);
							})} */}
						</Grid>
						<Grid item xs={12} lg={8}>
							<div className='plane'>
								<div className='cockpit'>
									<h1>Please select a seat</h1>
								</div>
								<div className='exit exit--front fuselage'></div>
								<ol className='cabin fuselage'>
									{items?.RowSeats?.map((i, index) => {
										return (
											<li className='row row--1'>
												<ol className='seats' type='A'>
													{index > 0
														? i.Seats.map((item, index) => {
																return (
																	<li className='seatss'>
																		<input
																			type='checkbox'
																			onClick={() => {
																				if (indexN === 0) {
																					if (selectedSeatItem.length < 1) {
																						dispatch(setSeatSelected(item));
																						dispatch(
																							setSeatSelected2({
																								item: item,
																								type: type,
																								index: nameindex,
																							})
																						);
																						setFieldValue(
																							`${type}.${nameindex}.seat`,
																							item
																						);
																					} else {
																						if (
																							selectedSeats.includes(item.Code)
																						) {
																							dispatch(setSeatSelected(item));
																							// dispatch(setSeatSelected2(item));
																						}
																					}
																				} else if (indexN === 1) {
																					setFieldValue(
																						`${type}.${nameindex}.seat`,
																						item
																					);
																					// if (
																					// 	selectedSeats2.length <
																					// 	formData.length
																					// ) {
																					// 	dispatch(setSeatSelected2(item));
																					// } else {
																					// 	if (
																					// 		selectedSeats2.includes(item.Code)
																					// 	) {
																					// 		dispatch(setSeatSelected2(item));
																					// 	}
																					// }
																				}
																			}}
																			name={`${type}.${nameindex}.seat`}
																			checked={
																				indexN === 0
																					? selectedSeats.includes(item.Code)
																					: indexN === 1
																					? selectedSeats2.includes(item.Code)
																					: ''
																			}
																			value='fl'
																			id={`checkbox-id${item.Code}`}
																			disabled={
																				item.AvailablityType === 1
																					? false
																					: true
																			}
																		/>
																		<label
																			for={`checkbox-id${item.Code}`}
																			title={`₹${item.Price}`}>
																			{item.Code}
																		</label>
																	</li>
																);
														  })
														: ''}
												</ol>
											</li>
										);
									})}
								</ol>
								<div className='exit exit--back fuselage'></div>
							</div>
						</Grid>
					</Grid>
				</Dialog>
			</div>
		);
}
