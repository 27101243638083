import { axiosInstance } from '../Axios';
import { FlightMailTicketDispatch } from './HomeReducer';
import {
	loader,
	setBookingMessage,
	storeData,
	toggleAPIFailedModal,
	toggleBPNRModal,
	toggleSessionExpiredModal,
} from './UiReducer';
import { walletBalanceAgentDispatch } from './UserReducer';
const { createSlice } = require('@reduxjs/toolkit');

const EtravReducer = createSlice({
	name: 'Etrav',
	initialState: {
		success: false,
		error: null,
		load: false,
		ticket: null,
		balance: null,
		payment: null,
		reprint: null,
		reprintRound: null,
	},
	reducers: {
		ticketRequest(state, action) {
			state.load = true;
		},
		ticketSuccess(state, action) {
			state.load = false;
			state.ticket = action.payload;
			state.error = null;
		},
		ticketFail(state, action) {
			state.load = false;
			state.error = action.payload;
		},
		balanceRequest(state, action) {
			state.load = true;
		},
		balanceSuccess(state, action) {
			state.load = false;
			state.balance = action.payload;
			state.error = null;
		},
		balanceFail(state, action) {
			state.load = false;
			state.error = action.payload;
		},
		paymentRequest(state, action) {
			state.load = true;
		},
		paymentSuccess(state, action) {
			state.load = false;
			state.payment = action.payload;
			state.error = null;
		},
		paymentFail(state, action) {
			state.load = false;
			state.error = action.payload;
		},
		reprintRequest(state, action) {
			state.load = true;
		},
		reprintSuccess(state, action) {
			state.load = false;
			state.reprint = action.payload;
			state.error = null;
		},
		reprintFail(state, action) {
			state.load = false;
			state.error = action.payload;
		},
		reprintRoundRequest(state, action) {
			state.load = true;
		},
		reprintRoundSuccess(state, action) {
			state.load = false;
			state.reprintRound = action.payload;
			state.error = null;
		},
		reprintRoundFail(state, action) {
			state.load = false;
			state.error = action.payload;
		},
	},
});

const { actions } = EtravReducer;
const config = {
	headers: {
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		// 'Api-Key': 'NjO5hqe3PG9qSRplus==',
		// 'Api-Key': 'NjA4ZGFkYWQ2MjY4MA==',
		'Api-Key': process.env.REACT_APP_APIKEY,
	},
};

export const {
	ticketRequest,
	ticketSuccess,
	ticketFail,
	balanceRequest,
	balanceSuccess,
	balanceFail,
	paymentRequest,
	paymentSuccess,
	paymentFail,
	reprintRequest,
	reprintSuccess,
	reprintFail,
	reprintRoundRequest,
	reprintRoundSuccess,
	reprintRoundFail,
} = actions;

export const flightTicketEtravDispatch =
	(bodyData, history, states, agentlogin) => async (dispatch) => {
		try {
			dispatch(ticketRequest());

			const { data } = await axiosInstance.post(
				'FlyEtrav/flightticketing',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(ticketSuccess(data));
			if (data.status === 200) {
				dispatch(
					flightReprintEtravNewDispatch(
						data.result.Booking_RefNo,
						data.result.AirlinePNRDetails[0].AirlinePNRs[0].Airline_PNR,
						history,
						states,
						agentlogin
					)
				);
				// if (states.OneflightData) {
				// 	dispatch(
				// 		flightReprintEtravNewDispatch(
				// 			data.result.Booking_RefNo,
				// 			data.result.AirlinePNRDetails[0].AirlinePNRs[0].Airline_PNR,
				// 			history,
				// 			states,
				// 			agentlogin
				// 		)
				// 	);
				// } else {
				// 	dispatch(
				// 		flightReprintEtravDispatch(
				// 			data.result.Booking_RefNo,
				// 			data.result.AirlinePNRDetails[0].AirlinePNRs[0].Airline_PNR,
				// 			history,
				// 			states,
				// 			agentlogin
				// 		)
				// 	);
				// 	if (data.result.AirlinePNRDetails[1]) {
				// 		dispatch(
				// 			flightReprintEtravRoundDispatch(
				// 				data.result.Booking_RefNo,
				// 				data.result.AirlinePNRDetails[1].AirlinePNRs[0].Airline_PNR,
				// 				history,
				// 				states,
				// 				agentlogin
				// 			)
				// 		);
				// 	}
				// }

				let formMail = {
					orderId: states.orderID,
					userEmail: states.Passenger_Email,
				};
				dispatch(FlightMailTicketDispatch(formMail));
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
			// if (data.status === 200) {
			// 	history.push({ pathname: '/ticket', state: states });
			// 	let formData = {
			// 		userEmail: agentInfo.email,
			// 		userId: agentInfo.id,
			// 	};
			// 	dispatch(walletBalanceAgentDispatch(formData));
			// }
		} catch (error) {
			dispatch(toggleSessionExpiredModal(true));
			dispatch(
				ticketFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const flightBalanceEtravDispatch = () => async (dispatch) => {
	try {
		let bodyData = {};
		dispatch(balanceRequest());

		const { data } = await axiosInstance.post(
			'FlyEtrav/flightapibalance',
			bodyData,
			config
		);
		// console.log(data);
		dispatch(balanceSuccess(data));
	} catch (error) {
		dispatch(
			balanceFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const flightPaymentDebitEtravDispatch =
	(bodyData, id, history, states, agentlogin) => async (dispatch) => {
		try {
			dispatch(paymentRequest());

			const { data } = await axiosInstance.post(
				'FlyEtrav/flightpaymentdebit',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(paymentSuccess(data));
			if (data.status === 200) {
				let ticketData = {
					Booking_RefNo: id,
					Ticketing_Type: '1',
					orderId: states.orderId,
					paymentId: states.paymentId,
				};
				dispatch(
					flightTicketEtravDispatch(ticketData, history, states, agentlogin)
				);
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
		} catch (error) {
			dispatch(toggleSessionExpiredModal(true));
			dispatch(
				paymentFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const flightReprintEtravDispatch =
	(Booking_RefNo, Airline_PNR, history, states, agentlogin) =>
	async (dispatch) => {
		try {
			let formData = {
				Booking_RefNo: Booking_RefNo,
				Airline_PNR: Airline_PNR,
			};
			dispatch(reprintRequest());

			const { data } = await axiosInstance.post(
				'FlyEtrav/flightreprint',
				formData,
				config
			);
			// console.log(data);
			dispatch(reprintSuccess(data));
			if (data.status === 200) {
				if (
					states.selectedReturn[0]?.Supplier === 'TBO' &&
					states.selectedonward[0]?.Supplier === 'ETR'
				) {
					history.push({ pathname: '/ticketroundtripet', state: states });
				} else if (
					states.selectedReturn[0]?.Supplier === 'ETR' &&
					states.selectedonward[0]?.Supplier === 'TBO'
				) {
					history.push({ pathname: '/ticketroundtripte', state: states });
				} else if (
					states.selectedReturn[0]?.Supplier === 'ETR' &&
					states.selectedonward[0]?.Supplier === 'ETR'
				) {
					history.push({ pathname: '/ticketroundtrip', state: states });
				} else if (states.OneflightData.Airline_Code) {
					history.push({ pathname: '/ticket', state: states });
				}

				dispatch(
					walletBalanceAgentDispatch(
						agentlogin && agentlogin.result.result.email,
						agentlogin && agentlogin.result.result.id
					)
				);
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
		} catch (error) {
			dispatch(toggleSessionExpiredModal(true));
			dispatch(
				reprintFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const flightReprintEtravNewDispatch =
	(Booking_RefNo, Airline_PNR, history, states, agentlogin) =>
	async (dispatch) => {
		try {
			let formData = {
				Booking_RefNo: Booking_RefNo,
				Airline_PNR: Airline_PNR,
			};
			dispatch(reprintRequest());

			const { data } = await axiosInstance.post(
				'FlyEtrav/flightreprint',
				formData,
				config
			);
			// console.log(data);
			dispatch(reprintSuccess(data));
			if (data.status === 200) {
				// if(states.selectedReturn[0]?.Supplier==='TBO'&&states.selectedonward[0]?.Supplier==='ETR'){
				//   history.push({ pathname: "/ticketroundtripet", state: states });
				// }else if(states.selectedReturn[0]?.Supplier==='ETR'&&states.selectedonward[0]?.Supplier==='TBO'){
				//   history.push({ pathname: "/ticketroundtripte", state: states });
				// }else if(states.selectedReturn[0]?.Supplier==='ETR'&&states.selectedonward[0]?.Supplier==='ETR'){
				//   history.push({ pathname: "/ticketroundtrip", state: states });
				// }else if(states.OneflightData.Airline_Code){
				history.push({ pathname: '/ticket', state: states });
				// }

				dispatch(
					walletBalanceAgentDispatch(
						agentlogin && agentlogin.result.result.email,
						agentlogin && agentlogin.result.result.id
					)
				);
			} else {
				dispatch(toggleAPIFailedModal(true));
			}
		} catch (error) {
			dispatch(toggleAPIFailedModal(true));
			dispatch(
				reprintFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const flightReprintEtravRoundDispatch =
	(Booking_RefNo, Airline_PNR, history, states, agentlogin) =>
	async (dispatch) => {
		try {
			let formData = {
				Booking_RefNo: Booking_RefNo,
				Airline_PNR: Airline_PNR,
			};
			dispatch(reprintRoundRequest());

			const { data } = await axiosInstance.post(
				'FlyEtrav/flightreprint',
				formData,
				config
			);
			// console.log(data);
			dispatch(reprintRoundSuccess(data));
			// if (data.status === 200) {
			// 	history.push({ pathname: '/ticket', state: states });

			// 	dispatch(walletBalanceAgentDispatch(agentlogin && agentlogin.result.result.email,
			// 		agentlogin && agentlogin.result.result.id));
			// }
		} catch (error) {
			dispatch(
				reprintRoundFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export default EtravReducer;
