import "./hotel.css";
import HeaderSection from './HeaderSection';
import { useEffect, useState } from "react";
import { Box,Grid,makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import Footer from "../../pages/Desktop/Footer";
import { useHistory, useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from "react-redux";
import { hotelInfoDispatch, hotelRoomInfoDispatch } from "../../reducers/HotelReducer";
import { useDispatch } from "react-redux";
import { setSelectedRoomInfo, toggleCancellationPolicyModal } from "../../reducers/UiReducer";
import CancellationPolicyModal from "./CancellationPolicyModal";
const useStyles = makeStyles((theme) => ({
  card:{
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    background: '#fff',
    margin: '20px 10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
    },
    border:'1px solid #44aadd'
    
  },
  selectable:{
    position: 'relative',
  transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
  border: '4px solid transparent',
  }
}));
const Hotel = () => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const classes = useStyles()
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  console.log("first",location)
  const {hotelinfo,loadinghoteldes,hotelroominfo} = useSelector((state)=>state.hotel)
  useEffect(()=>{
    if(location.state){
      dispatch(hotelInfoDispatch(location.state.formdata))
      dispatch(hotelRoomInfoDispatch(location.state.formdata))
    
    }
  },[])
  const photos = [
    {
      src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261707778.jpg?k=56ba0babbcbbfeb3d3e911728831dcbc390ed2cb16c51d88159f82bf751d04c6&o=&hp=1",
    },
    {
      src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261707367.jpg?k=cbacfdeb8404af56a1a94812575d96f6b80f6740fd491d02c6fc3912a16d8757&o=&hp=1",
    },
    {
      src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261708745.jpg?k=1aae4678d645c63e0d90cdae8127b15f1e3232d4739bdf387a6578dc3b14bdfd&o=&hp=1",
    },
    {
      src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261707776.jpg?k=054bb3e27c9e58d3bb1110349eb5e6e24dacd53fbb0316b9e2519b2bf3c520ae&o=&hp=1",
    },
    {
      src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261708693.jpg?k=ea210b4fa329fe302eab55dd9818c0571afba2abd2225ca3a36457f9afa74e94&o=&hp=1",
    },
    {
      src: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/261707389.jpg?k=52156673f9eb6d5d99d3eed9386491a0465ce6f3b995f005ac71abc192dd5827&o=&hp=1",
    },
  ];

  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? 5 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === 5 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber)
  };

  return (
    <div>
      <HeaderSection/>
      <div className="hotelContainer" style={{marginTop:100}}>
        {open && (
          <div className="slider">
            <i class="fa-solid fa-circle-xmark"  
              onClick={() => setOpen(false)} style={{position: 'absolute',
                top: '20px',
                right: '20px',
                fontSize: '30px',
                color: 'lightgray',
                cursor: 'pointer'}}></i>
            {/* <FontAwesomeIcon
              icon={faCircleXmark}
              className="close"
              onClick={() => setOpen(false)}
            />
            <FontAwesomeIcon
              icon={faCircleArrowLeft}
              className="arrow"
              onClick={() => handleMove("l")}
            /> */}
            <i class="fa-solid fa-circle-arrow-left" onClick={() => handleMove("l")} style={{  margin: '20px',
  fontSize: '50px',
  color: 'lightgray',
  cursor: 'pointer'}}></i>
            <div className="sliderWrapper">
              <img src={hotelinfo?.result?.HotelInfoResult?.HotelDetails?.Images[slideNumber]} alt="" style={{width:matches?'117%':'80%',height:matches?'32vh':'80vh'}} />
            </div>
            <i class="fa-solid fa-circle-arrow-right" onClick={() => handleMove("r")} style={{  margin: '20px',
  fontSize: '50px',
  color: 'lightgray',
  cursor: 'pointer'}}></i>
            {/* <FontAwesomeIcon
              icon={faCircleArrowRight}
              className="arrow"
              onClick={() => handleMove("r")}
            /> */}
          </div>
        )}
        <div className="hotelWrapper" style={{padding:matches?10:0}}>
          {/* <button className="bookNow">Reserve or Book Now!</button> */}
          {loadinghoteldes?	<Skeleton variant='text' width='50%' style={{ borderRadius: 10 }} />:<h1 className="hotelTitle">{hotelinfo?.result?.HotelInfoResult?.HotelDetails?.HotelName}</h1>}
         {loadinghoteldes?<Skeleton variant='text' width='60%' style={{ borderRadius: 10 }} />: <div className="hotelAddress">
            {/* <FontAwesomeIcon icon={faLocationDot} /> */}
            <span>{hotelinfo?.result?.HotelInfoResult?.HotelDetails?.Address}</span>
          </div>}
          {loadinghoteldes?<Skeleton variant='text' width='30%' style={{ borderRadius: 10 }} />
         : <span className="hotelDistance">
          {`Phone: ${hotelinfo?.result?.HotelInfoResult?.HotelDetails?.HotelContactNo}, Pincode: ${hotelinfo?.result?.HotelInfoResult?.HotelDetails?.PinCode}`}
          </span>}
          {/* <span className="hotelPriceHighlight">
            Book a stay over $114 at this property and get a free airport taxi
          </span> */}
          <div className="hotelImages">
            {loadinghoteldes?
      
            <Grid container spacing={2}>
              {[0,1,2,3,4,5].map((i)=>{
                return <Grid item xs={4}><Box style={{ width: '100%' }}>
                <Skeleton variant='rectangular' width='100%' height='200px' style={{ borderRadius: 10 }} />
              
              </Box></Grid>
              })}
             
            </Grid>: hotelinfo?.result?.HotelInfoResult?.HotelDetails?.Images.map((photo, i) => (
             
             i<6?<div className="hotelImgWrapper" key={i}>
               <img
                 onClick={() => handleOpen(i)}
                 src={photo}
                 alt=""
                 className="hotelImg"
               />
             </div>:''
           ))}
	
          </div>
          <div className="hotelDetails" style={{display:matches?'':'flex'}}>
            <div className="hotelDetailsTexts">
              <h1 className="hotelTitle">Stay in the heart of City</h1>
              {!loadinghoteldes?
             <p className="hotelDesc">
              <div
											style={{ fontSize: 14,fontFamily:'Poppins' }}
											dangerouslySetInnerHTML={{
												__html:
                        hotelinfo?.result?.HotelInfoResult?.HotelDetails?.Description
											}}
										/>
                {hotelinfo?.result?.HotelInfoResult?.HotelDetails?.Attractions?.map((i,index)=>{
                  return  <div
                  style={{ fontSize: 14,fontFamily:'Poppins' }}
                  dangerouslySetInnerHTML={{
                    __html:
                   i.Value
                  }}
                />
                })}
                
              </p> :
              	<Box style={{ width: '100%' }}>
				{[0,1,2].map((i)=>{
          return <Skeleton variant='rectangular' width='100%' height='200px' style={{ borderRadius: 10 }} />
        })}
        
				
				</Box>}
        <h1 className="hotelTitle">Choose Room</h1>
        {hotelroominfo&&hotelroominfo?.result?.GetHotelRoomResult?.HotelRoomsDetails?.map((i)=>{
            return <div className={classes.card} style={{padding:10,fontFamily:'Poppins'}} >
              <Box display='flex' justifyContent='space-between'>
              <span style={{color:'#0c2f54',fontSize:14,fontWeight:'bold'}}>{i.RoomTypeName}</span>
            <span style={{fontSize:20}}>₹{Number(Math.round(Number(i.Total_Amount)-Number(i.MFB_Discount)))}</span>
              </Box>
              <Box display='flex' justifyContent='space-between' alignItems='center' style={{marginTop:10}}>
                <Box onClick={()=>{dispatch(toggleCancellationPolicyModal(true));dispatch(setSelectedRoomInfo(i))}}>
                Cancellation Policy
                </Box>
                <Box style={{background: 'darkgreen',
    color: 'white',
    padding: '6px',
    fontWeight: '600'
}} onClick={()=>history.push({
  pathname:'/summaryhotel',
  state:{
    item:i,
    selectedHotel:location.state.selectedHotel,
    room:location.state.room,
    orderID:location.state.orderID,
    traceId:location.state.traceId,
    adultH:location.state.adultH,
  childH:location.state.childH,
  childAge1:location.state.childAge1,
  childAge2:location.state.childAge2,
  childAge3:location.state.childAge3,
  childAge4:location.state.childAge4
  }
})}>
               Select Room
                </Box>
              </Box>
           
        </div>
        })}
        
        {/* <div className={classes.card}>
            <h1>Hello</h1>
        </div> */}
            </div>
            <div className="hotelDetailsPrice" style={{height:'fitContent'}}>
            <h1 className="hotelTitle">Amenities</h1>
            {!loadinghoteldes?
                hotelinfo?.result?.HotelInfoResult?.HotelDetails?.HotelFacilities.map((i,index)=>{
                  return  <li>{i}</li>
                
                }):
                [0,1,2,3,4,5].map((i)=>{
                    return  <Skeleton variant='text' width='80%' style={{ borderRadius: 10 }} />
                })
                }
               
              {/* <h1>Perfect for a 9-night stay!</h1>
              <span>
                Located in the real heart of Krakow, this property has an
                excellent location score of 9.8!
              </span>
              <h2>
                <b>₹94554</b> (9 nights)
              </h2>
              <button>Reserve or Book Now!</button> */}
            </div>
          </div>
        </div>
        
        <Footer/>
        <CancellationPolicyModal/>
        {/* <MailList /> */}
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Hotel;
