import React, { useState } from 'react';
import {
	makeStyles,
	Button,
	Box,
	withStyles,
	Checkbox,
	Grid,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	agentedituserDispatch,
	travelleredituserDispatch,
} from '../../reducers/UserReducer';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid rgba(0, 50, 91, 0.5)',
			},
		},
		'& .MuiFormHelperText-root': {
			backgroundColor: '#F6F8FB',
			margin: 0,
			paddingTop: 10,
		},
		'& .MuiSelect-icon': {
			color: '#E61855',
		},
		'& div': {
			fontSize: 18,
		},
		'& input': {
			padding: '12.5px 14px',
		},
		'& .MuiSelect-select': {
			padding: '12.5px 14px',
		},

		backgroundColor: 'white',
		marginBottom: 20,
		width: '100%',
		marginTop: 10,
		borderRadius: 15,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
			marginTop: 8,
		},
	},
	InputPropsStyle: {
		backgroundColor: '#F6F8FB',
		borderRadius: 8,
		fontSize: 18,
	},
	inputPropsStyle: {
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#F6F8FB',
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		padding: 14,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
}));
const GreenCheckbox = withStyles({
	root: {
		color: '#39c9bb',
		'&$checked': {
			color: '#39c9bb',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const country = ['Jharkhand', 'Kolkata'];

const MyProfileForm = () => {
	const classes = useStyles();
	const [tabvalue, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [check, setCheck] = useState(false);
	const handleCheck = (event) => {
		setCheck(event.target.checked);
	};
	const dispatch = useDispatch();
	const history = useHistory();
	const { travellerlogin, agentlogin, supplierlogin } = useSelector(
		(state) => state.user
	);
	const [currentStep, SetCurrentStep] = useState('Step 1');

	const handleFormSubmit = (data, actions) => {
		if (
			agentlogin &&
			agentlogin.result &&
			agentlogin.result.result &&
			agentlogin.result.result.role === 'Agent'
		) {
			let formData2 = {
				userId:
					agentlogin &&
					agentlogin.result &&
					agentlogin.result.result &&
					agentlogin.result.result.id,
				userFirstName: data.userFirstName,
				userLastName: data.userLastName,
				userPhoneCode: '91',
				userPhone: data.userPhone,
				agencyName: data.agencyName,
			};
			dispatch(agentedituserDispatch(formData2, history));
		} else {
			let formData = {
				userId:
					travellerlogin &&
					travellerlogin.result &&
					travellerlogin.result.result &&
					travellerlogin.result.result.id,
				userFirstName: data.userFirstName,
				userLastName: data.userLastName,
				userPhoneCode: '91',
				userPhone: data.userPhone,
			};
			dispatch(travelleredituserDispatch(formData, history));
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					userFirstName: agentlogin
						? agentlogin &&
						  agentlogin.result &&
						  agentlogin.result.result &&
						  agentlogin.result.result.name.trimStart().split(' ')[0]
						: travellerlogin
						? travellerlogin &&
						  travellerlogin.result &&
						  travellerlogin.result.result &&
						  travellerlogin.result.result.name.trimStart().split(' ')[0]
						: supplierlogin
						? supplierlogin &&
						  supplierlogin.result &&
						  supplierlogin.result.result &&
						  supplierlogin.result.result.name.trimStart().split(' ')[0]
						: '',
					userLastName: agentlogin
						? agentlogin &&
						  agentlogin.result &&
						  agentlogin.result.result &&
						  agentlogin.result.result.name.trimStart().split(' ')[1]
						: travellerlogin
						? travellerlogin &&
						  travellerlogin.result &&
						  travellerlogin.result.result &&
						  travellerlogin.result.result.name.trimStart().split(' ')[1]
						: supplierlogin
						? supplierlogin &&
						  supplierlogin.result &&
						  supplierlogin.result.result &&
						  supplierlogin.result.result.name.trimStart().split(' ')[1]
						: '',
					userPhone: agentlogin
						? agentlogin &&
						  agentlogin.result &&
						  agentlogin.result.result &&
						  agentlogin.result.result.phone
						: travellerlogin
						? travellerlogin &&
						  travellerlogin.result &&
						  travellerlogin.result.result &&
						  travellerlogin.result.result.phone
						: supplierlogin
						? supplierlogin &&
						  supplierlogin.result &&
						  supplierlogin.result.result &&
						  supplierlogin.result.result.phone
						: '',
					agencyName: agentlogin
						? agentlogin &&
						  agentlogin.result &&
						  agentlogin.result.result &&
						  agentlogin.result.result.agencyName
						: supplierlogin
						? supplierlogin &&
						  supplierlogin.result &&
						  supplierlogin.result.result &&
						  supplierlogin.result.result.agencyName
						: '',
				}}
				validate={(values) => {
					const errors = {};

					// if (!values.email) {
					// 	errors.email = 'Required';
					// } else if (
					// 	!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
					// ) {
					// 	errors.email = 'Invalid email address';
					// }
					// if (!values.password) {
					// 	errors.password = 'Required';
					// }
					// if (!values.confirm_password) {
					// 	errors.confirm_password = 'Required';
					// } else if (values.confirm_password === values.password2) {
					// 	errors.confirm_password = 'Required';
					// }
					// if (!values.phone) {
					// 	errors.phone = 'Required';
					// }
					// if (!values.first_name) {
					// 	errors.first_name = 'Required';
					// }
					// if (!values.address) {
					// 	errors.address = 'Required';
					// }
					// if (!values.company_name) {
					// 	errors.company_name = 'Required';
					// }
					// if (!values.state) {
					// 	errors.state = 'Required';
					// }
					// if (!values.city) {
					// 	errors.city = 'Required';
					// }
					// if (!values.country) {
					// 	errors.country = 'Required';
					// }

					return errors;
				}}
				onSubmit={handleFormSubmit}>
				{({ submitForm, isSubmitting, values, errors }) => (
					<>
						{' '}
						<Box>
							{/* <h2 style={{ fontFamily: "Josefin Sans, sans-serif" }}>
                Edit Profile
              </h2> */}
						</Box>
						<Form>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ marginTop: 20, padding: 50 }}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h5>First Name</h5>
									<Field
										component={TextField}
										name='userFirstName'
										type='text'
										id='outlined-basic'
										label='First Name'
										variant='outlined'
										// error={errors.first_name}
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h5>Last Name</h5>
									<Field
										component={TextField}
										name='userLastName'
										type='text'
										id='outlined-basic'
										label='Last Name'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h5>Phone Number</h5>
									<Field
										component={TextField}
										name='userPhone'
										type='text'
										id='outlined-basic'
										label='Phone'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h5>Agency Name</h5>
									<Field
										component={TextField}
										name='agencyName'
										type='text'
										id='outlined-basic'
										label='Agency Name'
										variant='outlined'
										error={errors.company_name}
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
							</Grid>
							{/* <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ marginTop: 10,padding:50 }}
              >
             
              </Grid> */}
							{/* <Grid
							container
							spacing={2}
							alignItems='center'
							style={{ marginTop: 20 }}>
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<Field
									component={TextField}
									select
									name='state'
									type='text'
									variant='outlined'
									label='State'
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
									}}
									inputProps={{
										style: { padding: 20 },
									}}>
									{country.map((item, index) => (
										<MenuItem key={index} value={item}>
											{item}
										</MenuItem>
									))}
								</Field>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<Field
									component={TextField}
									select
									name='city'
									type='text'
									variant='outlined'
									label='City'
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
									}}
									inputProps={{
										style: { padding: 20 },
									}}>
									{country.map((item, index) => (
										<MenuItem key={index} value={item}>
											{item}
										</MenuItem>
									))}
								</Field>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<Field
									component={TextField}
									select
									name='country'
									type='text'
									variant='outlined'
									label='Country'
									className={classes.selectField}
									InputProps={{
										className: classes.InputPropsStyle,
									}}
									inputProps={{
										style: { padding: 20 },
									}}>
									{country.map((item, index) => (
										<MenuItem key={index} value={item}>
											{item}
										</MenuItem>
									))}
								</Field>
							</Grid>
						</Grid> */}
							<Grid container style={{ marginTop: 20, padding: 50 }}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<Button
										className={classes.createButton}
										onClick={submitForm}
										style={{ marginTop: 5 }}
										// disabled={isSubmitting}
									>
										UPDATE
									</Button>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default MyProfileForm;
