import React from 'react'
import HeaderSection from '../../components/reusableComponents/HeaderSection'
import Footer from './Footer'

const FailedPage = () => {
  return (
    <>
    <HeaderSection/>
    <div style={{marginTop:146,textAlign:'center',marginBottom:150}}>
      <h3>!! Transaction Failed !!</h3>
    </div>
    <Footer/>
    </>
  )
}

export default FailedPage