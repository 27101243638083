import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BodyText from "./BodyText";
import {
  makeStyles,
  Container,
  Box,
  Grid,
  TextField as TextField2,
} from "@material-ui/core";
import "../../main.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import click from '../../assets/images/jupiter.png';
// import musafirbazarlogo from '../../assets/images/max.png';

// import musafirbazarlogo from "../../assets/images/musafirbazarlogo.png";
// import musafirbazarlogo from '../../assets/images/click.PNG';
// import musafirbazarlogo from "../../assets/images/jupiter.png";
// import musafirbazarlogo from '../../assets/images/suzu.png';
// import musafirbazarlogo from '../../assets/images/worldwide.png';
import LoadingButton from "@mui/lab/LoadingButton";
import PrintIcon from "@mui/icons-material/Print";
import EmailIcon from "@mui/icons-material/Email";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation } from "react-router-dom";
import HeaderSection from "./HeaderSection";
import { toggleEditTicketModal } from "../../reducers/UiReducer";
import EditTicketPriceModal from "./EditTicketPriceModal";
import { FlightMailTicketDispatch,fetchlogo } from "../../reducers/HomeReducer";
const useStyles = makeStyles((theme) => ({
  table: {
    background: "#fff",
    margin: "0px auto",
    width: "850px !important",
    padding: "30px 20px",
    webkitPrintColorAdjust: "exact",
  },
  table2: {
    boxShadow: "1px 1px 15px rgba(0, 0, 0, 0.1)",
    background: "#fff",
    borderSpacing: 0,
    padding: 0,
    margin: 0,
  },
  table3: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  row: {
    width: "60%",
    padding: "15px 40px",
  },
  row2: {
    width: "50%",
    border: "1px solid #333",
    borderLeft: 0,
    padding: "8px",
  },
  row3: {
    width: "50%",
    borderTop: "1px solid #333",
    borderBottom: "1px solid #333",
    padding: "5px",
  },
}));
const RoundTicket = () => {
  const { ticket,ticketReturn } = useSelector((state) => state.home);
  const { editprice } = useSelector((state) => state.ui);
  const { bookingDetail } = useSelector((state) => state.tripjack);
  const { etrav } = useSelector((state) => state);
  console.log("ticket", ticket);
  // useEffect(() => {
  //     dispatch(FlightTicketDispatch());
  // },[])
  const location = useLocation();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const {  agentlogin,  agentlogoget } =
		useSelector((state) => state.user);
		const { logo } =
		useSelector((state) => state.home);
  console.log("Location", location);
  useEffect(() => {
		let form = {};
		
		dispatch(fetchlogo(form));
		
	  }, []);
  const handleClick = () => {
    setLoading(true);
    let formMail = {
      orderId: location.state.mailBody.orderID,
      userEmail: location.state.mailBody.formData.email,
    };
    dispatch(FlightMailTicketDispatch(formMail));
    setLoading(false)
    
  };
  const pnr = bookingDetail?Object.entries(bookingDetail.result.itemInfos.AIR.travellerInfos[0].pnrDetails).forEach(([key,value])=>{
    return `${key},${value}`}):''
  return (
    <>
      <HeaderSection id={1} />
      <Container style={{ marginTop: 100 }}>
        <LoadingButton
          id="back"
          onClick={() => history.push("/")}
          color="primary"
          loadingPosition="start"
          startIcon={<ArrowBackIosNewIcon />}
          variant="contained"
        >
          Back
        </LoadingButton>
      </Container>

      <table className={classes.table}>
        <tbody>
          <tr>
            <td>
              <table className={classes.table2}>
                <tbody>
                  <tr>
                    <td>
                      <table className={classes.table3}>
                        <tbody>
                          <tr>
                            <td className={classes.row}>
                              {/* <img
                                // src='https://www.marinersforex.com/img/LOGO-MarinersForex.png'
                                // src={agentlogin && agentlogoget
																// 	? agentlogoget && agentlogoget.result
																// 	: logo?.result[0]?.logo}
                                style={{
                                  maxHeight: 50,
                                  maxWidth: 150,
                                }}
                              ></img> */}
                            </td>
                            <td style={{ width: "40%" }}>
                              <BodyText
                                textStyle={{
                                  fontWeight: 600,
                                  marginTop: 5,
                                }}
                              >
                                Musafir Bazar
                              </BodyText>
                              <p
                                style={{
                                  fontWeight: 400,
                                  fontSize: 14,
                                  color: "#777",
                                }}
                              >
                                Email: info@info.com
                              </p>
                              <p
                                style={{
                                  fontWeight: 400,
                                  fontSize: 14,
                                  color: "#777",
                                }}
                              >
                                Phone: +91 887-777-3388
                              </p>
                              <p
                                style={{
                                  fontWeight: 400,
                                  fontSize: 14,
                                  color: "#777",
                                }}
                              >
                                Address: 141st Floor,New Tech Grand, Bank More,
                                Dhanbad-826001
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className={classes.table3}>
                        <tbody>
                          <tr>
                            <td className={classes.row2}>
                              <p
                                style={{
                                  marginBottom: "5px",
                                  color: "#777",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                  }}
                                >
                                  Booking Time:{" "}
                                  {/* {ticket &&
																		ticket.result &&
																		ticket.result.Response &&
																		ticket.result.Response.Response
																			.FlightItinerary.LastTicketDate} */}
                                  {new Date().toLocaleDateString()}
                                </span>
                              </p>
                              <p
                                style={{
                                  marginBottom: "5px",
                                  color: "#777",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                  }}
                                >
                                  Booking ID:
                                  {`${ticket?ticket &&
                                      ticket.result &&
                                      ticket.result.Response &&
                                      ticket.result.Response.Response.BookingId:etrav.reprint
                                      ? etrav &&
                                        etrav.reprint.result &&
                                        etrav.reprint.result.Booking_RefNo:''} | ${ticketReturn?ticketReturn &&
                                        ticketReturn.result &&
                                        ticketReturn.result.Response &&
                                        ticketReturn.result.Response.Response.BookingId:etrav.reprintRound
                                        ? etrav &&
                                          etrav.reprintRound.result &&
                                          etrav.reprintRound.result.Booking_RefNo:''}`}
                                  {/* {ticket &&
																		ticket.result &&
																		ticket.result.Booking_RefNo} */}
                                </span>
                              </p>
                              <p
                                style={{
                                  marginBottom: "5px",
                                  color: "#777",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                  }}
                                >
                                  Booking Status:
                                </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    color: "black",
                                  }}
                                >
                                  {" "}
                                  CONFIRMED
                                </span>
                              </p>
                            </td>
                            <td className={classes.row3}>
                              <br></br>
                              <table
                                style={{
                                  width: "100%",
                                  marginBottom: "15px",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ width: 40 }}>
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                          <img
                                            src={`https://nitish.musafirbazar.com/static/media/${
                                              etrav.reprint
                                                ? etrav &&
                                                  etrav.reprint.result &&
                                                  etrav.reprint.result
                                                    .AirPNRDetails[0]
                                                    .Airline_Code
                                                : ticket?ticket &&
                                                  ticket.result &&
                                                  ticket.result.Response &&
                                                  ticket.result.Response
                                                    .Response &&
                                                  ticket.result.Response
                                                    .Response.FlightItinerary
                                                    .AirlineCode:bookingDetail?bookingDetail.result.itemInfos.AIR.tripInfos[0].sI[0].fD.aI.code:''
                                            }.gif`}
                                            style={{
                                              marginRight: 5,
                                              verticalAlign: "middle",
                                              width: 50,
                                              height:50
                                            }}
                                          ></img>
                                        </span>
                                      </p>
                                    </td>
                                    <td style={{ width: 40 }}>
                                    <img
                                            src={`https://nitish.musafirbazar.com/static/media/${
                                             
                                                ticketReturn?ticketReturn &&
                                                  ticketReturn.result &&
                                                  ticketReturn.result.Response &&
                                                  ticketReturn.result.Response
                                                    .Response &&
                                                  ticketReturn.result.Response
                                                    .Response.FlightItinerary
                                                    .AirlineCode:etrav.reprintRound
                                                    ? etrav &&
                                                      etrav.reprintRound.result &&
                                                      etrav.reprintRound.result
                                                        .AirPNRDetails[0]
                                                        .Airline_Code:''
                                            }.gif`}
                                            style={{
                                              marginRight: 5,
                                              verticalAlign: "middle",
                                              width: 50,
                                              height:50
                                            }}
                                          ></img>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <p id='pnr'
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 20,
                                        }}
                                      >
                                        <strong>
                                          {`${ticket?ticket &&
                                              ticket.result &&
                                              ticket.result.Response &&
                                              ticket.result.Response.Response
                                                .PNR:etrav.reprint
                                                ? etrav &&
                                                  etrav.reprint.result &&
                                                  etrav.reprint.result
                                                    .AirPNRDetails[0].Airline_PNR:''} | ${ticketReturn?ticketReturn &&
                                                  ticketReturn.result &&
                                                  ticketReturn.result.Response &&
                                                  ticketReturn.result.Response.Response
                                                    .PNR:etrav.reprintRound
                                                    ? etrav &&
                                                      etrav.reprintRound.result &&
                                                      etrav.reprintRound.result
                                                        .AirPNRDetails[0].Airline_PNR:''}`
                                                 }
                                        </strong>
                                      </p>
                                      <p>
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            fontSize: 12,
                                            color: "#777",
                                          }}
                                        >
                                          
                                          Airline PNR
                                          {/* {ticket &&
																						ticket.result &&
																						ticket.result.AirlinePNRDetails[0]
																							.AirlinePNRs[0].Airline_PNR} */}
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className={classes.table3}>
                        <tbody>
                          <tr>
                            <td style={{ width: "30%" }}>
                              <p
                                style={{
                                  background: "#000",
                                  padding: "5px",
                                  color: "#fff",
                                  paddingLeft: 20,
                                  webkitPrintColorAdjust: "exact",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                  }}
                                >
                                  Flight Detail Onward
                                </span>
                              </p>
                            </td>
                            <td style={{ width: "70%" }} align="right">
                              <p
                                style={{
                                  background: "#000",
                                  padding: 5,
                                  color: "#fff",
                                  webkitPrintColorAdjust: "exact",
                                }}
                              >
                                <em>
                                  <span
                                    style={{
                                      fontWeight: 400,
                                      fontSize: 14,
                                    }}
                                  >
                                    *Please verify flight timings & terminal
                                    info with the airlines
                                  </span>
                                </em>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{
                          width: "100%",
                          lineHeight: "normal",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: "20%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>FLight</span>
                              </p>
                            </td>
                            <td style={{ width: "10%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>Class</span>
                              </p>
                            </td>
                            <td style={{ width: "10%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>Stop</span>
                              </p>
                            </td>
                            <td style={{ width: "20%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>
                                  Departing
                                </span>
                              </p>
                            </td>
                            <td style={{ width: "20%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>
                                  Arriving
                                </span>
                              </p>
                            </td>
                            <td style={{ width: "10%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>
                                  Duration
                                </span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* {location.state.OneflightData.Segments.length > 0 &&
												location.state.OneflightData.Segments.map((item) => {
													return (
														<table
															style={{
																borderBottom: '1px solid #cfcbcb',
																width: '100%',
															}}>
															<tbody>
																<tr>
																	<td
																		width='20%'
																		style={{
																			padding: 2,
																			fontSize: 12,
																			paddingLeft: 20,
																		}}>
																		<p>
																			<strong style={{ color: '#000' }}>
																				{item.Airline_Code}-{item.Flight_Number}
																			</strong>
																		</p>
																		<p>
																			<span style={{ fontWeight: 400 }}>
																				{item.Airline_Name}
																			</span>
																		</p>
																	</td>
																	<td
																		width='10%'
																		style={{
																			padding: 2,
																			fontSize: 12,
																		}}>
																		<p>
																			<span style={{ fontWeight: 400 }}>R</span>
																		</p>
																	</td>
																	<td width='10%'>
																		<p style={{ fontSize: 12 }}>
																			<span style={{ fontWeight: 400 }}>
																				Non Stop
																			</span>
																		</p>
																	</td>
																	<td width='20%'>
																		<p style={{ fontSize: 12 }}>
																			<strong color='#000'>
																				{item.Departure_DateTime}
																			</strong>
																		</p>
																		<p style={{ fontSize: 12 }}>
																			<span style={{ fontWeight: 400 }}>
																				{item.Origin_City}
																			</span>
																		</p>
																	</td>
																	<td width='20%'>
																		<p style={{ fontSize: 12 }}>
																			<strong color='#000'>
																				{item.Arrival_DateTime}
																			</strong>
																		</p>
																		<p style={{ fontSize: 12 }}>
																			<span style={{ fontWeight: 400 }}>
																				{item.Destination_City}
																			</span>
																		</p>
																	</td>
																	<td width='10%'>
																		<p style={{ fontSize: 12 }}>
																			<strong color='#000'>
																				{item.Duration}hr
																			</strong>
																		</p>
																	</td>
																</tr>
															</tbody>
														</table>
													);
												})} */}

                      <table
                        style={{
                          borderBottom: "1px solid #cfcbcb",
                          width: "100%",
                        }}
                      >
                        <tbody>
                          {ticket &&
                            ticket.result &&
                            ticket.result.Response &&
                            ticket.result.Response.Response &&
                            ticket.result.Response.Response &&
                            ticket.result.Response.Response.FlightItinerary.Segments.map(
                              (item, index) => {
                                return (
                                  <tr>
                                    <td
                                      width="20%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <p>
                                        <strong style={{ color: "#000" }}>
                                          {item.Airline.AirlineCode}-
                                          {item.Airline.FlightNumber}
                                        </strong>
                                      </p>
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Airline.AirlineName}
                                        </span>
                                      </p>
                                    </td>
                                    <td
                                      width="10%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                      }}
                                    >
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Airline.FareClass}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          Non Stop
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.Origin.DepTime}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Origin.Airport.AirportName}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.Destination.ArrTime}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Destination.Airport.AirportName}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {`${item.Duration} min`}
                                        </strong>
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            )}

                          {etrav &&
                            etrav.reprint &&
                            etrav.reprint.result &&
                            etrav.reprint.result.AirPNRDetails[0] &&
                            etrav.reprint.result.AirPNRDetails[0].Flights[0].Segments.map(
                              (item, index) => {
                                return (
                                  <tr>
                                    <td
                                      width="20%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <p>
                                        <strong style={{ color: "#000" }}>
                                          {item.Airline_Code}-
                                          {item.Flight_Number}
                                        </strong>
                                      </p>
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Airline_Name}
                                        </span>
                                      </p>
                                    </td>
                                    <td
                                      width="10%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                      }}
                                    >
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                         {etrav &&
                            etrav.reprint &&
                            etrav.reprint.result &&
                            etrav.reprint.result.AirPNRDetails[0] &&
                            etrav.reprint.result.AirPNRDetails[0].Flights[0].Fares[0].FareDetails[0].FareClasses[0].Class_Code}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          Non Stop
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.Departure_DateTime}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Origin}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.Arrival_DateTime}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Destination}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                        {`${item.Duration} min`}
                                        </strong>
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            {bookingDetail &&
                            bookingDetail.result &&
                            bookingDetail.result.itemInfos &&
                            bookingDetail.result.itemInfos.AIR &&
                            bookingDetail.result.itemInfos.AIR.tripInfos[0].sI.map(
                              (item, index) => {
                                return (
                                  <tr>
                                  <td
                                      width="20%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <p>
                                        <strong style={{ color: "#000" }}>
                                          {item.fD.aI.code}-
                                          {item.fD.fN}
                                        </strong>
                                      </p>
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.fD.aI.name}
                                        </span>
                                      </p>
                                    </td>
                                    <td
                                      width="10%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                      }}
                                    >
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                         {/* {} */}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          Non Stop
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.dt}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.da.code}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.at}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.aa.code}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                        {`${item.duration} min`}
                                        </strong>
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                      <table className={classes.table3}>
                        <tbody>
                          <tr>
                            <td style={{ width: "30%" }}>
                              <p
                                style={{
                                  background: "#000",
                                  padding: "5px",
                                  color: "#fff",
                                  paddingLeft: 20,
                                  webkitPrintColorAdjust: "exact",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                  }}
                                >
                                  Flight Detail Return
                                </span>
                              </p>
                            </td>
                            <td style={{ width: "70%" }} align="right">
                              <p
                                style={{
                                  background: "#000",
                                  padding: 5,
                                  color: "#fff",
                                  webkitPrintColorAdjust: "exact",
                                }}
                              >
                                <em>
                                  <span
                                    style={{
                                      fontWeight: 400,
                                      fontSize: 14,
                                    }}
                                  >
                                    *Please verify flight timings & terminal
                                    info with the airlines
                                  </span>
                                </em>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{
                          width: "100%",
                          lineHeight: "normal",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: "20%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>FLight</span>
                              </p>
                            </td>
                            <td style={{ width: "10%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>Class</span>
                              </p>
                            </td>
                            <td style={{ width: "10%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>Stop</span>
                              </p>
                            </td>
                            <td style={{ width: "20%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>
                                  Departing
                                </span>
                              </p>
                            </td>
                            <td style={{ width: "20%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>
                                  Arriving
                                </span>
                              </p>
                            </td>
                            <td style={{ width: "10%" }}>
                              <p
                                style={{
                                  background: "#BCBEC0",
                                  color: "#000",
                                  padding: "3px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                }}
                              >
                                <span style={{ fontWeight: 400 }}>
                                  Duration
                                </span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{
                          borderBottom: "1px solid #cfcbcb",
                          width: "100%",
                        }}
                      >
                        <tbody>
                          {ticketReturn &&
                            ticketReturn.result &&
                            ticketReturn.result.Response &&
                            ticketReturn.result.Response.Response &&
                            ticketReturn.result.Response.Response &&
                            ticketReturn.result.Response.Response.FlightItinerary.Segments.map(
                              (item, index) => {
                                return (
                                  <tr>
                                    <td
                                      width="20%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <p>
                                        <strong style={{ color: "#000" }}>
                                          {item.Airline.AirlineCode}-
                                          {item.Airline.FlightNumber}
                                        </strong>
                                      </p>
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Airline.AirlineName}
                                        </span>
                                      </p>
                                    </td>
                                    <td
                                      width="10%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                      }}
                                    >
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Airline.FareClass}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          Non Stop
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.Origin.DepTime}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Origin.Airport.AirportName}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.Destination.ArrTime}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Destination.Airport.AirportName}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {`${item.Duration} min`}
                                        </strong>
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
{etrav &&
                            etrav.reprint &&
                            etrav.reprint.result &&
                            etrav.reprint.result.AirPNRDetails[0] &&
                            etrav.reprint.result.AirPNRDetails[0].Flights[0].Segments.map(
                              (item, index) => {
                                return (
                                  <tr>
                                    <td
                                      width="20%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <p>
                                        <strong style={{ color: "#000" }}>
                                          {item.Airline_Code}-
                                          {item.Flight_Number}
                                        </strong>
                                      </p>
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Airline_Name}
                                        </span>
                                      </p>
                                    </td>
                                    <td
                                      width="10%"
                                      style={{
                                        padding: 2,
                                        fontSize: 12,
                                      }}
                                    >
                                      <p>
                                        <span style={{ fontWeight: 400 }}>
                                         {etrav &&
                            etrav.reprintRound &&
                            etrav.reprintRound.result &&
                            etrav.reprintRound.result.AirPNRDetails[0] &&
                            etrav.reprintRound.result.AirPNRDetails[0].Flights[0].Fares[0].FareDetails[0].FareClasses[0].Class_Code}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          Non Stop
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.Departure_DateTime}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Origin}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="20%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                          {item.Arrival_DateTime}
                                        </strong>
                                      </p>
                                      <p style={{ fontSize: 12 }}>
                                        <span style={{ fontWeight: 400 }}>
                                          {item.Destination}
                                        </span>
                                      </p>
                                    </td>
                                    <td width="10%">
                                      <p style={{ fontSize: 12 }}>
                                        <strong color="#000">
                                        {`${item.Duration} min`}
                                        </strong>
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                         
                        </tbody>
                      </table>
                      <table style={{ marginTop: 12, width: "100%" }}>
                        <tbody>
                          <tr>
                            <td width="100%">
                              <p
                                style={{
                                  background: "#000",
                                  color: "#fff",
                                  padding: "5px",
                                  paddingLeft: "20px",
                                  fontSize: 14,
                                  webkitPrintColorAdjust: "exact",
                                }}
                              >
                                Passenger Details
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{
                          color: "#000",
                          width: "100%",
                          verticalAlign: "middle",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderBottom: "1px solid #333",
                                background: "#BCBEC0",
                                webkitPrintColorAdjust: "exact",
                              }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  padding: "3px",
                                  fontSize: 14,
                                }}
                              >
                                Sr.
                              </p>
                            </td>
                            <td
                              style={{
                                borderBottom: "1px solid #333",
                                background: "#BCBEC0",
                                webkitPrintColorAdjust: "exact",
                              }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  padding: "3px",
                                  fontSize: 14,
                                }}
                              >
                                Name
                              </p>
                            </td>
                            <td
                              style={{
                                borderBottom: "1px solid #333",
                                background: "#BCBEC0",
                                webkitPrintColorAdjust: "exact",
                              }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  padding: "3px",
                                  fontSize: 14,
                                }}
                              >
                                Type
                              </p>
                            </td>
                            <td
                              style={{
                                borderBottom: "1px solid #333",
                                background: "#BCBEC0",
                                webkitPrintColorAdjust: "exact",
                              }}
                            >
                              <p
                                style={{
                                  color: "#000",
                                  padding: "3px",
                                  fontSize: 14,
                                }}
                              >
                                PNR & Ticket No.
                              </p>
                            </td>
                            <td
                              style={{
                                borderBottom: "1px solid #333",
                                background: "#BCBEC0",
                                fontSize: 14,
                                webkitPrintColorAdjust: "exact",
                              }}
                            >
                              Baggage
                              <span
                                style={{
                                  fontSize: "8px",
                                  lineHeight: "12px",
                                  display: "block",
                                }}
                              >
                                Check-in | Cabin
                              </span>
                            </td>
                          </tr>
                          {ticket &&
                            ticket.result &&
                            ticket.result.Response &&
                            ticket.result.Response.Response &&
                            ticket.result.Response.Response.FlightItinerary
                              .Passenger.length > 0 &&
                            ticket &&
                            ticket.result &&
                            ticket.result.Response &&
                            ticket.result.Response.Response &&
                            ticket.result.Response.Response.FlightItinerary.Passenger.map(
                              (item, index) => {
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        borderLeft: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p style={{ fontSize: 12 }}>
                                        {index + 1}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p style={{ fontSize: 12 }}>
                                        {""}
                                        {`${item.Title} ${item.FirstName} ${item.LastName}`}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.PaxType === 1
                                          ? "Adult"
                                          : item.PaxType === 2
                                          ? "Child"
                                          : item.PaxType === 3
                                          ? "Infant"
                                          : ""}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {`${item.Ticket.TicketNumber} ${item.Ticket.TicketId}`}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.SegmentAdditionalInfo[0].Baggage}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          {etrav &&
                            etrav.reprint &&
                            etrav.reprint.result &&
                            etrav.reprint.result.AirPNRDetails[0] &&
                            etrav.reprint.result.AirPNRDetails[0].PAXTicketDetails.map(
                              (item, index) => {
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        borderLeft: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p style={{ fontSize: 12 }}>
                                        {index + 1}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p style={{ fontSize: 12 }}>
                                        {""}
                                        {`${item.Title} ${item.First_Name} ${item.Last_Name}`}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.Pax_type === 0
                                          ? "Adult"
                                          : item.Pax_type === 1
                                          ? "Child"
                                          : item.Pax_type === 2
                                          ? "Infant"
                                          : ""}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {`${item.TicketDetails[0].Ticket_Number}`}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {etrav &&
                            etrav.reprint &&
                            etrav.reprint.result &&
                            etrav.reprint.result.AirPNRDetails[0].Flights[0].Fares[0].FareDetails[0].Free_Baggage.Check_In_Baggage}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            {bookingDetail &&
                            bookingDetail.result &&
                            bookingDetail.result.itemInfos.AIR.travellerInfos&&
                            bookingDetail.result.itemInfos.AIR.travellerInfos.map(
                              (item, index) => {
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        borderLeft: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p style={{ fontSize: 12 }}>
                                        {index + 1}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p style={{ fontSize: 12 }}>
                                        {""}
                                        {`${item.ti} ${item.fN} ${item.lN}`}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.pt}
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      
                                        {Object.entries(item.pnrDetails).forEach(([key,value])=>{
                                                  <p
                                                  style={{
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                  }}
                                                >{key} </p>;
                                                })}
                                     
                                    </td>
                                    <td
                                      style={{
                                        borderBottom: "1px solid #333",
                                        padding: 2,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {/* {etrav &&
                            etrav.reprint &&
                            etrav.reprint.result &&
                            etrav.reprint.result.AirPNRDetails[0].Flights[0].Fares[0].FareDetails[0].Free_Baggage.Check_In_Baggage} */}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                      <table style={{ marginTop: "12px", width: "100%" }}>
                        <tbody>
                          <tr>
                            <td>
                              <p
                                style={{
                                  background: "#000",
                                  color: "#fff",
                                  padding: "5px",
                                  paddingLeft: 20,
                                  fontSize: 12,
                                  webkitPrintColorAdjust: "exact",
                                }}
                              >
                                Fare Details
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{
                          border: "1px solid #333",
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: 3,
                                fontSize: 12,
                                color: "#000",
                                paddingLeft: "20px",
                              }}
                            >
                              Total Price
                            </td>
                            <td
                              style={{
                                padding: 3,
                                fontWeight: "bold",
                              }}
                            >
                              <p>
                                INR{" "}
                                {/* {editprice
                                  ? editprice
                                  : ticket &&
                                    ticket.result &&
                                    ticket.result.Response &&
                                    ticket.result.Response.Response &&
                                    ticket.result.Response.Response
                                      .FlightItinerary.InvoiceAmount} */}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ marginTop: 12, width: "100%" }}>
                        <tbody>
                          <tr>
                            <td>
                              <p
                                style={{
                                  background: "#000",
                                  color: "#fff",
                                  padding: "5px",
                                  paddingLeft: "20px",
                                  fontSize: 12,
                                  webkitPrintColorAdjust: "exact",
                                }}
                              >
                                Important Information
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        style={{
                          width: "100%",
                          marginBottom: "40px",
                          paddingTop: "15px",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: "0 8px",
                                fontSize: 13,
                                color: "#000",
                                width: "100%",
                              }}
                            >
                              1 - You must web check-in on the airline website
                              and obtain a boarding pass.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0 8px",
                                fontSize: 13,
                                color: "#000",
                                width: "100%",
                              }}
                            >
                              2 - You must download & register on the Aarogya Setu App and carry a Valid ID
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0 8px",
                                fontSize: 13,
                                color: "#000",
                                width: "100%",
                              }}
                            >
                              3 - It is mandatory to wear a musk and carry other protective gear.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0 8px",
                                fontSize: 13,
                                color: "#000",
                                width: "100%",
                              }}
                            >
                              4 - Reach the terminal at least 2 hours prior to the departure for domestic flight and 4 hors prior to the departure of international flight .
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0 8px",
                                fontSize: 13,
                                color: "#000",
                                width: "100%",
                              }}
                            >
                              5 - For departure terminal please check with the airline first.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0 8px",
                                fontSize: 13,
                                color: "#000",
                                width: "100%",
                              }}
                            >
                              6 - Date & Time is calculated based on the local time of the city/destination.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0 8px",
                                fontSize: 13,
                                color: "#000",
                                width: "100%",
                              }}
                            >
                              7 - Use the Airline PNR for all Correspondence directly with the Airline.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0 8px",
                                fontSize: 13,
                                color: "#000",
                                width: "100%",
                              }}
                            >
                              8 - For rescheduling/cancellation within 4hours of the departure time contact the airline directly.
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0 8px",
                                fontSize: 13,
                                color: "#000",
                                width: "100%",
                              }}
                            >
                              9 - Your ability to travel is at the sole discretion of the airport authorities and we shall not be held responsible.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td>
              <LoadingButton
                id="myPrntbtn"
                onClick={() => window.print()}
                loadingPosition="start"
                startIcon={<PrintIcon />}
                variant="contained"
              >
                Print
              </LoadingButton>
            </td>
            <td>
              <LoadingButton
                id="download"
                loadingPosition="start"
                startIcon={<DownloadForOfflineIcon />}
                variant="contained"
              >
                Download
              </LoadingButton>
            </td>
            <td>
              <LoadingButton
                id="downloadwprice"
                loadingPosition="start"
                startIcon={<DownloadForOfflineIcon />}
                variant="contained"
              >
                Download without price
              </LoadingButton>
            </td>
            <td>
              <LoadingButton
                id="mail"
                // color='secondary'
                onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<EmailIcon />}
                variant="contained"
              >
                Mail
              </LoadingButton>
            </td>
            <td>
              <LoadingButton
                id="editprice"
                // color='secondary'
                onClick={() => dispatch(toggleEditTicketModal(true))}
                // loading={loading}
                loadingPosition="start"
                startIcon={<EditIcon />}
                variant="contained"
              >
                Edit Price
              </LoadingButton>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <Grid container spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}> <Box onClick={()=>history.push('/ticketroundtrip')} style={{textAlign: 'center',
    backgroundColor: '#1976d2',cursor:'pointer'}}>
        <h2 style={{color:'white'}}>Continue to Round Ticket</h2>
      </Box></Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      */}
      <EditTicketPriceModal />
    </>
  );
};

export default RoundTicket;
