import React from 'react';
import { Box, Grid } from '@material-ui/core';
import LoginForm from '../../components/reusableComponents/LoginForm';
import logo from '../../assets/images/BLUE PNG.png';
import download from '../../assets/images/download.png';
import googleplay from '../../assets/images/googleplay.png';

const LoginPage = () => {
	return (
		<div style={{ backgroundColor: '#a9e1fb', height: '100vh' }}>
			<Grid container>
				<Grid item xs={2}></Grid>
				<Grid item xs={8}>
					<Box
						style={{
							backgroundColor: 'white',
							// height: 600,
							marginTop: 60,
							borderRadius: 25,
							padding: 30,
						}}>
						<Grid container spacing={4}>
							<Grid item xs={6}>
								<img src={logo} style={{ width: '100%', height: 400 }} />
							</Grid>
							<Grid item xs={6}>
								<h1 style={{ textAlign: 'center' }}>WELCOME TO MILESZEROS</h1>
								<LoginForm />
							</Grid>
						</Grid>
					</Box>
					<Box
						style={{
							backgroundColor: 'white',
							height: 100,
							marginTop: 20,
							borderRadius: 25,
							padding: 10,
						}}>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}>
							<Box display={'flex'} alignItems={'center'}>
								<img src={download} style={{ height: 50 }} />
								<h1>Download App Now !</h1>
								<Box
									ml={2}
									style={{
										border: '1px solid #2e3592',
										padding: 10,
										borderRadius: 5,
									}}>
									GET APP LINK
								</Box>
							</Box>
							<Box>
								<img src={googleplay} />
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={2}></Grid>
			</Grid>
		</div>
	);
};

export default LoginPage;
