import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
function useQuery() {
	const { search } = useLocation();
	console.log('Search', search);
	return React.useMemo(() => new URLSearchParams(search), [search]);
}
const RailPg = () => {
	let query = useQuery();
	const dispatch = useDispatch();
	const history = useHistory();
	console.log(query.get('encdata'));
	// console.log('city', city);
	useEffect(() => {
		if (query.get('encdata')) {
			// let formData = {
			// 	validationString: query.get('validationString'),
			// };
			// dispatch(verifyAccountDispatch(formData, history));
		}
	}, []);
	return (
		<>
			<HeaderSection />
			{/* <div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: '#F8F8F8AD',
				}}>
				<div
					style={{
						left: '20%',
						top: '50%',
						zIndex: 1000,
						position: 'absolute',
					}}>
					{loadinguser ? (
						<div style={{ fontSize: 35 }}>please wait.....</div>
					) : (
						<div style={{ fontSize: 35 }} id='verify'>{`${
							verify && verify.result.message
						} Redirecting....................`}</div>
					)}
				</div>
			</div> */}
		</>
	);
};

export default RailPg;
