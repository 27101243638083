import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
// import FlightModalDetail from './FlightModalDetail';
import { agentbookinghistoryDispatch } from '../../reducers/UserReducer';
import { useHistory } from 'react-router-dom';
import { FlightMailTicketDispatch } from '../../reducers/HomeReducer';
import {
	setBookingHistoryID,
	setBusBookingHistoryID,
	setSelectedBookingHistory,
} from '../../reducers/UiReducer';
import CancelTicketModal from './CancelTicketModal';
import { busbookinghistoryDispatch } from '../../reducers/BusReducer';
// import BusModalDetail from './BusModalDetail';
// import CancelBusTicketModal from './CancelBusTicketModal';
const tableHeaders = [
	'ORDER_ID',
	'DEPART FROM',
	'JOURNEY TO',
	'DATE OF BOOKING',
	'JOURNEY DATE',
	'TICKET COST',
	'STATUS',
	'ACTION',
];

const BusBookingHistory = () => {
	const dispatch = useDispatch();
	const [val, setVal] = useState('');
	const { bookinghistory, bookinghistoryagent, agentlogin } = useSelector(
		(state) => state.user
	);
	const { busbookinghistory } = useSelector((state) => state.bus);
	console.log('busbookinghistory', busbookinghistory);
	const history = useHistory();
	useEffect(() => {
		dispatch(
			busbookinghistoryDispatch(agentlogin && agentlogin.result.result.id)
		);
	}, []);
	const [data, setData] = useState('');
	console.log('Booking', bookinghistoryagent);
	const handleMail = (item) => {
		let formMail = {
			orderId: item.orderId,
			userEmail: item.userEmail,
		};
		// dispatch(FlightMailTicketDispatch(formMail));
		dispatch(FlightMailTicketDispatch(formMail));
		window.alert('Ticket Send Successfully');
	};
	const handleSearch = (event) => {
		let value = event.target.value.toLowerCase();
		let results = [];
		console.log(value);
		// setVal(value);
		if (event.target.value.toLowerCase() === 'new') {
			results =
				bookinghistoryagent &&
				bookinghistoryagent.result &&
				bookinghistoryagent.result.flights.slice().sort((a, b) => {
					return b.id - a.id;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'old') {
			results =
				bookinghistoryagent &&
				bookinghistoryagent.result &&
				bookinghistoryagent.result.flights.slice().sort((a, b) => {
					return a.id - b.id;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'lh') {
			results =
				bookinghistoryagent &&
				bookinghistoryagent.result &&
				bookinghistoryagent.result.flights.slice().sort((a, b) => {
					return a.amount - b.amount;
				});
			setData(results);
		} else if (event.target.value.toLowerCase() === 'hl') {
			results =
				bookinghistoryagent &&
				bookinghistoryagent.result &&
				bookinghistoryagent.result.flights.slice().sort((a, b) => {
					return b.ticketCost - a.ticketCost;
				});
			setData(results);
		} else {
			results =
				bookinghistoryagent &&
				bookinghistoryagent.result &&
				bookinghistoryagent.result.flights.filter((item) => {
					return item.type.toLowerCase().includes(value.toLowerCase());
				});
			setData(results);
		}
		// results =
		//   bookinghistoryagent &&
		//   bookinghistoryagent.result &&
		//   bookinghistoryagent.result.flights.filter((item) => {
		//     return (
		//       item.orderId.toLowerCase().includes(value.toLowerCase()) ||
		//       item.userEmail.toLowerCase().includes(value.toLowerCase()) ||
		//       item.origin.toLowerCase().includes(value.toLowerCase()) ||
		//       item.destination.toLowerCase().includes(value.toLowerCase()) ||
		//       item.flightType.toLowerCase().includes(value.toLowerCase()) ||
		//       item.journeyType.toLowerCase().includes(value.toLowerCase()) ||
		//       item.journeyType.toLowerCase().includes(value.toLowerCase()) ||
		//       item.supplier.toLowerCase().includes(value.toLowerCase()) ||
		//       item.bookingStatus.toLowerCase().includes(value.toLowerCase()) ||
		//       item.travelDate.toLowerCase().includes(value.toLowerCase())
		//     );
		//   });
		// setData(results);
		// console.log(results);
	};
	console.log('Result', data);
	// const handleSearch =

	return (
		<>
			<div className='row ' style={{ padding: 15 }}>
				<Box display='flex' justifyContent='space-between'>
					<h3 className='fs-4 mb-3'>Booking Details</h3>
					<Box display='flex'>
						<Box display='flex' alignItems='center'>
							{/* <span>Filter</span> */}
							<select
								onChange={handleSearch}
								name='Filter by'
								id='cars'
								style={{ padding: 10, border: '1px solid grey' }}>
								<option value='booked'> Filter</option>
								<option value='booked'> Booked</option>
								<option value='cancelled'>Cancelled</option>
							</select>
						</Box>
						<Box display='flex' alignItems='center' ml={'5px'}>
							{/* <span>Filter</span> */}
							<select
								onChange={handleSearch}
								name='Sort by'
								id='cars'
								style={{ padding: 10, border: '1px solid grey' }}>
								<option value='booked'> Sort</option>
								<option value='new'> Newest to Oldest</option>
								<option value='old'>Oldest to Newest</option>
								<option value='lh'>Price Low-High</option>
								<option value='hl'>Price High-Low</option>
							</select>
						</Box>
					</Box>
				</Box>

				{/* </Box> */}
				<div className='col'>
					<table className='table bg-white rounded shadow-sm  table-hover'>
						<thead>
							<tr>
								<th scope='col' width='50'>
									#
								</th>
								{tableHeaders.map((i) => {
									return (
										<th scope='col' style={{ fontFamily: 'monospace' }}>
											{i}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{busbookinghistory &&
								busbookinghistory?.result?.buses?.length > 0 &&
								busbookinghistory?.result?.buses.map((i, index) => {
									return (
										<tr>
											<th
												scope='row'
												style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{index + 1}
											</th>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.orderId}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.departureCity}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.arrivalCity}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.dob}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.doj}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												₹ {i.ticketCost}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.bookingStatus}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{/* <i
													class='fa-solid fa-eye'
													style={{ cursor: 'pointer' }}
													onClick={() => {
														dispatch(setBusBookingHistoryID(i));
													}}></i>
												<i
													class='fa-solid fa-envelope'
													style={{ marginLeft: 5, cursor: 'pointer' }}
													onClick={() => handleMail(i)}></i>
												<i
													class='fa-solid fa-print'
													onClick={() =>
														history.push({
															pathname: '/busticketdisplay',
															state: i,
														})
													}
													style={{ marginLeft: 5, cursor: 'pointer' }}></i> */}
												<button
													onClick={() =>
														history.push({
															pathname: '/busticketdisplay',
															state: i,
														})
													}
													style={{
														background: 'green',
														paddingLeft: 10,
														paddingRight: 10,
														paddingTop: 5,
														paddingBottom: 5,
														color: 'white',
														marginLeft: 5,
														borderRadius: 25,
														marginTop: 5,
													}}>
													<span> Ticket</span>
												</button>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
			{/* <FlightModalDetail />
			<BusModalDetail />
			<CancelTicketModal />
			<CancelBusTicketModal /> */}
		</>
	);
};

export default BusBookingHistory;
