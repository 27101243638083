import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
const FlightDetailRowTBO = ({ item }) => {
	return (
		<tr>
			<td
				width='20%'
				style={{
					padding: 2,
					fontSize: 12,
					paddingLeft: 20,
				}}>
				<p>
					<strong style={{ color: '#000' }}>
						{item.Airline.AirlineCode}-{item.Airline.FlightNumber}
					</strong>
				</p>
				<p>
					<span style={{ fontWeight: 400 }}>{item.Airline.AirlineName}</span>
				</p>
			</td>
			<td
				width='10%'
				style={{
					padding: 2,
					fontSize: 12,
				}}>
				<p>
					<span style={{ fontWeight: 400 }}>{item.Airline.FareClass}</span>
				</p>
			</td>
			<td width='10%'>
				<p style={{ fontSize: 12 }}>
					<span style={{ fontWeight: 400 }}>Non Stop</span>
				</p>
			</td>
			<td width='20%'>
				<p style={{ fontSize: 12 }}>
					<strong color='#000'>
						{`${moment(item.Origin.DepTime).format('DD-MM-YYYY')} ${
							item.Origin.DepTime.split('T')[1]
						}`}
					</strong>
				</p>
				<p style={{ fontSize: 12 }}>
					<span style={{ fontWeight: 400 }}>
						{`${item.Origin.Airport.AirportName} (${item.Origin.Airport.AirportCode})`}
					</span>
				</p>
			</td>
			<td width='20%'>
				<p style={{ fontSize: 12 }}>
					<strong color='#000'>
						{`${moment(item.Destination.ArrTime).format('DD-MM-YYYY')} ${
							item.Destination.ArrTime.split('T')[1]
						}`}
					</strong>
				</p>
				<p style={{ fontSize: 12 }}>
					<span style={{ fontWeight: 400 }}>
						{`${item.Destination.Airport.AirportName} (${item.Destination.Airport.AirportCode})`}
					</span>
				</p>
			</td>
			<td width='10%'>
				<p style={{ fontSize: 12 }}>
					<strong color='#000'>{`${item.Duration} min`}</strong>
				</p>
			</td>
			<td width='10%'>
				<p style={{ fontSize: 12 }}>
					<span>{`${item?.Baggage} || ${item?.CabinBaggage} `}</span>
				</p>
			</td>
		</tr>
	);
};

export default FlightDetailRowTBO;
