import React from 'react';
import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: 'Mariners Forex',
  description:
    'Mariners Forex provides you online hotel bookings all over the world. Book cheap, budget and luxury hotels at best price from leading hotel booking site.',
  keywords:
    'online hotel booking, book hotels online, hotel booking site, cheap hotels, budget hotels, luxury hotels, star hotels, hotel booking, hotel reservation, hotel stay, accommodations',
};

export default Meta;
