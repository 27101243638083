const addBookingReturn = (
	location,
	agentlogin,
	payment,
	payableAmout,
	priceDetails2,
	priceDetails3,
	selectedFlight,
	dispatch,
	FlightAddBookingDispatch,
	PGCharge,
	BookingFormData,
	fareQuoteEtrav,
	fareQuote,
	fareQuoteReturn,
	handlePayment,
	TBOPARETURN
) => {
	const FareQuotePrice =
		Number(
			Math.round(fareQuote?.result?.Response?.Results?.Fare.Total_Amount)
		) +
		Number(
			Math.round(fareQuoteReturn?.result?.Response?.Results?.Fare.Total_Amount)
		);
	const OriginalPrice =
		Number(Math.round(location.state.selectedonward[0].Fare.Total_Amount)) +
		Number(Math.round(location.state.selectedReturn[0].Fare.Total_Amount));
	let formaddBooking = {
		orderId: location.state.orderID,
		supplier: `${location.state.selectedonward[0].Supplier}|${location.state.selectedReturn[0].Supplier}`,
		userEmail: location.state.formData.email,
		userPhone: location.state.formData.phone,
		user_type: agentlogin && agentlogin.status === 200 ? 'agent' : 'guest',
		origin: location.state.returnBody.Segments[0].Origin,
		destination: location.state.returnBody.Segments[0].Destination,
		travelDate: location.state.returnBody.Segments[0].PreferredDepartureTime,
		returnDate: location.state.returnBody.Segments[1].PreferredDepartureTime,
		journeyType: location.state.returnBody.JourneyType === 2 ? 'RoundTrip' : '',
		origin1: location.state.returnBody.Segments[1].Origin,
		destination1: location.state.returnBody.Segments[1].Destination,
		travelDate1: '',
		origin2: '',
		destination2: '',
		travelDate2: '',
		origin3: '',
		destination3: '',
		travelDate3: '',
		PGCharge: agentlogin
			? payment === 'offline'
				? 0
				: Number((Number(TBOPARETURN) * 2.5) / 100)
			: '',
		class: '',
		nop: `${location.state.returnBody.AdultCount} | ${location.state.returnBody.ChildCount} | ${location.state.returnBody.InfantCount} `,
		airlineType: '',
		carrier: `${location.state.selectedonward[0].AirlineCode}|${location.state.selectedReturn[0].AirlineCode}`,
		fareType: '',
		eTicketType: 'eTicket',
		actualFare:
			Number(
				Number(Math.round(location.state.selectedonward[0].Fare.Total_Amount)) -
					Number(Math.round(location.state.selectedonward[0].Fare.markup)) -
					Number(
						Math.round(location.state.selectedonward[0].Fare.partnerMarkup)
					) -
					Number(
						Math.round(location.state.selectedonward[0].Fare.agentMarkup)
					) -
					Number(Math.round(location.state.selectedonward[0].Fare.MFB_Discount))
			) +
			Number(
				Number(Math.round(location.state.selectedReturn[0].Fare.Total_Amount)) -
					Number(Math.round(location.state.selectedReturn[0].Fare.markup)) -
					Number(
						Math.round(location.state.selectedReturn[0].Fare.partnerMarkup)
					) -
					Number(
						Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
					) -
					Number(Math.round(location.state.selectedReturn[0].Fare.MFB_Discount))
			),
		markup: Number(
			Number(Math.round(location.state.selectedonward[0].Fare.markup)) +
				Number(Math.round(location.state.selectedReturn[0].Fare.markup))
		),
		partnerMarkup: Number(
			Number(Math.round(location.state.selectedonward[0].Fare.partnerMarkup)) +
				Number(Math.round(location.state.selectedReturn[0].Fare.partnerMarkup))
		),
		agentMarkup: Number(
			Number(Math.round(location.state.selectedonward[0].Fare.agentMarkup)) +
				Number(Math.round(location.state.selectedReturn[0].Fare.agentMarkup))
		),
		baseFare:
			Number(
				Number(Math.round(location.state.selectedonward[0].Fare.Total_Amount)) -
					Number(
						Math.round(location.state.selectedonward[0].Fare.partnerMarkup)
					) -
					Number(
						Math.round(location.state.selectedonward[0].Fare.agentMarkup)
					) -
					Number(Math.round(location.state.selectedonward[0].Fare.MFB_Discount))
			) +
			Number(
				Number(Math.round(location.state.selectedReturn[0].Fare.Total_Amount)) -
					Number(
						Math.round(location.state.selectedReturn[0].Fare.partnerMarkup)
					) -
					Number(
						Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
					) -
					Number(Math.round(location.state.selectedReturn[0].Fare.MFB_Discount))
			),
		totalFare: Number(TBOPARETURN),
		payableAmount:
			payment === 'payworldline'
				? Number(payableAmout)
				: Number(payableAmout) -
				  Number(Number((Number(TBOPARETURN) * 2.5) / 100)),
		ticketCost:
			payment === 'payworldline'
				? Number(payableAmout) +
				  Number(
						Math.round(location.state.selectedonward[0].Fare.agentMarkup)
				  ) +
				  Number(Math.round(location.state.selectedReturn[0].Fare.agentMarkup))
				: Number(payableAmout) -
				  Number(Number((Number(TBOPARETURN) * 2.5) / 100)),
		totalEarn: 0,
		cashBack: '0.00',
		couponDiscount: 0.0,
		couponDetails: '',
		pricingDetails: [priceDetails2, priceDetails3],
		selectedFlight: [
			location.state.selectedonward[0],
			location.state.selectedReturn[0],
		],
		// airlineType: '',
		selectedPax: BookingFormData,
		ticketSent: 0,
		availableOnNewPrice:
			Number(FareQuotePrice) > Number(OriginalPrice)
				? true
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? true
				: false,
		priceChange: Number(
			Number(Math.round(FareQuotePrice)) - Number(Math.round(OriginalPrice))
		),
		priceValue:
			Number(Math.round(FareQuotePrice)) > Number(Math.round(OriginalPrice))
				? 'PRICEUP'
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? 'PRICEDOWN'
				: 'NOCHANGE',
		paymentMode: agentlogin
			? payment === 'offline'
				? 'OFFLINE'
				: 'ONLINE'
			: 'ONLINE',
		agent_id:
			agentlogin && agentlogin.status === 200
				? agentlogin && agentlogin.result.result.id
				: 0,
	};
	dispatch(
		FlightAddBookingDispatch(
			formaddBooking,
			payment,
			payableAmout,
			handlePayment,
			location
		)
	);
	console.log('add booking', formaddBooking);
};
export default addBookingReturn;
