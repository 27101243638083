import React from 'react';
import {
	Box,
	Drawer,
	makeStyles,
	Divider,
	Avatar,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import { toggleDrawerModal, toggleLoginModal } from '../reducers/UiReducer';
import { useDispatch, useSelector } from 'react-redux';
import TitleText from './reusableComponents/TitleText';
import BodyText from './reusableComponents/BodyText';
const drawerWidth = '20vw';

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		[theme.breakpoints.down('md')]: {
			width: '20vw',
		},
		'@media (max-width:820px)': {
			width: '25vw',
		},
	},
	drawerPaper: {
		width: drawerWidth,
		[theme.breakpoints.down('md')]: {
			width: '20vw',
		},
		'@media (max-width:820px)': {
			width: '42vw',
		},
	},
	root: {
		'& .MuiTypography-root': {
			fontSize: 14,
		},
	},
}));

const Drawers = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { drawer } = useSelector((state) => state.ui);
	const { agentlogin } = useSelector((state) => state.user);

	return (
		<>
			<Drawer
				anchor={'left'}
				open={drawer}
				onClose={() => dispatch(toggleDrawerModal(false))}
				style={{ width: '30%' }}
				className={classes.drawer}
				onKeyDown
				classes={{
					paper: classes.drawerPaper,
				}}>
				<div>
					<Box
						style={{ textAlign: 'center' }}
						my={2}
						mx={2}
						p={1}
						alignItems='center'>
						<Box>
							{agentlogin &&
							agentlogin.result &&
							agentlogin.result.result &&
							agentlogin.result.result.access_token ? (
								<Box display='flex' alignItems='center'>
									<Box>
										<Avatar>
											{agentlogin &&
												agentlogin.result &&
												agentlogin.result.result &&
												agentlogin.result.result.name.charAt(0)}
										</Avatar>
									</Box>
									<Box ml={'15px'}>
										<TitleText textStyle={{ fontSize: 16 }}>
											Hello{' '}
											{agentlogin &&
												agentlogin.result &&
												agentlogin.result.result &&
												agentlogin.result.result.name}
										</TitleText>
									</Box>
								</Box>
							) : (
								<Box display='flex' alignItems='center'>
									<Box>
										<Avatar>H</Avatar>
									</Box>
									<Box ml={'15px'}>
										<TitleText textStyle={{ fontSize: 16 }}>
											Hello Guest!
										</TitleText>
										<Box onClick={() => dispatch(toggleLoginModal(true))}>
											<BodyText textStyle={{ cursor: 'pointer', fontSize: 14 }}>
												Login/Signup
											</BodyText>
										</Box>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
				</div>
				<Divider />
				<List>
					<div className={classes.root}>
						<ListItem button key={'Inbox'}>
							<ListItemIcon>
								<i class='fa-solid fa-house'></i>
							</ListItemIcon>
							<ListItemText primary={'Home'} />
						</ListItem>
						<Divider />
						<ListItem button key={'Inbox'}>
							<ListItemIcon>
								<i class='fa-solid fa-suitcase-rolling'></i>
							</ListItemIcon>
							<ListItemText primary={'My Booking'} />
						</ListItem>
						<Divider />
						<ListItem button key={'Inbox'}>
							<ListItemIcon>
								<i class='fa-solid fa-money-bill-wave'></i>
							</ListItemIcon>
							<ListItemText primary={'Make Payment'} />
						</ListItem>
						<Divider />
						<ListItem button key={'Inbox'}>
							<ListItemIcon>
								<i class='fa-solid fa-money-bill-wave'></i>
							</ListItemIcon>
							<ListItemText primary={'Bank Account Details'} />
						</ListItem>
						<Divider />
						<ListItem button key={'Inbox'}>
							<ListItemIcon>
								<i class='fa-brands fa-whatsapp-square'></i>
							</ListItemIcon>
							<ListItemText primary={'Connect with us on WhatApp'} />
						</ListItem>
						<Divider />
						<ListItem button key={'Inbox'}>
							<ListItemIcon>
								<i class='fa-brands fa-telegram'></i>
							</ListItemIcon>
							<ListItemText primary={'Connect with us on Telegram'} />
						</ListItem>
						<Divider />
						<ListItem button key={'Inbox'}>
							<ListItemIcon>
								<i class='fa-brands fa-facebook'></i>
							</ListItemIcon>
							<ListItemText primary={'Connect with us on Telegram'} />
						</ListItem>
						<Divider />
					</div>
				</List>
			</Drawer>
		</>
	);
};

export default Drawers;
