import * as React from "react";
// import TextField from '@mui/material/TextField';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import {
  makeStyles,
  Button,
  MenuItem,
  LinearProgress,
  Box,
  TextField,
  InputAdornment,
  Grid,
  Tabs,
  Tab,
  Divider,
  withStyles,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setOrigin, setOriginO } from "../../reducers/UiReducer";
const useStyles = makeStyles((theme) => ({
  selectField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #959595",
      },
    },
    "& label": {
      display: "none",
    },
    // backgroundColor: "#eee",
    // padding: 2.5,
    // marginBottom: 30,
    width: "100%",
    border: "1px solid lightgrey",
    // marginTop: 10,
    borderRadius: 4,
    // [theme.breakpoints.down('xs')]: {
    // 	marginBottom: 15,
    // 	marginTop: 8,
    // },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInputBase-input": {
      fontSize: 12,
      padding: "12px",
      fontWeight: 600,
    },
    "& .MuiInputAdornment-root": {
      marginRight: 14,
    },
    // "& .MuiSvgIcon-root": {
    //   width: "2em",
    //   height: "2em",
    // },
    "& .MuiInput-root": {
      marginTop: 0,
    },
  },
  InputPropsStyle: {
    backgroundColor: "white",
    borderRadius: 25,
  },
  inputPropsStyle: {
    padding: 14,
  },
  createButton: {
    backgroundColor: "#39c9bb",
    color: "white",
    borderRadius: 25,
    width: "100%",
    padding: "11px 8px",
    "&:hover": {
      backgroundColor: "#39c9bb",
    },
  },
  tabBorder: {
    borderBottom: "2px solid transparent",
    color: "#7B95AB",
    fontWeight: 400,
  },
  tabBorderColor: {
    borderBottom: "2px solid #313131",
    color: "#00325B",
    fontWeight: 700,
  },
  tab: {
    [theme.breakpoints.up("sm")]: {
      "& .MuiTab-root": {
        minWidth: 241,
        fontSize: 22,
      },
    },
  },
  auto: {
    "& input": {
      padding: "20px 18px",
    },
    "& div": {
      "& div": {
        // position: 'fixed',
        // maxHeight: '20%',
        // left: 'none',
        padding: 3,
      },
    },
    "& .css-xrz6uu-MuiAutocomplete-root .MuiInput-root .MuiInput-input": {
      padding: "11px 4px 4px 0px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiFormLabel-root": {
      marginLeft: 5,
      fontSize: 13,
    },
    "& .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator":
      {
        marginTop: -10,
      },
  },
  root: {
    "& .InputFromTo": {
      "& .DayPickerInput input": {
        padding: "20px 18px",
      },
    },
  },
  j: {
    "& .MuiBox-root": {
      // alignContent: 'stretch',
      // justifyContent: 'flex-start',
      // flexWrap: 'wrap',
      // display: 'flex',
      display: "flex",
      alignContent: "stretch",
      flexWrap: "wrap",
    },
  },
  down: {
    // opacity: 1,

    // position: 'absolute',
    // top: '100%',
    // left: 0,
    marginTop: "385px",
    minWidth: 300,
    padding: 10,
    background: "white",
    position: "absolute",
    boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
    // opacity: 0,
    // transform: 'scaleY(0)',
    transformOrigin: "top",
    transition: "all .3s ease",
    "@media(min-device-width: 500px) and (max-device-width: 1120px)": {
      marginLeft: 137,
    },
  },
  dayWithDotContainer: {
    position: "relative",
  },
  dayWithDot: {
    position: "absolute",
    height: 0,
    width: 0,
    border: "2px solid",
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    right: "50%",
    transform: "translateX(1px)",
    top: "80%",
  },
  new: {
    "& .css-mvmu1r": {
      marginTop: 18,
    },

    "& .MuiButtonBase-root": {
      fontSize: 20,
    },
    "& input::placeholder": {
      fontSize: "30px",
    },
  },
}));
export default function DesktopDateBus() {
  const [value, setValue] = React.useState(new Date());
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  dispatch(setOrigin(convert(value)));
  dispatch(setOriginO(value));
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="From"
        value={value}
        disablePast
        inputFormat="dd/MM/yyyy"
        minDate={new Date("2017-01-01")}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.selectField}
            onClick={(e) => setIsOpen(true)}
          />
        )}
      />
    </LocalizationProvider>
  );
}
