import React from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Avatar,
	Chip,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Slider,
	withStyles,
	FormControlLabel,
	Checkbox,
	Divider,
	useMediaQuery
} from '@material-ui/core';
import TitleText from './TitleText';
import BodyText from './BodyText';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
function convertH2M(timeInHour) {
	// console.log(timeInHour);
	var timeParts = timeInHour.split(':');
	return Number(timeParts[0]) * 60 + Number(timeParts[1]);
}
function timeConvert(n) {
	var num = n;
	var hours = num / 60;
	var rhours = Math.floor(hours);
	var minutes = (hours - rhours) * 60;
	var rminutes = Math.round(minutes);
	return rhours + ' hr and ' + rminutes + ' min.';
}
const SelectedOnwardEtravRow = ({ i, index }) => {
	const location = useLocation()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.down("xs"));
	return (
		<>
		<Box
		  display="flex"
		  justifyContent="space-between"
		  mt={index > 0 ? "20px" : ""}
		>
		  <Box display="flex" alignItems="center">
			<Box alignItems="center" display="flex">
			  <TitleText
				textStyle={{
				  fontFamily: "unset",
				  fontWeight: 500,
				  fontSize: 18,
				}}
			  >
				{i.Origin}
			  </TitleText>
			  -
			  <TitleText
				textStyle={{
				  fontFamily: "unset",
				  fontWeight: 500,
				  fontSize: 18,
				}}
			  >
				{i.Destination}
			  </TitleText>
			</Box>
			<Box
			  ml={"30px"}
			  style={{
				background: "rgb(239, 51, 57)",
				padding: "3px 15px",
				borderRadius: "50px",
				color: "white",
				fontFamily: "unset",
				fontWeight: 500,
			  }}
			>
			  <TitleText two>ONWARD</TitleText>
			</Box>
		  </Box>
		  <Box>{/* <BodyText>Fare Rule</BodyText> */}</Box>
		</Box>
		<Box mt={"20px"}>
		  <BodyText three textStyle={{ fontSize: 16 }}>
			{" "}
			{`${new Date(
			  i.Departure_DateTime
			).toLocaleString("en-us", {
			  weekday: "short",
			})} ,  ${new Date(
				i.Departure_DateTime
			).getDate()} ${new Date(
				i.Departure_DateTime
			).toLocaleString("en-us", {
			  month: "short",
			})}`}
			
		  </BodyText>
		</Box>
		<Box
		  style={{
			background: "white",
			padding: "10px 15px",
			border: "1px solid #e6e6e6",
			marginTop: "10px",
			borderRadius: 5,
		  }}
		>
		  <Grid container style={{ alignItems: "center" }}>
			<Grid item xs={12} sm={3} lg={3}>
			  <Box
				display={matches ? "flex" : ""}
				justifyContent={
				  matches ? "space-between" : ""
				}
				alignItems={matches ? "center" : ""}
			  >
				<TitleText
				  textStyle={{
					fontFamily: "unset",
					fontWeight: 500,
					fontSize: 18,
				  }}
				>
				  {i.Origin_City}
				  {/* {i.Origin.Airport.AirportCode}) */}
				</TitleText>
				<TitleText
				  three
				  textStyle={{
					fontFamily: "unset",
					fontWeight: 600,
					fontSize: matches ? 16 : "",
				  }}
				>
				  {i.Departure_DateTime.substr(11, 5)}
				</TitleText>
			  </Box>
			</Grid>
			<Grid
			  item
			  xs={12}
			  sm={3}
			  lg={3}
			  style={{ marginTop: matches ? 10 : "" }}
			>
			  <Box
				style={{
				  padding: "2px 20px",
				  background: "rgb(239, 51, 57)",
				  color: "white",
				  borderRadius: 25,
				}}
			  >
				<BodyText
				  three
				  textStyle={{
					fontSize: 16,
					textAlign: "center",
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
				  }}
				>
				  {timeConvert(convertH2M(i.Duration))}
				</BodyText>
			  </Box>
			</Grid>
			<Grid
			  item
			  xs={12}
			  sm={3}
			  lg={3}
			  style={{
				textAlign: "center",
				marginTop: matches ? 10 : "",
			  }}
			>
			  <Box
				display={matches ? "flex" : ""}
				justifyContent={
				  matches ? "space-between" : ""
				}
				alignItems={matches ? "center" : ""}
			  >
				<TitleText
				  textStyle={{
					fontFamily: "unset",
					fontWeight: 500,
					fontSize: 18,
				  }}
				>
				  {i.Destination_City}
				  {/* {i.Origin.Airport.AirportCode}) */}
				</TitleText>
				<TitleText
				  three
				  textStyle={{
					fontFamily: "unset",
					fontWeight: 600,
					fontSize: matches ? 16 : "",
				  }}
				>
				  {i.Arrival_DateTime.substr(11, 5)}
				</TitleText>
			  </Box>
			</Grid>
			<Grid
			  item
			  xs={12}
			  sm={3}
			  lg={3}
			  style={{ textAlign: "center" }}
			>
			  <img
				src={`https://nitish.musafirbazar.com/static/media/${i.Airline_Code}.gif`}
				width="100px"
				height="80px"
			  ></img>
			  <p>{`${i.Airline_Name} (${i.Airline_Code}-${i.Flight_Number})`}</p>
			</Grid>
		  </Grid>

		  {/* <Grid item xs={12} sm={3} lg={3}>
			<Box textAlign="center" display='flex'>
			  <img
				src={`https://nitish.musafirbazar.com/static/media/${i.Airline_Code}.gif`}
				width="50px"
				height="25px"
			  ></img>
			  <BodyText three textStyle={{ fontSize: 16 }}>
				{i.Airline_Name}
			  </BodyText>
			  <BodyText three textStyle={{ fontSize: 16 }}>
				{i.Airline_Code}-{i.Flight_Number}| Economy
			  </BodyText>
			  <BodyText three textStyle={{ fontSize: 16 }}>
				Check-In : 1Pc - 15Kgs
			  </BodyText>
			  <BodyText three textStyle={{ fontSize: 16 }}>
				Cabin : 7Kgs
			  </BodyText>
			</Box>
		  </Grid> */}
		</Box>
	  </>
	);
};

export default SelectedOnwardEtravRow;
