import React, { useState, useEffect } from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	withStyles,
	Checkbox,
	Divider,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import {
	toggleGSTModal,
	// toggleSessionExpireModal,
	toggleTermModal,
} from '../../reducers/UiReducer';
import { useDispatch } from 'react-redux';
import AddGSTModal from '../../components/reusableComponents/AddGSTModal';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import FlightSummaryForm from '../../form/FlightSummaryForm';
import Drawers from '../../components/Drawers';
import {
	agentlogogetDispatch,
	walletBalanceAgentDispatch,
} from '../../reducers/UserReducer';
import Login from '../../components/reusableComponents/Login';
import SelectedOnwardEtravRow from '../../components/reusableComponents/SelectedOnwardEtravRow';
import SelectedReturnEtravRow from '../../components/reusableComponents/SelectedReturnEtravRow';
import SelectedOnwardTboRow from '../../components/reusableComponents/SelectedOnwardTboRow';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import SessionExpireModal from '../../components/reusableComponents/SessionExpireModal';
import Footer from './Footer';
import SelectedOnwardTJKRow from '../../components/reusableComponents/SelectedOnwardTJKRow';
import FlightSummaryFDForm from '../../form/FlightSummaryFDForm';
import APIFailedErrorModal from '../../components/reusableComponents/APIFailedErrorModal';
import SessionExpiredModal from '../../components/reusableComponents/SessionExpiredModal';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiChip-sizeSmall': {
			height: 36,
		},
	},
	accodin: {
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-between',
		},
	},
	radio: {
		'& .MuiTypography-body1': {
			fontSize: 13,
		},
	},
}));
const GreenCheckbox = withStyles({
	root: {
		color: 'rgb(239, 51, 57)',
		'&$checked': {
			color: 'rgb(239, 51, 57)',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const FDSummaryFlight = () => {
	const classes = useStyles();
	const [value, setValue] = React.useState('');
	const [value2, setValue2] = React.useState(false);
	const [val, setVal] = React.useState(false);
	const [b, setB] = React.useState(false);
	const { etravflightFare, baggageInfo, mealInfo } = useSelector(
		(state) => state.ui
	);
	const { agentlogin } = useSelector((state) => state.user);
	const { curr } = useSelector((state) => state.ui);
	const theme = useTheme();
	const location = useLocation();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const dispatch = useDispatch();
	const history = useHistory();

	const handleClick = (event) => {
		if (event.target.value === value) {
			setValue('');
		} else {
			setValue(event.target.value);
		}
	};
	console.log(value);

	const [check, setCheck] = useState(false);
	const handleCheck = (event) => {
		// if (gst) {
		dispatch(toggleTermModal(true));
		// }

		// setCheck(event.target.checked);
	};
	useEffect(() => {
		window.scrollTo(0, 0);
		// dispatch(fetchCurrencyDispatch())
	}, []);
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	function convertH2M(timeInHour) {
		console.log(timeInHour);
		var timeParts = timeInHour.split(':');
		return Number(timeParts[0]) * 60 + Number(timeParts[1]);
	}
	// const Convert = Number(curr==='INR'?currency.conversion_rates.INR:curr==='USD'?currency.conversion_rates.USD:curr==='AFN'?currency.conversion_rates.AFN:1)
	const countContainer = document.getElementById('countdown-number');
	const countContainer2 = document.getElementById('countdown-number2');

	// variable to store count
	let hr;
	let remainingTime = 100;

	// variable to store time interval
	let timer;
	const renderTime = () => {
		// decement time
		remainingTime -= 1;
		// render count on the screen

		const min = Math.floor(remainingTime / 60);
		let second = remainingTime % 60;
		countContainer.innerHTML = `${min}:${second}min`;
		// timeout on zero
		if (remainingTime === 0) {
			isStopped = true;
			clearInterval(timer);
			// Play audio on timeout
			// timeoutAudio.play();
			remainingTime = 100;
			// dispatch(toggleSessionExpireModal(true));
			setValue2(false);
			// alert('Finish');
		}
	};
	// Variable to track whether timer is running or not
	let isStopped = true;
	console.log(location);
	const startTimer = () => {
		if (isStopped) {
			isStopped = false;
			countContainer.innerHTML = remainingTime;
			timer = setInterval(renderTime, 1000);
			console.log('first');
		}
	};
	const resetTimer = () => {
		isStopped = true;
		clearInterval(timer);
		remainingTime = 30;
		countContainer.innerHTML = remainingTime;
	};
	const handleC = () => {
		console.log('Hello wlc');
		setValue2(true);
	};
	useEffect(() => {
		if (agentlogin && agentlogin.status === 200) {
			let logodata = {
				agent_id: agentlogin && agentlogin.result.result.id,
			};
			dispatch(agentlogogetDispatch(logodata));
			// let walletform = {
			// 	userEmail: agentlogin && agentlogin.result.result.email,
			// 	userId: ,
			// };
			dispatch(
				walletBalanceAgentDispatch(
					agentlogin && agentlogin.result.result.email,
					agentlogin && agentlogin.result.result.id
				)
			);
		}
	}, []);

	return (
		<>
			<HeaderSection id={1} />

			<Box
				style={{
					background: 'white',
					paddingLeft: 30,
					paddingRight: 30,
					marginTop: 50,
				}}>
				{/* <Container> */}
				<Grid container spacing={2}>
					<Grid item xs={12} lg={8}>
						<Box display='flex' justifyContent='space-between' mt={'30px'}>
							<Box>
								<TitleText
									textStyle={{
										fontFamily: 'unset',
										fontWeight: 500,
										fontSize: 22,
									}}>
									Review Your Booking
								</TitleText>
							</Box>
							<Box display='flex' alignItems='center'>
								{/* <i class="fa-solid fa-clock"></i> */}
								{/* <p style={{ marginLeft: 10 }}>Session Expires in</p> */}
								<Box display='flex' style={{ marginLeft: 5 }}>
									<div id='countdown-number' style={{ color: 'blue' }}></div>
								</Box>
							</Box>
						</Box>
						<Box
							style={{
								padding: 15,
								background: 'white',
								color: 'gray',
								border: '1px solid #e6e6e6',
								margin: '0 0 20px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
							{location.state.flightdetail.Supplier === 'MFB' ? (
								<>
									<Box
										display='flex'
										justifyContent='space-between'
										// mt={index > 0 ? '20px' : ''}
									>
										<Box display='flex' alignItems='center'>
											<Box alignItems='center' display='flex'>
												<TitleText
													textStyle={{
														fontFamily: 'unset',
														fontWeight: 500,
														fontSize: 18,
													}}>
													{
														location.state.flightdetail.Segments[0].Origin
															.Airport.AirportCode
													}
												</TitleText>
												-
												<TitleText
													textStyle={{
														fontFamily: 'unset',
														fontWeight: 500,
														fontSize: 18,
													}}>
													{
														location.state.flightdetail.Segments[0].Destination
															.Airport.AirportCode
													}
												</TitleText>
											</Box>

											<Box
												ml={'30px'}
												style={{
													background: 'rgb(239, 51, 57)',
													padding: '3px 15px',
													borderRadius: '50px',
													color: 'white',
													fontFamily: 'unset',
													fontWeight: 500,
												}}>
												<TitleText two>ONWARD</TitleText>
											</Box>
										</Box>
										<Box>{/* <BodyText>Fare Rule</BodyText> */}</Box>
									</Box>
									<Box mt={'20px'}>
										<BodyText three textStyle={{ fontSize: 16 }}>
											{' '}
											{`${new Date(
												location.state.flightdetail.Segments[0].Origin.DepTime
											).toLocaleString('en-us', {
												weekday: 'short',
											})} ,  ${new Date(
												location.state.flightdetail.Segments[0].Origin.DepTime
											).getDate()} ${new Date(
												location.state.flightdetail.Segments[0].Origin.DepTime
											).toLocaleString('en-us', {
												month: 'short',
											})}`}
										</BodyText>
									</Box>
									<Box
										style={{
											background: 'white',
											padding: '10px 15px',
											border: '1px solid #e6e6e6',
											marginTop: '10px',
											borderRadius: 5,
										}}>
										<Grid container style={{ alignItems: 'center' }}>
											<Grid item xs={12} sm={3} lg={3}>
												<Box
													display={matches ? 'flex' : ''}
													justifyContent={matches ? 'space-between' : ''}
													alignItems={matches ? 'center' : ''}>
													<TitleText
														textStyle={{
															fontFamily: 'unset',
															fontWeight: 500,
															fontSize: 18,
														}}>
														{
															location.state.flightdetail.Segments[0].Origin
																.Airport.AirportCode
														}
														{/* {
															location.state.selectedSearch.sector.split(
																'//'
															)[0]
														} */}
														{/* {i.Origin.Airport.AirportCode}) */}
													</TitleText>
													<TitleText
														three
														textStyle={{
															fontFamily: 'unset',
															fontWeight: 600,
															fontSize: matches ? 16 : '',
														}}>
														{
															location.state.flightdetail.Segments[0].Origin.DepTime.split(
																'T'
															)[1]
														}
													</TitleText>
												</Box>
											</Grid>
											<Grid
												item
												xs={12}
												sm={3}
												lg={3}
												style={{ marginTop: matches ? 10 : '' }}>
												<Box
													style={{
														padding: '2px 20px',
														background: 'rgb(239, 51, 57)',
														color: 'white',
														borderRadius: 25,
													}}>
													<BodyText
														three
														textStyle={{
															fontSize: 16,
															textAlign: 'center',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}>
														{/* {timeConvert(convertH2M(i.Duration))} */}
													</BodyText>
												</Box>
											</Grid>
											<Grid
												item
												xs={12}
												sm={3}
												lg={3}
												style={{
													textAlign: 'center',
													marginTop: matches ? 10 : '',
												}}>
												<Box
													display={matches ? 'flex' : ''}
													justifyContent={matches ? 'space-between' : ''}
													alignItems={matches ? 'center' : ''}>
													<TitleText
														textStyle={{
															fontFamily: 'unset',
															fontWeight: 500,
															fontSize: 18,
														}}>
														{
															location.state.flightdetail.Segments[0]
																.Destination.Airport.AirportCode
														}
														{/* {
															location.state.selectedSearch.sector.split(
																'//'
															)[1]
														} */}
														{/* {i.Origin.Airport.AirportCode}) */}
													</TitleText>
													<TitleText
														three
														textStyle={{
															fontFamily: 'unset',
															fontWeight: 600,
															fontSize: matches ? 16 : '',
														}}>
														{
															location.state.flightdetail.Segments[0].Destination.ArrTime.split(
																'T'
															)[1]
														}
													</TitleText>
												</Box>
											</Grid>
											<Grid
												item
												xs={12}
												sm={3}
												lg={3}
												style={{ textAlign: 'center' }}>
												<img
													src={`https://nitish.musafirbazar.com/static/media/${location.state.flightdetail.Segments[0].Airline.AirlineCode}.gif`}
													width='100px'
													height='80px'></img>
												<p>{`${location.state.flightdetail.Segments[0].Airline.AirlineName} (${location.state.flightdetail.Segments[0].Airline.FlightNumber})`}</p>
											</Grid>
										</Grid>
									</Box>
								</>
							) : (
								<>
									<Box
										display='flex'
										justifyContent='space-between'
										// mt={index > 0 ? '20px' : ''}
									>
										<Box display='flex' alignItems='center'>
											<Box alignItems='center' display='flex'>
												<TitleText
													textStyle={{
														fontFamily: 'unset',
														fontWeight: 500,
														fontSize: 18,
													}}>
													{location.state.flightdetail.origin}
												</TitleText>
												-
												<TitleText
													textStyle={{
														fontFamily: 'unset',
														fontWeight: 500,
														fontSize: 18,
													}}>
													{location.state.flightdetail.destination}
												</TitleText>
											</Box>

											<Box
												ml={'30px'}
												style={{
													background: 'rgb(239, 51, 57)',
													padding: '3px 15px',
													borderRadius: '50px',
													color: 'white',
													fontFamily: 'unset',
													fontWeight: 500,
												}}>
												<TitleText two>ONWARD</TitleText>
											</Box>
										</Box>
										<Box>{/* <BodyText>Fare Rule</BodyText> */}</Box>
									</Box>
									<Box mt={'20px'}>
										{/* <BodyText three textStyle={{ fontSize: 16 }}>
											{' '}
											{`${new Date(
												location.state.body.DepartureDate
											).toLocaleString('en-us', {
												weekday: 'short',
											})} ,  ${new Date(
												location.state.body.DepartureDate
											).getDate()} ${new Date(
												location.state.body.DepartureDate
											).toLocaleString('en-us', {
												month: 'short',
											})},2022`}
										</BodyText> */}
									</Box>
									<Box
										style={{
											background: 'white',
											padding: '10px 15px',
											border: '1px solid #e6e6e6',
											marginTop: '10px',
											borderRadius: 5,
										}}>
										<Grid container style={{ alignItems: 'center' }}>
											<Grid item xs={12} sm={3} lg={3}>
												<Box
													display={matches ? 'flex' : ''}
													justifyContent={matches ? 'space-between' : ''}
													alignItems={matches ? 'center' : ''}>
													<TitleText
														textStyle={{
															fontFamily: 'unset',
															fontWeight: 500,
															fontSize: 18,
														}}>
														{location.state.flightdetail.origin}
														{/* {
															location.state.selectedSearch.sector.split(
																'//'
															)[0]
														} */}
														{/* {i.Origin.Airport.AirportCode}) */}
													</TitleText>
													<TitleText
														three
														textStyle={{
															fontFamily: 'unset',
															fontWeight: 600,
															fontSize: matches ? 16 : '',
														}}>
														{location.state.flightdetail.departure_time}
													</TitleText>
												</Box>
											</Grid>
											<Grid
												item
												xs={12}
												sm={3}
												lg={3}
												style={{ marginTop: matches ? 10 : '' }}>
												<Box
													style={{
														padding: '2px 20px',
														background: 'rgb(239, 51, 57)',
														color: 'white',
														borderRadius: 25,
													}}>
													<BodyText
														three
														textStyle={{
															fontSize: 16,
															textAlign: 'center',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}>
														{/* {timeConvert(convertH2M(i.Duration))} */}
													</BodyText>
												</Box>
											</Grid>
											<Grid
												item
												xs={12}
												sm={3}
												lg={3}
												style={{
													textAlign: 'center',
													marginTop: matches ? 10 : '',
												}}>
												<Box
													display={matches ? 'flex' : ''}
													justifyContent={matches ? 'space-between' : ''}
													alignItems={matches ? 'center' : ''}>
													<TitleText
														textStyle={{
															fontFamily: 'unset',
															fontWeight: 500,
															fontSize: 18,
														}}>
														{location.state.flightdetail.destination}
														{/* {
															location.state.selectedSearch.sector.split(
																'//'
															)[1]
														} */}
														{/* {i.Origin.Airport.AirportCode}) */}
													</TitleText>
													<TitleText
														three
														textStyle={{
															fontFamily: 'unset',
															fontWeight: 600,
															fontSize: matches ? 16 : '',
														}}>
														{location.state.flightdetail.arival_time}
													</TitleText>
												</Box>
											</Grid>
											<Grid
												item
												xs={12}
												sm={3}
												lg={3}
												style={{ textAlign: 'center' }}>
												<img
													src={`https://nitish.musafirbazar.com/static/media/${
														location.state.flightdetail.flight_number.split(
															' '
														)[0]
													}.gif`}
													width='100px'
													height='80px'></img>
												<p>{`${location.state.flightdetail.airline} (${location.state.flightdetail.flight_number})`}</p>
											</Grid>
										</Grid>
									</Box>
								</>
							)}
						</Box>

						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<TitleText
								textStyle={{
									fontFamily: 'unset',
									fontWeight: 500,
									fontSize: 20,
								}}>
								Contact Information
							</TitleText>
							<span style={{ cursor: 'pointer', color: 'blue' }}>
								<u>Choose Another Fare</u>
							</span>
						</Box>
						<FlightSummaryFDForm />
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box style={{ position: 'sticky', top: 84 }}>
							<Box
								style={{
									background: 'white',
									padding: '10px 15px',
									border: '1px solid #e6e6e6',
									marginTop: '62px',
									boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
									borderRadius: '5px',
									// position: 'sticky',
									// top: 84,
									zIndex: 1,
								}}>
								<Grid container alignItems='center'>
									<Grid item xs={4}>
										Fare Details
									</Grid>
									<Grid item xs={8}>
										<Divider style={{ background: 'black' }} />
									</Grid>
								</Grid>
								<Box
									style={{
										background: 'rgba(57,201,187,.17)',
										padding: '5px 10px',
										margin: '10px 0 0',
										borderRadius: '15px',
									}}>
									<BodyText textStyle={{ fontSize: 13 }}>
										AMOUNT TO BE PAID
									</BodyText>
									<TitleText textStyle={{ fontSize: 18 }}>
										{`₹ ${Number(
											Math.round(
												location.state.flightdetail.Supplier === 'MFB'
													? location.state.flightdetail.Fare.Total_Amount
													: location.state.flightdetail.totalFare
											)
										)}`}
									</TitleText>
									<span>(inclusive of all taxes.)</span>
								</Box>
								<Box display='flex' justifyContent='space-between' mt={'10px'}>
									<BodyText textStyle={{ fontSize: 13 }}>
										Traveller x
										{Number(location.state.body.AdultCount) +
											Number(location.state.body.ChildCount) +
											Number(location.state.body.InfantCount)}
									</BodyText>
								</Box>

								<Divider />
								{value ? (
									<Box
										display='flex'
										justifyContent='space-between'
										mt={'5px'}
										mb={'5px'}>
										<BodyText>Convenience Fees</BodyText>
										<TitleText two>Rs 200</TitleText>
									</Box>
								) : (
									''
								)}

								<Divider />
							</Box>
						</Box>
					</Grid>
				</Grid>
				{/* </Container> */}
			</Box>
			<Footer />
			<AddGSTModal />
			<Drawers />
			<SessionExpiredModal />
			{/* <SessionExpireModal /> */}
			<APIFailedErrorModal />
			<Login />
		</>
	);
};

export default FDSummaryFlight;
