import React, { useState } from 'react';
import {
	makeStyles,
	Button,
	Box,
	InputAdornment,
	withStyles,
	Checkbox,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import {
	agentloginDispatch,
	agentSignupDispatch,
	supplierloginDispatch,
	supplierSignupDispatch,
	travellerloginDispatch,
	travellerSignupDispatch,
} from '../../reducers/UserReducer';
import { toggleLoginModal } from '../../reducers/UiReducer';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		// backgroundColor: 'white',
		marginBottom: 20,
		width: '100%',
		// marginTop: 10,
		borderRadius: 25,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
			marginTop: 8,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 25,
			marginBottom: 16,
			// marginTop: 10,
		},
		// '& .MuiInputLabel-root': {
		// 	dispaly: 'none',
		// },
	},
	InputPropsStyle: {
		backgroundColor: 'white',
		borderRadius: 25,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: '#2e3592',
		color: 'white',
		borderRadius: 25,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: '#2e3592',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
		fontSize: 12,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 70,
				// fontSize: 16,
			},
		},
	},
	chec: {
		'& .MuiTypography-root': {
			// fontSize: 12,
		},
	},
}));
const GreenCheckbox = withStyles({
	root: {
		color: '#39c9bb',
		'&$checked': {
			color: '#39c9bb',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);

const LoginForm = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [tabvalue, setValue] = React.useState(0);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const [check, setCheck] = useState('agent');
	const handleTab = (event) => {
		setCheck(event);
	};
	const handleFormSubmit = (data, actions) => {
		if (check === 'agent') {
			if (data.userFirstName) {
				console.log(data);
				let formData = {
					userTitle: 'Mr',
					userFirstName: data.userFirstName,
					userLastName: data.userLastName,
					userPhone: data.phone2,
					userEmail: data.email2,
					password: data.password2,
					confirmpassword: data.confirm_password2,
					agencyPanCard: data.agencyPanCard,
					agencyName: data.agencyName,
				};
				console.log(formData);
				dispatch(agentSignupDispatch(formData, history));
				dispatch(toggleLoginModal(false));
			} else {
				let formData = {
					username: data.email,
					password: data.password, //converting password to base64
				};
				console.log(formData);
				dispatch(agentloginDispatch(formData, history));
				// dispatch(toggleLoginModal(false));
			}
		}
		if (check === 'traveller') {
			if (data.userFirstName) {
				console.log(data);
				let formData = {
					userTitle: 'Mr',
					userFirstName: data.userFirstName,
					userLastName: data.userLastName,
					userPhone: data.phone2,
					userEmail: data.email2,
					password: data.password2,
					confirmpassword: data.confirm_password2,
				};
				console.log(formData);
				dispatch(travellerSignupDispatch(formData, history));
				dispatch(toggleLoginModal(false));
			} else {
				let formData = {
					username: data.email,
					password: data.password, //converting password to base64
				};
				console.log(formData);
				dispatch(travellerloginDispatch(formData, history));
				dispatch(toggleLoginModal(false));
			}
		}
		if (check === 'supplier') {
			if (data.userFirstName) {
				console.log(data);
				let formData = {
					userTitle: 'Mr',
					userFirstName: data.userFirstName,
					userLastName: data.userLastName,
					userPhone: data.phone2,
					userEmail: data.email2,
					password: data.password2,
					confirmpassword: data.confirm_password2,
					// agencyPanCard: data.agencyPanCard,
					// agencyName: data.agencyName,
				};
				console.log(formData);
				dispatch(supplierSignupDispatch(formData, history));
				// dispatch(agentSignupDispatch(formData, history));
				dispatch(toggleLoginModal(false));
			} else {
				let formData = {
					username: data.email,
					password: data.password, //converting password to base64
				};
				console.log(formData);
				dispatch(supplierloginDispatch(formData));
				// dispatch(agentloginDispatch(formData, history));
				dispatch(toggleLoginModal(false));
			}
		}

		// dispatch(loginDispatch(formData, actions, history, checked));
	};
	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
			}}
			validate={(values) => {
				const errors = {};
				// if (tabvalue === 0 && values.phone) {
				// }
				// if (tabvalue === 0) {
				// 	if (!values.email) {
				// 		errors.email = 'Required';
				// 	} else if (
				// 		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
				// 	) {
				// 		errors.email = 'Invalid email address';
				// 	}
				// 	if (!values.password) {
				// 		errors.password = 'Required';
				// 	}
				// 	if (!values.phone) {
				// 		errors.phone = 'Required';
				// 	}
				// }
				// if (tabvalue === 1) {
				// 	if (!values.email2) {
				// 		errors.email2 = 'Required';
				// 	} else if (
				// 		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email2)
				// 	) {
				// 		errors.email2 = 'Invalid email address';
				// 	}
				// 	if (!values.password2) {
				// 		errors.password2 = 'Required';
				// 	}
				// 	if (!values.confirm_password2) {
				// 		errors.confirm_password2 = 'Required';
				// 	} else if (values.confirm_password2 === values.password2) {
				// 		errors.confirm_password2 = 'Required';
				// 	}
				// 	if (!values.phone2) {
				// 		errors.phone2 = 'Required';
				// 	}
				// 	if (!values.full_name) {
				// 		errors.full_name = 'Required';
				// 	}
				// }

				return errors;
			}}
			onSubmit={handleFormSubmit}>
			{({ submitForm, isSubmitting, values, errors }) => (
				<>
					<Form>
						<>
							<Field
								style={{ marginTop: 60 }}
								component={TextField}
								name='email'
								type='email'
								variant='outlined'
								label='Email'
								className={classes.selectField}
								InputProps={{
									className: classes.InputPropsStyle,
									startAdornment: (
										<InputAdornment position='start'>
											<AccountCircle />
										</InputAdornment>
									),
								}}
								inputProps={{
									style: { padding: 14, fontSize: 16 },
								}}
							/>
							{/* <TitleText two textStyle={{ marginTop: 10, fontSize: 19 }}>
									Password
								</TitleText> */}
							<Field
								style={{ marginTop: 10 }}
								component={TextField}
								name='password'
								type='password'
								variant='outlined'
								label='Password'
								className={classes.selectField}
								InputProps={{
									className: classes.InputPropsStyle,
									startAdornment: (
										<InputAdornment position='start'>
											<LockIcon />
										</InputAdornment>
									),
								}}
								inputProps={{
									style: { padding: 14, fontSize: 16 },
								}}
							/>

							<Button
								className={classes.createButton}
								// onClick={() => submitForm(values)}
								onClick={submitForm}
								style={{ marginTop: 10, fontSize: 16 }}
								// disabled={isSubmitting}
							>
								LOGIN
							</Button>
							<Box
								display={'flex'}
								justifyContent={'space-between'}
								// onClick={() => {
								// 	history.push('/resetpassword');
								// }}
								style={{ cursor: 'pointer', marginTop: 10 }}>
								<span style={{ fontSize: 12 }}>Forgot your Password?</span>
								{/* <Link to='/register'>
									<span style={{ fontSize: 12 }}>Register</span>
								</Link> */}
							</Box>
						</>
					</Form>
				</>
			)}
		</Formik>
	);
};

export default LoginForm;
