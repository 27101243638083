import React, { useEffect, useState } from 'react';
import './main-layout.scss';
import Meta from '../components/reusableComponents/Meta';
import Sidebar from '../components/sidebar/Sidebar';
import TopNav from '../components/topnav/TopNav';
import { useSelector } from 'react-redux';
import './styles.css';
import { agentbookinghistoryDispatch } from '../reducers/UserReducer';
import { useDispatch } from 'react-redux';
const MainLayout = ({ children }) => {
	const dispatch = useDispatch();
	// console.log(window.location.pathname.split('/')[1]);
	const { agentlogin } = useSelector((state) => state.user);
	const [tab, setTab] = useState(false);
	const handleTab = (a) => {
		setTab(a);
	};
	useEffect(() => {
		dispatch(
			agentbookinghistoryDispatch(agentlogin && agentlogin?.result?.result?.id)
		);
	}, []);
	return (
		<>
			{/* <Meta
				title={`Mariners Forex | Dashboard`}
				description={
					'Mariners Forex provides you online hotel bookings all over the world. Book cheap, budget and luxury hotels at best price from leading hotel booking site.'
				}
				keywords={
					'online hotel booking, book hotels online, hotel booking site, cheap hotels, budget hotels, luxury hotels, star hotels, hotel booking, hotel reservation, hotel stay, accommodations'
				}
			/> */}
			<div className={tab ? 'd-flex toggled' : 'd-flex'} id='wrapper'>
				<Sidebar />

				<div id='page-content-wrapper'>
					<TopNav handleTab={handleTab} tab={tab} />
					{children}
				</div>
			</div>
		</>
	);
};

export default MainLayout;
