import React, { useEffect, useState } from 'react';
import {
	makeStyles,
	Box,
	Grid,
	FormControlLabel,
	Radio,
	MenuItem,
	TextField as TextField2,
} from '@material-ui/core';
import { Field, Form, Formik, FieldArray } from 'formik';
import moment from 'moment';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { walletBalanceAgentDispatch } from '../reducers/UserReducer';
import {
	FlightFareQuoteEtravDispatch,
	FlightFareQuoteDispatch,
	FlightFareQuoteReturnDispatch,
	flightDebitAmount,
	FlightAddBookingDispatch,
} from '../reducers/HomeReducer';
import { useDispatch, useSelector } from 'react-redux';
import FDConfirmModal from '../components/reusableComponents/FDConfirmModal';
import { FlightFareQuoteTripJackDispatch } from '../reducers/TripjackReducer';
import {
	FDAddBookingDispatch,
	fdBookDispatch,
	fdDebitAmountDispatch,
	holdBookingDispatch,
} from '../reducers/FDReducer';
import {
	setSelectedDebit,
	setSelectedFormADDBooking,
	setSelectedformData,
	toggleFDConfirmModal,
} from '../reducers/UiReducer';
const title = ['Mr', 'Mrs', 'Ms', 'Mstr', 'Miss'];
const titleC = ['Mstr', 'Miss'];
const titleI = ['Mr', 'Ms', 'Mstr', 'Miss'];
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		// backgroundColor: 'white',
		marginBottom: 30,
		width: '100%',
		marginTop: 10,
		borderRadius: 5,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
			marginTop: 8,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
		},
		'& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
			borderRadius: 5,
		},
	},
	InputPropsStyle: {
		// backgroundColor: 'white',
		borderRadius: 15,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: 'rgb(239, 51, 57)',
		color: 'white',
		borderRadius: 12,
		width: '100%',
		padding: '11px 8px',
		// '&:hover': {
		// 	backgroundColor: 'rgb(239, 51, 57)',
		// },
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
	date: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
			width: '100%',
		},
	},
	dob: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
	passport: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
	root: {
		'& .MuiFormHelperText-root': {
			fontSize: 12,
		},
		'& .MuiOutlinedInput-input': {
			fontSize: 14,
			padding: '13px 14px',
		},
		'& MuiInputLabel-root': {
			fontSize: 16,
		},
		'& label': {
			fontSize: 15,
		},
		// '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
		// 	fontSize: 14,
		// },
	},
	root2: {
		'& .MuiOutlinedInput-input': {
			fontSize: 14,
			padding: '13px 14px',
		},
		'& MuiInputLabel-root': {
			fontSize: 16,
		},
		'& label': {
			fontSize: 15,
		},
	},
}));
const FlightSummaryFDForm = ({ time }) => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [value, setValue] = React.useState(null);
	const [offline, setOffline] = useState(false);

	const { etravflightFare, selectedOrigin, selectedDepart } = useSelector(
		(state) => state.ui
	);
	const { agentlogin, walletagent } = useSelector((state) => state.user);
	console.log('location', location);
	const dispatch = useDispatch();
	const date = new Date();
	const getActualFormat = (given: number) => {
		if (given < 10) {
			return 0 + '' + given.toString();
		} else {
			return given;
		}
	};
	const invoice = [
		date.getFullYear(),
		getActualFormat(date.getMonth() + 1),
		getActualFormat(date.getDate()),
		getActualFormat(date.getHours()),
		getActualFormat(date.getMinutes()),
		getActualFormat(date.getSeconds()),
	].join('');
	const handleHold = (e) => {
		if (e.target.value === 'offline') {
			// dispatch(setPayment(e.target.value));
			if (offline) {
				setOffline(false);
			} else {
				setOffline(true);
			}
		}

		console.log(e.target.value);
	};
	const handleFormSubmit = (data) => {
		console.log(data);
		console.log('Value', value);
		console.log('ForData', FormData);
		let formData = {
			orderId: location.state.orderID,
			paymentId: invoice,
			ticketId: location.state.flightdetail.ticket_id,
			totalPax:
				Number(location.state.body.AdultCount) +
				Number(location.state.body.ChildCount) +
				Number(location.state.body.InfantCount),
			AdultCount: location.state.body.AdultCount,
			ChildCount: location.state.body.ChildCount,
			InfantCount: location.state.body.InfantCount,
			doj: location.state.flightdetail.departure_date,
			adult_info:
				data?.adults?.length > 0
					? data.adults.map((item) => {
							return {
								title: `${item.title}.`,
								first_name: item.firstname,
								last_name: item.lastname,
							};
					  })
					: [],
			child_info:
				data?.childs?.length > 0
					? data?.childs?.map((item) => {
							return {
								title: `${item.title}.`,
								first_name: item.firstname,
								last_name: item.lastname,
							};
					  })
					: [],
			infant_info:
				data?.infants?.length > 0
					? data?.infants?.map((item) => {
							return {
								title: `${item.title}.`,
								first_name: item.firstname,
								last_name: item.lastname,
							};
					  })
					: [],
			paymentMode: 'OFFLINE',
			actualFare: location.state.flightdetail.actualFare,
			markup: location.state.flightdetail.markup,
			baseFare: location.state.flightdetail.baseFare,
			partnerMarkup: location.state.flightdetail.partnerMarkup,
			payableAmount: location.state.flightdetail.payableAmount,
			totalFare: location.state.flightdetail.totalFare,
			agentMarkup: location.state.flightdetail.agentMarkup,
			ticketCost: location.state.flightdetail.ticketCost,
			totalEarn: location.state.flightdetail.totalEarn,
			TotalInfantFare: location.state.flightdetail.TotalInfantFare,
			agent_id: agentlogin
				? agentlogin.status === 200
					? agentlogin?.result.result.id
					: 0
				: 0,
		};
		let formaddBooking = {
			orderId: location.state.orderID,
			supplier: location.state.flightdetail.Supplier,
			userEmail: data.email,
			userPhone: data.phone,
			user_type: agentlogin && agentlogin.status === 200 ? 'agent' : 'guest',
			origin:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Origin
					: location.state.flightdetail.origin,
			destination:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Destination
					: location.state.flightdetail.destination,
			travelDate:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Segments[0].Origin.DepTime.split('T')[0]
					: location.state.flightdetail.departure_date,
			returnDate: '',
			journeyType: 'OneWay',
			origin1: '',
			destination1: '',
			travelDate1: '',
			origin2: '',
			destination2: '',
			travelDate2: '',
			origin3: '',
			destination3: '',
			travelDate3: '',
			PGCharge: 0,
			class: '',
			nop: `${location.state.body.AdultCount} | ${location.state.body.ChildCount} | ${location.state.body.InfantCount} `,
			airlineType: 'Non-Refundable',
			carrier:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Segments[0].Airline.AirlineCode
					: location.state.flightdetail.airline,
			fareType: '',
			eTicketType: 'eTicket',
			actualFare:
				location.state.flightdetail.Supplier === 'MFB'
					? 0
					: location.state.flightdetail.actualFare,
			markup:
				location.state.flightdetail.Supplier === 'MFB'
					? 0
					: location.state.flightdetail.markup,
			baseFare:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Fare.Basic_Amount
					: location.state.flightdetail.baseFare,
			partnerMarkup:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Fare.partnerMarkup
					: location.state.flightdetail.partnerMarkup,
			payableAmount:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Fare.Total_Amount
					: location.state.flightdetail.payableAmount,
			totalFare:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Fare.Total_Amount
					: location.state.flightdetail.totalFare,
			agentMarkup:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Fare.agentMarkup
					: location.state.flightdetail.agentMarkup,
			ticketCost:
				location.state.flightdetail.Supplier === 'MFB'
					? location.state.flightdetail.Fare.Total_Amount
					: location.state.flightdetail.ticketCost,
			totalEarn: 0,
			cashBack: '0.00',
			couponDiscount: 0.0,
			couponDetails: '',
			pricingDetails: '',
			selectedFlight: location.state.flightdetail,
			airlineType: '',
			selectedPax: data,
			availableOnNewPrice: false,
			priceChange: 0,
			priceValue: 'NOCHANGE',
			ticketSent: 0,
			paymentMode: 'OFFLINE',
			agent_id:
				agentlogin && agentlogin.status === 200
					? agentlogin && agentlogin.result.result.id
					: 0,
		};
		let debit = {
			orderId: location.state.orderID,
			paymentId: invoice,
		};
		if (location.state.flightdetail.Supplier === 'MFB') {
			let formDataHold = {
				orderId: location.state.orderID,
				paymentId: invoice,
				FlightID: location.state.flightdetail.FlightID,
				FareCode: location.state.flightdetail.FareCode,
			};
			dispatch(setSelectedFormADDBooking(formaddBooking));
			dispatch(setSelectedformData(formDataHold));
			dispatch(setSelectedDebit(debit));

			// console.log('formaddBooking', formaddBooking);
			// dispatch(holdBookingDispatch(formDataHold));
			dispatch(FDAddBookingDispatch(formaddBooking));
		} else {
			dispatch(setSelectedFormADDBooking(formaddBooking));
			dispatch(setSelectedformData(formData));
			dispatch(setSelectedDebit(debit));
			// console.log('form', formData);
			dispatch(
				FDAddBookingDispatch(
					formaddBooking,
					debit,
					formData,
					history,
					agentlogin
				)
			);
		}
	};

	const adult = location.state.body.AdultCount;

	const child = location.state.body.ChildCount;

	const infant = location.state.body.InfantCount;

	const ar = [];
	const ar2 = [];
	const ar3 = [];
	for (let i = 1; i <= adult; i++) {
		if (
			selectedOrigin.country_code === 'IN' &&
			selectedDepart.country_code === 'IN'
		) {
			ar.push({ title: '', firstname: '', lastname: '' });
		} else {
			ar.push({
				title: '',
				firstname: '',
				lastname: '',
				passportnumber: '',
				expiry: '',
				dob: '',
			});
		}
	}
	for (let i = 1; i <= child; i++) {
		if (
			selectedOrigin.country_code === 'IN' &&
			selectedDepart.country_code === 'IN'
		) {
			ar2.push({ title: '', firstname: '', lastname: '', dob: '' });
		} else {
			ar2.push({
				title: '',
				firstname: '',
				lastname: '',
				passportnumber: '',
				expiry: '',
				dob: '',
			});
		}
	}
	for (let i = 1; i <= infant; i++) {
		if (
			selectedOrigin.country_code === 'IN' &&
			selectedDepart.country_code === 'IN'
		) {
			ar3.push({ title: '', firstname: '', lastname: '', dob: '' });
		} else {
			ar3.push({
				title: '',
				firstname: '',
				lastname: '',
				passportnumber: '',
				expiry: '',
				dob: '',
			});
		}
	}
	console.log('first', ar);

	return (
		<>
			<Formik
				initialValues={{
					phone: '',
					email: '',
					adults: ar,
					childs: ar2,
					infants: ar3,
				}}
				validationSchema={
					selectedOrigin.country_code === 'IN' &&
					selectedDepart.country_code === 'IN'
						? Yup.object({
								phone: Yup.string().required('Phone required'),
								email: Yup.string()
									.required('email required')
									.email('Enter valid email'),
								adults: Yup.array().of(
									Yup.object().shape({
										title: Yup.string().required('title required'),
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
									})
								),
								childs: Yup.array().of(
									Yup.object().shape({
										title: Yup.string().required('title required'),
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										dob: Yup.string().required('dob required'),
									})
								),
								infants: Yup.array().of(
									Yup.object().shape({
										title: Yup.string().required('title required'),
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										dob: Yup.string().required('dob required'),
									})
								),
						  })
						: Yup.object({
								phone: Yup.string().required('Phone required'),
								email: Yup.string()
									.required('email required')
									.email('Enter valid email'),
								adults: Yup.array().of(
									Yup.object().shape({
										title: Yup.string().required('title required'),
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										passportnumber: Yup.string().required(
											'passportnumber required'
										),
										expiry: Yup.string().required('expiry required'),
										dob: Yup.string()
											.required('dob required')
											.test(
												'DOB',
												'Please choose a valid date of birth',
												(date) => moment().diff(moment(date), 'years') >= 18
											),
									})
								),
								childs: Yup.array().of(
									Yup.object().shape({
										title: Yup.string().required('title required'),
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										passportnumber: Yup.string().required(
											'passportnumber required'
										),
										expiry: Yup.string().required('expiry required'),
										// .min(new Date(), "Please choose a valid expiry date"),
										dob: Yup.string().required('dob required'),
									})
								),
								infants: Yup.array().of(
									Yup.object().shape({
										title: Yup.string().required('title required'),
										firstname: Yup.string().required('firstname required'),
										lastname: Yup.string().required('lastname required'),
										passportnumber: Yup.string().required(
											'passportnumber required'
										),
										expiry: Yup.string().required('expiry required'),
										dob: Yup.string().required('dob required'),
									})
								),
						  })
				}
				onSubmit={handleFormSubmit}>
				{(formik) => (
					<Form>
						<Box
							className={classes.root}
							style={{
								background: 'white',
								padding: '10px 15px',
								border: '1px solid #e6e6e6',
								marginTop: '10px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ marginTop: 20, alignItems: 'baseline' }}>
								<Grid item xs={12} lg={6}>
									<Field
										component={TextField}
										name='email'
										type='email'
										variant='outlined'
										// error={errors.email}
										label='Email ID'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											style: { padding: 12 },
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Field
										component={TextField}
										name='phone'
										type='number'
										variant='outlined'
										// error={errors.phone}
										label='Mobile No.'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											style: { padding: 12 },
										}}
									/>
								</Grid>
							</Grid>
						</Box>
						<FieldArray
							name='adult'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values.adults.map((adult, index) => (
											<>
												<Box
													className={classes.root}
													style={{
														background: 'white',
														padding: '10px 15px',
														border: '1px solid #e6e6e6',
														marginTop: '10px',
														boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
														borderRadius: '5px',
													}}>
													<div>{`Enter the Detail of Adult.${index + 1}`}</div>

													<Grid
														container
														spacing={2}
														alignItems='center'
														style={{ marginTop: 20, alignItems: 'baseline' }}>
														<Grid
															item
															xs={12}
															sm={6}
															lg={4}
															className={classes.root2}
															style={{ alignItems: 'center' }}>
															<Field
																component={TextField}
																select
																name={`adults.${index}.title`}
																type='text'
																variant='outlined'
																label='Select Title'
																// error={errors.titleA1}
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	className: classes.inputPropsStyle,
																}}>
																{title.map((item, index) => (
																	<MenuItem key={index} value={item}>
																		{item}
																	</MenuItem>
																))}
															</Field>
														</Grid>
														<Grid item xs={12} sm={6} lg={4}>
															<Field
																component={TextField}
																name={`adults.${index}.firstname`}
																type='text'
																// error={errors.first_nameA1}
																variant='outlined'
																label='Enter First Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={4}>
															<Field
																component={TextField}
																name={`adults.${index}.lastname`}
																type='text'
																// error={errors.last_nameA1}
																variant='outlined'
																label='Enter Last Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														{selectedOrigin.country_code === 'IN' &&
														selectedDepart.country_code === 'IN' ? (
															''
														) : (
															<>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`adults.${index}.passportnumber`}
																		type='text'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Enter Passport Number'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`adults.${index}.expiry`}
																		type='date'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Select Expiry Date'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`adults.${index}.dob`}
																		type='date'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Select DOB'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
															</>
														)}
													</Grid>
												</Box>
											</>
										))}
									</div>
								);
							}}
						/>
						<FieldArray
							name='child'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values.childs.map((child, index) => (
											<>
												<Box
													className={classes.root}
													style={{
														background: 'white',
														padding: '10px 15px',
														border: '1px solid #e6e6e6',
														marginTop: '10px',
														boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
														borderRadius: '5px',
													}}>
													<div>{`Enter the Detail of Child.${index + 1}`}</div>
													{/* <Field
														disabled
														type='text'
														name={`chil.${index}.firsName`}
														value={chil.firsName}
													/>
													<Field type='date' name={`chil.${index}.date`} /> */}
													<Grid
														container
														spacing={2}
														alignItems='center'
														style={{ marginTop: 20, alignItems: 'baseline' }}>
														<Grid
															item
															xs={12}
															sm={6}
															lg={3}
															className={classes.root2}
															style={{ alignItems: 'center' }}>
															<Field
																component={TextField}
																select
																name={`childs.${index}.title`}
																type='text'
																variant='outlined'
																label='Select Title'
																// error={errors.titleC1}
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	className: classes.inputPropsStyle,
																}}>
																{titleC.map((item, index) => (
																	<MenuItem key={index} value={item}>
																		{item}
																	</MenuItem>
																))}
															</Field>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`childs.${index}.firstname`}
																type='text'
																variant='outlined'
																// error={errors.first_nameC1}
																label='Enter First Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`childs.${index}.lastname`}
																type='text'
																variant='outlined'
																// error={errors.last_nameC1}
																label='Enter Last Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`childs.${index}.dob`}
																type='date'
																variant='outlined'
																// error={errors.last_nameC1}
																label='Select DOB'
																className={classes.selectField}
																// style={{color:'lightgrey'}}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														{selectedOrigin.country_code === 'IN' &&
														selectedDepart.country_code === 'IN' ? (
															''
														) : (
															<>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`childs.${index}.passportnumber`}
																		type='text'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Enter Passport Number'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`childs.${index}.expiry`}
																		type='date'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Select Expiry Date'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
															</>
														)}
													</Grid>
												</Box>
											</>
										))}
									</div>
								);
							}}
						/>
						<FieldArray
							name='infant'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values.infants.map((infant, index) => (
											<>
												<Box
													className={classes.root}
													style={{
														background: 'white',
														padding: '10px 15px',
														border: '1px solid #e6e6e6',
														marginTop: '10px',
														boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
														borderRadius: '5px',
													}}>
													<div>{`Enter the Detail of Infant.${index + 1}`}</div>
													{/* <Field
														disabled
														type='text'
														name={`chil.${index}.firsName`}
														value={chil.firsName}
													/>
													<Field type='date' name={`chil.${index}.date`} /> */}
													<Grid
														container
														spacing={2}
														alignItems='center'
														style={{ marginTop: 20, alignItems: 'baseline' }}>
														<Grid
															item
															xs={12}
															sm={6}
															lg={3}
															className={classes.root2}
															style={{ alignItems: 'center' }}>
															<Field
																component={TextField}
																select
																name={`infants.${index}.title`}
																type='text'
																variant='outlined'
																label='Select Title'
																// error={errors.titleC1}
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	className: classes.inputPropsStyle,
																}}>
																{titleI.map((item, index) => (
																	<MenuItem key={index} value={item}>
																		{item}
																	</MenuItem>
																))}
															</Field>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`infants.${index}.firstname`}
																type='text'
																variant='outlined'
																// error={errors.first_nameC1}
																label='Enter First Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`infants.${index}.lastname`}
																type='text'
																variant='outlined'
																// error={errors.last_nameC1}
																label='Enter Last Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`infants.${index}.dob`}
																type='date'
																variant='outlined'
																// placeholder="Select DOB"
																// error={errors.last_nameC1}
																label='Select DOB'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														{selectedOrigin.country_code === 'IN' &&
														selectedDepart.country_code === 'IN' ? (
															''
														) : (
															<>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`infants.${index}.passportnumber`}
																		type='text'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Enter Passport Number'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`infants.${index}.expiry`}
																		type='date'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		label='Select Expiry Date'
																		// value=''
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
															</>
														)}
													</Grid>
												</Box>
											</>
										))}
									</div>
								);
							}}
						/>
						<Box
							alignItems='center'
							display='flex'
							style={{
								background: 'white',
								padding: '10px 15px',
								border: '1px solid #e6e6e6',
								marginTop: '10px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
							{agentlogin &&
							agentlogin.result &&
							agentlogin.result.result &&
							agentlogin.result.result.role === 'Agent' ? (
								// <></>

								<>
									{' '}
									<FormControlLabel
										style={{ marginRight: 0 }}
										value='offline'
										control={
											<Radio
												checked={offline}
												onClick={handleHold}
												disabled={
													Number(
														Math.round(
															location.state.flightdetail.Supplier === 'MFB'
																? location.state.flightdetail.Fare.Total_Amount
																: location.state.flightdetail.totalFare
														)
													) <= Number(walletagent.result.balanceAmount)
														? false
														: true
												}
											/>
										}
									/>
									<span
										style={{
											opacity:
												Number(
													Math.round(
														location.state.flightdetail.Supplier === 'MFB'
															? location.state.flightdetail.Fare.Total_Amount
															: location.state.flightdetail.totalFare
													)
												) <= Number(walletagent.result.balanceAmount)
													? ''
													: 0.3,
										}}>
										Offline Amount
									</span>
									<span
										style={{
											marginLeft: 5,
											color: 'blue',
											opacity:
												Number(
													Math.round(
														location.state.flightdetail.Supplier === 'MFB'
															? location.state.flightdetail.Fare.Total_Amount
															: location.state.flightdetail.totalFare
													)
												) <= Number(walletagent.result.balanceAmount)
													? ''
													: 0.3,
										}}>
										<span style={{ marginRight: 5, marginLeft: 5 }}>
											{'₹'}
											{Number(
												Math.round(
													location.state.flightdetail.Supplier === 'MFB'
														? location.state.flightdetail.Fare.Total_Amount
														: location.state.flightdetail.totalFare
												)
											)}
										</span>
									</span>
								</>
							) : (
								''
							)}
						</Box>
						<Grid
							container
							spacing={4}
							alignItems='center'
							style={{ marginBottom: 20 }}>
							<Grid item xs={3}></Grid>
							<Grid item xs={12} lg={4}>
								<button
									type='submit'
									className={classes.createButton}
									disabled={!offline}
									style={{
										marginTop: 10,
										fontSize: 16,
										cursor: 'pointer',
										backgroundColor: offline ? 'rgb(239, 51, 57)' : 'grey',
										color: 'white',
										opacity: offline ? '' : 0.3,
									}}>
									Procced to Payment
								</button>
							</Grid>
							<Grid item xs={4}></Grid>
						</Grid>
					</Form>
				)}
			</Formik>
			<FDConfirmModal />
		</>
	);
};

export default FlightSummaryFDForm;
