import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import TitleText from './TitleText';
import BodyText from './BodyText';
import loaders from '../../assets/images/loaders.gif';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const SkeletonOneway = () => {
	return (
		<>
			<Box
				display='flex'
				style={{
					boxShadow: '3px 3px 16px -4px rgb(0 0 0 / 30%)',
					padding: 8,
					background: 'white',
				}}
				// justifyContent='space-between'
				mt={'20px'}
				// alignItems='center'
			>
				<Hidden only={['xs']}>
					<Box display='flex' alignItems='center'>
						{/* <Skeleton variant='circle' width={40} height={40} />

					<Box alignItems='center' ml={'5px'}>
							<Skeleton variant='text' width='60px' />

						<Skeleton variant='text' width='60px' />
					</Box> */}
						<img src={loaders} style={{ height: '135px' }} />
					</Box>
				</Hidden>
				<Box style={{ width: '100%' }}>
					<Skeleton variant='text' width='100%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='80%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='60%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='40%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='20%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='10%' style={{ borderRadius: 10 }} />
					{/* <Skeleton variant='text' width='5%'  /> */}

					{/* <Skeleton variant='text' width='60px' /> */}
				</Box>
				{/* <Hidden only={['xs']}>
					<Box alignItems='center' textAlign='center'>
						<Skeleton variant='text' width='100px' />

						<Skeleton variant='text' width='60px' />
					</Box>
					<Box>
						<Skeleton variant='text' width='60px' />

						<Skeleton variant='text' width='60px' />
					</Box>
				</Hidden>
				<Hidden only={['xs']}>
				<Box alignItems='center' display='flex'>
					<Skeleton variant='text' width='60px' height='30px' />
					<Box style={{ marginLeft: 10 }}></Box>
					<Skeleton variant='text' width='60px' height='60px' />
					<Skeleton variant='text' width='60px' height='60px' />
				</Box>
				</Hidden> */}
			</Box>
		</>
	);
};

export default SkeletonOneway;
