import React from 'react';
import './AgentInvoice.css';
const AgentInvoice = () => {
	return (
		<div style={{ width: 1100, borderRadius: 0 }} className='main-wrapper'>
			<div className='maind clearfix'>
				<div
					className='main1 clearfix'
					style={{
						backgroundColor: '#f9fafb',
						padding: '10px',
						borderBottom: '2px solid black',
					}}>
					<div style={{ width: '33%' }} className='box1'>
						<h2>Invoice-EWEB/2223/633463</h2>
					</div>
					<div style={{ width: '33%' }} className='box2'>
						<h2>Invoice Date : 07-05-2022 </h2>
					</div>
					<div style={{ width: '33%' }} className='box3'>
						<h2>PNR : XDS3PB</h2>
					</div>
				</div>
				<div className='grid-02'>
					<p
						style={{
							fontSize: '15px',
							textAlign: 'left',
							color: '#000',
							fontWeight: 'bold',
						}}>
						EWEBBAZAR PVT LTD
					</p>
					<p style={{ fontSize: '15px', textAlign: 'left' }}>
						H14, Moti Nagar, Karmik Nagar,<br></br>Moti Nagar, Dhanbad,
						Jharkhand, India , Pin-826004
					</p>
					<p style={{ fontSize: '15px', textAlign: 'left' }}>
						<b>Email</b>: rahularyaeweb@gmail.com
					</p>
					<p style={{ fontSize: '15px', textAlign: 'left' }}>
						<b>Website</b>: www.b2bmusafirbazar.com
					</p>
					<p style={{ fontSize: '15px', textAlign: 'left' }}>
						<b>Phone</b>: 8674955550
					</p>
					<h4
						style={{
							fontSize: '13px',
							textAlign: 'left',
							fontWeight: 'bold',
						}}>
						CI Number :
					</h4>
					<h4
						style={{
							fontSize: '13px',
							textAlign: 'left',
							fontWeight: 'bold',
						}}>
						PAN :
					</h4>
					<h4
						style={{
							fontSize: '13px',
							textAlign: 'left',
							fontWeight: 'bold',
						}}>
						GST State : Jharkhand
					</h4>
					<h4
						style={{
							fontSize: '13px',
							textAlign: 'left',
							fontWeight: 'bold',
						}}>
						GSTIN : 20AAECE1717F2ZB
					</h4>
					<h4
						style={{
							fontSize: '13px',
							textAlign: 'left',
							fontWeight: 'bold',
						}}>
						Travel Date : 11/10/2020
					</h4>
				</div>
				<div className='grid-02' style={{ paddingLeft: 174 }}>
					<h4>INVOICE</h4>
				</div>
				<div className='grid-03'>
					<p style={{ fontSize: '17px', textAlign: 'left' }}>
						<b>Prerna Travels</b>
					</p>
					<p style={{ fontSize: '17px', textAlign: 'left' }}>
						<b>dhanbad</b>
					</p>
					<p style={{ fontSize: '17px', textAlign: 'left' }}>
						<b>PHONE : 9798637079 </b>
					</p>
					<br></br>
					<h4
						style={{
							fontSize: '13px',
							textAlign: 'left',
							fontWeight: 'bold',
						}}>
						PAN : sa565
					</h4>
					<h4
						style={{
							fontSize: '13px',
							textAlign: 'left',
							fontWeight: 'bold',
						}}>
						GST State : Jharkhand
					</h4>
					<h4
						style={{
							fontSize: '13px',
							textAlign: 'left',
							fontWeight: 'bold',
						}}>
						GSTIN :
					</h4>
				</div>
			</div>
			<div className='main1 clearfix'></div>
			<table
				className='table'
				style={{
					fontSize: '13px',
					maxWidth: '100%',
					backgroundColor: 'transparent',
					borderCollapse: 'collapse',
					borderSpacing: 0,
				}}>
				<thead>
					<tr>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>SNo.</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>
							Ticket Number
						</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}> Sector</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>Flight</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>Pax Name</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>Type</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>Class</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>Fare</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>OT Tax</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>K3/GST</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>YQ Tax</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>
							Baggage Ch.
						</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>Meal Ch.</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>Seat Ch.</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>
							SpecialService Ch.
						</th>
						<th style={{ padding: '8px', lineHeight: 1.42857143 }}>
							Service Ch.
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>1</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>XDS3PB</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>
							Hyderabad-Ranchi
						</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>6E-6242</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>
							Ms KAJAL MEHTA
						</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>Adult</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>SM</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>INR 6000</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>0</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>0</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>0</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>0</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>0</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>0</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>0</td>
						<td style={{ padding: '8px', lineHeight: 1.42857143 }}>0</td>
					</tr>
				</tbody>
			</table>
			<br></br>
			<div className='main1 clearfix'>
				<div
					className='main5 clearfix'
					style={{
						width: '450px',
						float: 'right',
						borderTop: '0px solid #000',
						marginBottom: '50px',
					}}>
					<div className='grid-box clearfix'>
						<ul>
							<li
								style={{ fontWeight: 'bold', fontSize: '18px' }}
								className='cal-02 bod'>
								Gross Amount
							</li>
							<li
								className='cal-02'
								style={{
									float: 'right',
									fontWeight: 'bold',
									fontSize: '18px',
								}}>
								INR 6000
							</li>
						</ul>
					</div>
					<div className='grid-box clearfix'>
						<ul>
							<li>
								Less <i> Commission Earned: </i>
								<i style={{ paddingLeft: 196 }}> 0.00 </i>
								<br></br>
								Add<i> Commission Earned: </i>
								<i style={{ paddingLeft: 196 }}> 0.00 </i>
								<br></br>Add<i> Commission Earned: </i>
								<i style={{ paddingLeft: 196 }}> 0.00 </i>
								<br></br>Add<i> Commission Earned: </i>
								<i style={{ paddingLeft: 196 }}> 0.00 </i>
								<br></br>Add<i> Commission Earned: </i>
								<i style={{ paddingLeft: 196 }}> 0.00 </i>
								<br></br>Add<i> Commission Earned: </i>
								<i style={{ paddingLeft: 196 }}> 0.00 </i>
								<br></br>
							</li>
						</ul>
					</div>
					<div className='grid-box clearfix'>
						<ul>
							<li
								className='cal-02 bod'
								style={{ fontWeight: 'bold', fontSize: '15px' }}>
								Net Amount (Amount in Rs)
							</li>
							<li
								className='cal-02 bod'
								style={{
									float: 'right',
									fontWeight: 'bold',
									fontSize: '15px',
								}}>
								INR 6000
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='main4 clearfix'>
				<h5>GST Details</h5>
				<table className='table' style={{ fontSize: '13px' }}>
					<thead>
						<tr
							style={{
								backgroundColor: '#2c4269',
								color: 'white',
								textAlign: 'left',
							}}>
							<th>Service Description</th>
							<th>SAC</th>
							<th>Taxable Value</th>
							<th>CGST@0.00%</th>
							<th>SGST@0.00%</th>
							<th>IGST@18.00%</th>
							<th>Total GST</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Transaction Fees</td>
							<td>4567</td>
							<td>0</td>
							<td>0</td>
							<td>0</td>
							<td>0</td>
							<td>0</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className='main3 clearfix'>
				<h4 style={{ fontSize: '17px', textAlign: 'left', fontWeight: 'bold' }}>
					Billed by : Prerna Travels
				</h4>
				<h4 style={{ fontSize: '17px', textAlign: 'left', fontWeight: 'bold' }}>
					Ticketed by : Prerna Verma
				</h4>
				<h4 style={{ fontSize: '17px', textAlign: 'left', fontWeight: 'bold' }}>
					Invoice Status : Paid
				</h4>
				<h4 style={{ fontSize: '17px', textAlign: 'left', fontWeight: 'bold' }}>
					Agent Remarks :
				</h4>
			</div>
			<div className='main3 clearfix'>
				<h2>Terms &amp; Conditions:</h2>
				<ul>
					<li>This is computer generated invoice signature not required.</li>
					<li>All Cases Disputes are subject to New Delhi Jurisdiction.</li>
					<li>Refunds Cancellations are subject to Airline Approval.</li>
					<li>
						Kindely check all details carefully to avoid unnecessary
						complications.
					</li>
					<li>CHEQUE: must be drawn in favour of Prerna Travels.</li>
					<li>
						LATE PAYMENT: Intrest @24% per annum will be charged on all
						outstanding bills after due date.
					</li>
				</ul>
			</div>
			<div className='main4 clearfix'>
				<ul>
					<li>
						<a>
							<button
								name='print'
								type='submit'
								onClick={() => window.print()}
								style={{
									background: 'blue',
									color: 'white',
									padding: '8px',
									cursor: 'pointer',
									paddingLeft: 20,
									paddingRight: 20,
								}}>
								Print
							</button>
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default AgentInvoice;
