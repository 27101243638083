import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Button,
	Box,
	InputAdornment,
	Tabs,
	Tab,
	withStyles,
	FormControlLabel,
	Checkbox,
	Divider,
	Grid,
	MenuItem,
	TextField as TextField2,
	Popper,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import flt from '../../assets/images/flt.png';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import BodyText from './BodyText';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
// import { loginDispatch } from '../reducers/UsersReducer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Autocomplete from '@mui/material/Autocomplete';
import {
	agentchangePasswordDispatch,
	supplieraddSectorDispatch,
	travellerchangePasswordDispatch,
} from '../../reducers/UserReducer';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import { setSelectedDepart, setSelectedOrigin } from '../../reducers/UiReducer';
import { fetchCityDispatch } from '../../reducers/HomeReducer';
// import { useDispatch } from 'react-redux';
const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormControl-root': {
			'& div': {
				'& div': {
					display: 'none',
				},
			},
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& input': {
			fontSize: '.8em',
			fontWeight: 600,
			padding: '0px 4px 4px 0px',
			[theme.breakpoints.down('xs')]: {
				fontSize: '12px',
			},
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-xzqck1-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
	},
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				// border: '1px solid rgba(0, 50, 91, 0.5)',
			},
		},
		'& .MuiFormHelperText-root': {
			backgroundColor: '#F6F8FB',
			margin: 0,
			paddingTop: 10,
		},
		'& .MuiSelect-icon': {
			color: '#E61855',
		},
		'& div': {
			fontSize: 18,
		},
		'& input': {
			padding: '10px',
			color: 'black',
			// border: '1px solid',
		},
		'& .MuiSelect-select': {
			padding: '12.5px 14px',
		},

		backgroundColor: 'white',
		// marginBottom: 20,
		width: '100%',
		marginTop: 10,
		borderRadius: 5,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
			marginTop: 8,
		},
	},
	InputPropsStyle: {
		backgroundColor: '#F6F8FB',
		borderRadius: 5,
		fontSize: 18,
	},
	inputPropsStyle: {
		// padding: 14,
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#F6F8FB',
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 10,
		width: '100%',
		padding: 14,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
	popper: {
		'& .MuiAutocomplete-listbox': {
			'& :hover': {
				color: 'rgb(239, 51, 57)',
				// '& img': {
				// 	background: 'rgb(239, 51, 57)',
				// },
			},
		},
	},
}));

const country = ['Jharkhand', 'Kolkata'];

const AddFlight = () => {
	const classes = useStyles();
	// const theme = useTheme();
	const [result, setResult] = useState([]);

	const [tabvalue, setValue] = React.useState(0);
	const { city } = useSelector((state) => state.home);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [check, setCheck] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const handleCheck = (event) => {
		setCheck(event.target.checked);
	};
	const handleSearchOrigin = (event) => {
		let value = event.target.value.toLowerCase();
		let cityData = {
			keywords: value,
			limits: 10,
		};
		// dispatch(fetchCityDispatch(cityData, setResult));
		dispatch(fetchCityDispatch(cityData, setResult));
	};
	const { origin, depart, OriginO, selectedOrigin, selectedDepart, fddate } =
		useSelector((state) => state.ui);
	const { travellerlogin, agentlogin, supplierlogin } = useSelector(
		(state) => state.user
	);
	const [currentStep, SetCurrentStep] = useState('Step 1');

	const handleFormSubmit = (data, actions) => {
		let formData = {
			supplierId: `S-${supplierlogin && supplierlogin.result.result.id}`,
			origin: selectedOrigin.airport_code,
			destination: selectedDepart.airport_code,
			departureDate: data.departureDate,
			departureTime: data.departureTime,
			arrivalTime: data.arrivalTime,
			seat: data.seat,
			airlineName: data.airlineName,
			airlineCode: data.airlineCode,
			airlineNumber: data.airlineNumber,
			fare: data.fare,
			code: 'MBB748',
		};
		console.log('data>>', formData);
		dispatch(supplieraddSectorDispatch(formData, history));

		// dispatch(loginDispatch(formData, actions, history, checked));
	};
	const PopperMy = function (props) {
		return (
			<Popper
				{...props}
				style={styles.popper}
				className={classes.popper}
				placement='bottom-start'
			/>
		);
	};

	const styles = (theme) => ({
		popper: {
			width: 'fit-content',
		},
	});
	return (
		<>
			<Formik
				initialValues={{}}
				validate={(values) => {
					const errors = {};

					// if (!values.currentpassword) {
					// 	errors.currentpassword = 'Required';
					// }
					// if (!values.newpassword) {
					// 	errors.newpassword = 'Required';
					// }
					// if (!values.confirmnewpassword) {
					// 	errors.confirmnewpassword = 'Required';
					// }

					return errors;
				}}
				onSubmit={handleFormSubmit}>
				{({ submitForm, isSubmitting, values, errors }) => (
					<>
						{' '}
						<Box>
							{/* <h2 style={{ fontFamily: 'Josefin Sans, sans-serif' }}>
								Change Password
							</h2> */}
						</Box>
						<Form>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ padding: 50 }}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<span
										style={{
											color: 'black',
											fontSize: '16',
										}}>
										Origin
									</span>
									<div
										className={classes.root}
										style={{ width: '100%', marginTop: 10 }}>
										{/* <span
											style={{
												color: 'black',
												fontSize: '16',
											}}>
											Origin
										</span> */}
										<Autocomplete
											id='country-select-demo'
											sx={{
												// background: "#eee",
												border: '1px solid lightgrey',
												borderRadius: '4px',
												padding: '7px',
												transition: 'none',
											}}
											// clearOnBlur={true}
											value={selectedOrigin}
											PopperComponent={PopperMy}
											options={
												city && city.result.length > 0 ? city.result : []
											}
											filterOptions={(options, state) => options}
											autoHighlight
											getOptionLabel={(option) =>
												`${option.city_name}, ${option.country_name} `
											}
											renderOption={(props, option) => (
												<Box
													// mr={'5px'}
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
													}}
													component='li'
													sx={{ '& > img': { mr: 2 } }}
													{...props}>
													<Box display='flex'>
														<img loading='lazy' width='20' src={flt} alt='' />
														<p style={{ fontSize: 16 }}>
															{option.city_name} ({option.airport_code}){' '}
															{option.airport_name}
														</p>
													</Box>
													<img
														style={{ marginLeft: 10 }}
														loading='lazy'
														width='30'
														src={`https://countryflagsapi.com/svg/${option.country_code}`}
														alt=''
													/>
												</Box>
											)}
											onChange={(event, value) => {
												// setFieldValue('Origin', value.airport_code);
												dispatch(setSelectedOrigin(value));
												// dispatch(setSelectedOrigin(value));
												// setFrom(value);
											}}
											renderInput={(params) => (
												<TextField2
													{...params}
													onChange={handleSearchOrigin}
													name='origin'
													style={{ padding: 4 }}
												/>
											)}
										/>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<span
										style={{
											color: 'black',
											fontSize: '16',
										}}>
										Depart
									</span>
									<div
										className={classes.root}
										style={{ width: '100%', marginTop: 10 }}>
										{/* <h6>Departure </h6> */}
										<Autocomplete
											id='country-select-demo'
											sx={{
												// background: "#eee",
												border: '1px solid lightgrey',
												borderRadius: '4px',
												padding: '7px',
												transition: 'none',
											}}
											// clearOnBlur={true}
											value={selectedDepart}
											PopperComponent={PopperMy}
											options={
												city && city.result.length > 0 ? city.result : []
											}
											filterOptions={(options, state) => options}
											autoHighlight
											getOptionLabel={(option) =>
												`${option.city_name}, ${option.country_name} `
											}
											renderOption={(props, option) => (
												<Box
													// mr={'5px'}
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
													}}
													component='li'
													sx={{ '& > img': { mr: 2 } }}
													{...props}>
													<Box display='flex'>
														<img loading='lazy' width='20' src={flt} alt='' />
														<p style={{ fontSize: 16 }}>
															{option.city_name} ({option.airport_code}){' '}
															{option.airport_name}
														</p>
													</Box>
													<img
														style={{ marginLeft: 10 }}
														loading='lazy'
														width='30'
														src={`https://countryflagsapi.com/svg/${option.country_code}`}
														alt=''
													/>
												</Box>
											)}
											onChange={(event, value) => {
												dispatch(setSelectedDepart(value));
												// dispatch(setSelectedOrigin(value));
												// dispatch(setSelectedOrigin(value));
												// setFrom(value);
											}}
											renderInput={(params) => (
												<TextField2
													{...params}
													onChange={handleSearchOrigin}
													name='origin'
													style={{ padding: 4 }}
												/>
											)}
										/>
									</div>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={4}
									lg={4}
									style={{ marginTop: 30 }}>
									<Field
										component={TextField}
										name='departureDate'
										type='date'
										id='outlined-basic'
										label='Departure Date'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									lg={3}
									style={{ marginTop: 20 }}>
									<Field
										component={TextField}
										name='departureTime'
										type='time'
										id='outlined-basic'
										label='Departure Time'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									lg={3}
									style={{ marginTop: 20 }}>
									<Field
										component={TextField}
										name='arrivalTime'
										type='time'
										id='outlined-basic'
										label='Arrival Time'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									lg={3}
									style={{ marginTop: 20 }}>
									<Field
										component={TextField}
										name='seat'
										type='number'
										id='outlined-basic'
										label='Seat'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									lg={3}
									style={{ marginTop: 20 }}>
									<Field
										component={TextField}
										name='airlineName'
										type='text'
										id='outlined-basic'
										label='Airline Name'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									lg={3}
									style={{ marginTop: 20 }}>
									<Field
										component={TextField}
										name='airlineCode'
										type='text'
										id='outlined-basic'
										label='Airline Code'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									lg={3}
									style={{ marginTop: 20 }}>
									<Field
										component={TextField}
										name='airlineNumber'
										type='text'
										id='outlined-basic'
										label='Airline Number'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									lg={3}
									style={{ marginTop: 20 }}>
									<Field
										component={TextField}
										name='fare'
										type='text'
										id='outlined-basic'
										label='Fare'
										variant='outlined'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											className: classes.inputPropsStyle,
										}}
									/>
								</Grid>
							</Grid>

							<Grid container style={{ marginTop: 20, padding: 50 }}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<Button
										className={classes.createButton}
										onClick={submitForm}
										style={{ marginTop: 5, fontSize: 14 }}
										// disabled={isSubmitting}
									>
										ADD
									</Button>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default AddFlight;
