import React from 'react'
import { useSelector } from 'react-redux'
import HeaderSection from '../../components/reusableComponents/HeaderSection'
import Footer from '../Desktop/Footer'
const ErrorPage = () => {
  const {flightbook} = useSelector((state)=>state.tripjack)
  return (
    <>
    <HeaderSection/>
    <div style={{marginTop:146,textAlign:'center'}}>
      <h3>{flightbook?.message}</h3>
    </div>
    <Footer/>
    </>
  )
}

export default ErrorPage