import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import logo from '../../assets/images/bluelogo.png';
import RegisterForm from '../../form/RegisterForm';
// import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
	// const navigate = useNavigate();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div
			style={{
				background: 'linear-gradient(to top right, #b1824e, #241842',
			}}>
			<Box style={{ paddingTop: 100 }}>
				<Grid container>
					<Grid item xs={1} lg={4}></Grid>
					<Grid item xs={10} lg={4}>
						<Box
							style={{
								background: '#fff',
								borderRadius: 10,
							}}>
							<Box
								display={'flex'}
								justifyContent={'center'}
								// onClick={() => navigate('/')}
							>
								<img
									src={logo}
									style={{ height: '100px', marginTop: 20, cursor: 'pointer' }}
								/>
							</Box>
							<RegisterForm />
						</Box>
						<Box></Box>
					</Grid>
					<Grid item xs={1} lg={4}></Grid>
				</Grid>
			</Box>
		</div>
	);
};

export default RegisterPage;
