import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { makeStyles, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setFDdate, setOrigin, setOriginO } from '../../reducers/UiReducer';
import { useParams, useLocation } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		'& label': {
			display: 'none',
		},
		backgroundColor: 'white',
		width: '100%',
		// borderRadius: 5,
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			fontSize: 12,
			padding: '10px',
			fontWeight: 600,
		},
		'& .MuiInputAdornment-root': {
			marginRight: 14,
		},
		// '& .MuiSvgIcon-root': {
		// 	width: '2em',
		// 	height: '5em',
		// },
		'& .MuiInput-root': {
			marginTop: 0,
		},
	},
	InputPropsStyle: {
		backgroundColor: 'white',
		borderRadius: 25,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: '#39c9bb',
		color: 'white',
		borderRadius: 25,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: '#39c9bb',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
	auto: {
		'& input': {
			padding: '20px 18px',
		},
		'& div': {
			'& div': {
				padding: 3,
			},
		},
		'& .css-xrz6uu-MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
			padding: '11px 4px 4px 0px',
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiFormLabel-root': {
			marginLeft: 5,
			fontSize: 13,
		},
		'& .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator':
			{
				marginTop: -10,
			},
	},
	root: {
		'& .InputFromTo': {
			'& .DayPickerInput input': {
				padding: '20px 18px',
			},
		},
	},
	j: {
		'& .MuiBox-root': {
			display: 'flex',
			alignContent: 'stretch',
			flexWrap: 'wrap',
		},
	},
	down: {
		marginTop: '385px',
		minWidth: 300,
		padding: 10,
		background: 'white',
		position: 'absolute',
		boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
		transformOrigin: 'top',
		transition: 'all .3s ease',
		'@media(min-device-width: 500px) and (max-device-width: 1120px)': {
			marginLeft: 137,
		},
	},
}));
export default function ModifyDateFd() {
	const { origindate } = useParams();
	const location = useLocation();
	const [isOpen, setIsOpen] = React.useState(false);
	const [value, setValue] = React.useState(
		location.state.body2
			? location.state.body2.Segments[0].PreferredDepartureTime
			: location.state.returnBody
			? location.state.returnBody.Segments[0].PreferredDepartureTime
			: ''
	);
	const classes = useStyles();
	const dispatch = useDispatch();
	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('/');
	};
	dispatch(setOrigin(convert(value)));
	dispatch(setOriginO(value));
	console.log(location);
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DesktopDatePicker
				label='From'
				disablePast
				value={value}
				// minDate={new Date('2017-01-01')}
				inputFormat='dd/MM/yyyy'
				onChange={(newValue) => {
					setValue(newValue);
					// dispatch(setFDdate(convert(newValue)));
					dispatch(setFDdate(convert(newValue)));
				}}
				open={isOpen}
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				renderInput={(params) => (
					<TextField
						{...params}
						className={classes.selectField}
						onClick={(e) => setIsOpen(true)}
					/>
				)}
			/>
		</LocalizationProvider>
	);
}
