import React from 'react';
import {
	Container,
	Box,
	Grid,
	Paper,
	Divider,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	Avatar,
	withStyles,
	TableCell,
} from '@material-ui/core';
import TitleText from './TitleText';
import BodyText from './BodyText';
const Overview = () => {
	return (
		<>
			<Box>
				<TitleText four>Personal Infomation</TitleText>
			</Box>
			<Grid container style={{ marginTop: 20 }}>
				<Grid item xs={4}>
					<Box>
						<TitleText two>Full Name</TitleText>
						<BodyText>RIYA VERMA</BodyText>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box>
						<TitleText two>City</TitleText>
						<BodyText>Dhanbad</BodyText>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box>
						<TitleText two>Company Name</TitleText>
						<BodyText>MUSAFIR BAZAR.COM</BodyText>
					</Box>
				</Grid>
			</Grid>
			<Grid container style={{ marginTop: 20 }}>
				<Grid item xs={4}>
					<Box>
						<TitleText two>E-mail</TitleText>
						<BodyText>B2B@MUSAFIRBAZAR.COM</BodyText>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box>
						<TitleText two>State/Province/Region</TitleText>
						<BodyText>Jharkhand</BodyText>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box>
						<TitleText two>Pancard Number</TitleText>
						<BodyText>123456</BodyText>
					</Box>
				</Grid>
			</Grid>
			<Grid container style={{ marginTop: 20 }}>
				<Grid item xs={4}>
					<Box>
						<TitleText two>Phone Number</TitleText>
						<BodyText>8877909555</BodyText>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box>
						<TitleText two>ZIP code/Postal code</TitleText>
						<BodyText>826001</BodyText>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box>
						<TitleText two>Registered Date</TitleText>
						<BodyText>04 Oct 2021</BodyText>
					</Box>
				</Grid>
			</Grid>
			<Grid container style={{ marginTop: 20 }}>
				<Grid item xs={4}>
					<Box>
						<TitleText two>Address</TitleText>
						<BodyText>SARAIDHELA</BodyText>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box>
						<TitleText two>Country</TitleText>
						<BodyText>India</BodyText>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box>
						<TitleText two>Last Login</TitleText>
						<BodyText>12 Feb 2022, 10:09:25 AM</BodyText>
					</Box>
				</Grid>
			</Grid>
			<Grid container style={{ marginTop: 20 }}>
				<Grid item xs={4}>
					<Box>
						<TitleText two>Total Login</TitleText>
						<BodyText>75</BodyText>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default Overview;
