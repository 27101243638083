import React, { useState } from 'react';
import {
	makeStyles,
	Button,
	MenuItem,
	LinearProgress,
	Box,
	// TextField,
	InputAdornment,
	Grid,
	Tabs,
	Tab,
	Divider,
	withStyles,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import { TextField } from 'formik-material-ui';
// import TextField from '@material-ui/core/TextField
import { Field, Form, Formik } from 'formik';
import PhoneIcon from '@material-ui/icons/Phone';
import TitleText from '../../components/reusableComponents/TitleText';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BodyText from './BodyText';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import LocationOnIcon from '@material-ui/icons/LocationOn';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		backgroundColor: 'white',
		marginBottom: 30,
		width: '100%',
		marginTop: 10,
		borderRadius: 10,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
			marginTop: 8,
		},
	},
	InputPropsStyle: {
		backgroundColor: 'white',
		borderRadius: 25,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: '#1593ff',
		color: 'white',
		borderRadius: 25,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: '#1593ff',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
}));
const GreenCheckbox = withStyles({
	root: {
		color: '#39c9bb',
		'&$checked': {
			color: '#39c9bb',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const country = ['INDIA', 'SRILANKA'];
const AddGSTForm = () => {
	const classes = useStyles();
	// const theme = useTheme();
	const [tabvalue, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [check, setCheck] = useState(false);
	const handleCheck = (event) => {
		setCheck(event.target.checked);
	};
	const [currentStep, SetCurrentStep] = useState('Step 1');

	// const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const handleSubmit = (data) => {
		console.log(data);
	};

	return (
		<>
			<Formik
				initialValues={{}}
				validate={(values) => {
					const errors = {};
				}}
				onSubmit={handleSubmit}>
				{({ submitForm, isSubmitting }) => (
					<>
						<Form>
							<>
								<Grid container spacing={2} alignItems='center'>
									<Grid item xs={6}>
										{/* < */}
										<Field
											component={TextField}
											name='gst_number'
											type='text'
											variant='outlined'
											// label='GST NUMBER'
											className={classes.selectField}
											InputProps={{
												className: classes.InputPropsStyle,
											}}
											inputProps={{
												style: { padding: 14, fontSize: 16 },
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<Field
											component={TextField}
											name='first_name'
											type='select'
											variant='outlined'
											label='COMPANY NAME'
											className={classes.selectField}
											InputProps={{
												className: classes.InputPropsStyle,
											}}
											inputProps={{
												style: { padding: 14, fontSize: 16 },
											}}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2} alignItems='center'>
									<Grid item xs={6}>
										<Field
											component={TextField}
											name='first_name'
											type='text'
											variant='outlined'
											label='EMAIL ID'
											className={classes.selectField}
											InputProps={{
												className: classes.InputPropsStyle,
											}}
											inputProps={{
												style: { padding: 14, fontSize: 16 },
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<Field
											component={TextField}
											name='first_name'
											type='select'
											variant='outlined'
											label='CONTACT NUMBER'
											className={classes.selectField}
											InputProps={{
												className: classes.InputPropsStyle,
											}}
											inputProps={{
												style: { padding: 14, fontSize: 16 },
											}}
										/>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={2}></Grid>
									<Grid item xs={6}>
										<button
											type='submit'
											onClick={submitForm}
											style={{
												cursor: 'pointer',
												padding: '14px 20px',
												backgroundColor: '#1593ff',
												color: 'white',
												marginLeft: 10,
												borderRadius: 25,
												width: '100%',
												fontSize: 16,
											}}>
											<TitleText textStyle={{ fontSize: 16 }}>Submit</TitleText>
										</button>
									</Grid>
									<Grid item xs={2}></Grid>
								</Grid>
								<Box display='flex' justifyContent='space-between'>
									{/* <Box>
										<BodyText one>
											NOTE: (1) GSTIN can’t be updated once booking is
											completed.
										</BodyText>
										<BodyText one>
											(2) Your Taxes may get updated post submitting GST
											details.
										</BodyText>
									</Box> */}
									<Box display='flex'></Box>
								</Box>
							</>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default AddGSTForm;
