import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ERROR_404_Image from '../../assets/images/ERROR_404_Image.svg';
import TitleText from '../../components/reusableComponents/TitleText';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	rootContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		backgroundColor: 'darkblue',
		height: '100vh',
	},
	image_404: {
		width: '80%',
		objectFit: 'contain',
		[theme.breakpoints.up('sm')]: {
			width: '50%',
		},
	},
	textContainer: {
		paddingTop: 40,
		textAlign: 'center',
		paddingLeft: 14,
		paddingRight: 14,
		[theme.breakpoints.up('sm')]: {
			width: '60%',
		},
		[theme.breakpoints.up('lg')]: {
			width: '35%',
		},
		[theme.breakpoints.up('xl')]: {
			paddingTop: 100,
		},
	},
}));

const Error404Page = () => {
	const classes = useStyles();
	return (
		<Grid className={classes.rootContainer}>
			<img
				src={ERROR_404_Image}
				alt='Error 404'
				className={classes.image_404}
			/>
			<Grid className={classes.textContainer}>
				<TitleText textStyle={{ color: 'white' }}>
					YOU MUST HAVE BEEN LOOKING FOR SOMETHING STRANGE TO FIND THIS PAGE
				</TitleText>
			</Grid>
			<Grid style={{ marginTop: '4%' }}>
				<Link to='/' style={{ color: 'white' }}>
					<TitleText> Go Back</TitleText>
				</Link>
			</Grid>
		</Grid>
	);
};

export default Error404Page;
