import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { Select, Space } from 'antd';

const SignInSchema = Yup.object().shape({
	name: Yup.string().required('Name Required'),
	email: Yup.string().required('Email Required'),
	password: Yup.string()
		.required('Password Required')
		.min(8, 'Password must be at least 8 characters')
		.matches(RegExp('(.*[a-z].*)'), 'Password must be at least 1 Lowercase')
		.matches(RegExp('(.*[A-Z].*)'), 'Password must be at least 1 Uppercase')
		.matches(RegExp('(.*\\d.*)'), 'Password must be at least 1 Number')
		.matches(
			RegExp('[!@#$%^&*(),.?":{}|<>]'),
			'Password must be at least 1 Special'
		),
	mobile: Yup.string().required('Mobile Required'),
	address: Yup.string().required('Address Required'),
	// referral: Yup.string().required('Referral Required'),
	address: Yup.string().required('Address Required'),
	pincode: Yup.string().required('Pincode Required'),
	pancard: Yup.string().required('Pancard Required'),
	aadhaar: Yup.string().required('Aadhaar Required'),
	account: Yup.string().required('Account Required'),
	ifsc: Yup.string().required('Ifsc Required'),
});
const RegisterForm = ({ SetStatus, handleClose, setOpen }) => {
	const dispatch = useDispatch();
	// const navigate = useNavigate();
	const [tab, setTab] = useState('member');
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleTab = (val) => {
		setTab(val);
	};
	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};
	const handleFormSubmit = (data) => {
		alert(JSON.stringify(data));
	};
	return (
		<Formik
			initialValues={{
				name: '',
				email: '',
				password: '',
				mobile: '',
				address: '',
				referral: '',
				pincode: '',
				pancard: '',
				aadhaar: '',
				account: '',
				ifsc: '',
			}}
			validationSchema={SignInSchema}
			onSubmit={handleFormSubmit}>
			{(formik, values) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box>
							{/*  */}
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Box>
										<Grid container style={{ padding: 15 }} spacing={3}>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Name <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='name'
													type='text'
													placeHolder='Enter Your Name*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.name
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.name ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.name}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Email <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='email'
													type='email'
													placeHolder='Enter Your Email*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.email
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.email ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.email}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Create Password{' '}
													<span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='password'
													type='password'
													placeHolder='Enter Your Password*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.password
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.password ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.password}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Mobile Number <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='mobile'
													type='number'
													placeHolder='Enter Your Mobile Number*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.mobile
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.mobile ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.mobile}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Address <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='address'
													type='text'
													placeHolder='Enter Your Address*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.address
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.address ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.address}
													</span>
												) : null}
											</Grid>{' '}
											{tab === 'member' && (
												<Grid item xs={12}>
													<span
														style={{
															color: '#6c757d',
														}}>
														Referral Code{' '}
														{/* <span style={{ color: 'red' }}>*</span> */}
													</span>
													<Field
														name='referral'
														type='text'
														placeHolder='Enter Your Referral Code*'
														style={{
															padding: 10,
															width: '100%',
															borderRadius: 5,
															marginTop: 5,
															border: errors?.referral
																? '2px solid red'
																: '1px solid #6c757d',
														}}
														inputProps={{
															style: { padding: 12 },
														}}
													/>
													<br />
													{errors?.referral ? (
														<span style={{ color: 'red', fontSize: 12 }}>
															{errors?.referral}
														</span>
													) : null}
												</Grid>
											)}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Pincode <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='pincode'
													type='text'
													placeHolder='Enter Your Pincode*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.pincode
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.pincode ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.pincode}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Pancard <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='pancard'
													type='text'
													placeHolder='Enter Your Pancard*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.pancard
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.pancard ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.pancard}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Aadhaar <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='aadhaar'
													type='text'
													placeHolder='Enter Your Aadhaar*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.aadhaar
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.aadhaar ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.aadhaar}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Account Number <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='account'
													type='text'
													placeHolder='Enter Your Account Number*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.account
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.account ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.account}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													IFSC Code <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='ifsc'
													type='text'
													placeHolder='Enter Your IFSC Code*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.ifsc
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.ifsc ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.ifsc}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<Button
													type='submit'
													style={{
														backgroundColor: '#0035F0',
														color: 'white',
														width: '100%',
														padding: 10,
													}}>
													Register
												</Button>
												<p style={{ fontSize: 12, marginTop: 15 }}></p>
												<Box display={'flex'} alignItems={'center'} mt={2}>
													<p style={{ fontSize: 12 }}>
														Already have an account ?
													</p>
													<Button
														// onClick={() => navigate('/login')}
														style={{
															backgroundColor: '#0035F0',
															color: 'white',
															textTransform: 'capitalize',
															marginLeft: 10,
															padding: '4px 20px',
															fontSize: 12,
														}}>
														Login
													</Button>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default RegisterForm;
