import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	withStyles,
	Checkbox,
	Hidden,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import FlightDetailModal from '../../components/reusableComponents/FlightDetailModal';
import { setSelectedTripID } from '../../reducers/UiReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchFormDispatch } from '../../reducers/HomeReducer';
import Drawers from '../../components/Drawers';
import Login from '../../components/reusableComponents/Login';
// import OnewayForm from '../../form/OnewayForm';
import BusListForm from '../../form/BusListForm';
import { Divider } from '@mui/material';
import { Showseat } from './ShowSeat';
import {
	addBusSearchDispatch,
	fetchSeatLayoutDispatch,
} from '../../reducers/BusReducer';
import Footer from './Footer';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import SkeletonOneway from '../../components/reusableComponents/SkeletonOneway';
import LoaderModal from '../../components/reusableComponents/LoaderModal';
import Linear from '../../components/reusableComponents/Linear';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiChip-sizeSmall': {
			height: 36,
		},
	},
	accodin: {
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-evenly',
		},
	},
	searhform: {
		// backgroundImage: `url(${Home})`,
		background: 'rgb(34, 118, 227)',
		// height: 272,
		backgroundSize: 'cover',
		paddingBottom: 15,
		backgroundRepeat: 'no-repeat',

		paddingTop: 44,

		// [theme.breakpoints.down("xs")]: {
		//   height: 590,
		// },
	},
	radio: {
		'& .MuiTypography-body1': {
			fontSize: 13,
		},
	},
	scroll: {
		'&::-webkit-scrollbar': {
			width: '0.2em',
		},
		'&::-webkit-scrollbar-track': {
			// '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.1)',
			outline: '1px solid slategrey',
			// borderTopLeftRadius: 5,
			// borderTopRightRadius: 5,
			borderRadius: 5,
		},
	},
}));

const BusList = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [expanded, setExpanded] = React.useState('');
	const [scrollVal, SetScrollVal] = useState(10);
	const [scrollValResult, SetScrollValResult] = useState(10);
	const [hasMoreresult, SethasMoreResult] = useState(true);
	const [hasMorefilterData, SethasMoreFilterData] = useState(true);
	const [result, setResult] = useState([]);
	const history = useHistory();
	const [rule, SetRule] = useState(false);
	const theme = useTheme();
	const location = useLocation();
	const { search, loading } = useSelector((state) => state.home);
	const { busSearch, loadingbus, layoutloading, seatLayout, loadingbus2 } =
		useSelector((state) => state.bus);
	const { selectedTripID, selectedBus, selectedDesBus } = useSelector(
		(state) => state.ui
	);
	const [stop1, setStop1] = useState(false);
	const [value, setValue] = React.useState('');

	const [stop2, setStop2] = useState(false);
	const [stop3, setStop3] = useState(false);
	const [ac, setAC] = useState(false);
	const [nonac, setNAC] = useState(false);
	const [seater, setSeater] = useState(false);
	const [sleeper, setSleeper] = useState(false);
	const [morning, setMorning] = useState(false);
	const [dophar, setDOphar] = useState(false);
	const [toggle, setToggle] = useState(false);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const matches2 = useMediaQuery(theme.breakpoints.down('xs'));
	console.log('Location', location);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	function valuetext(value) {
		return `${value}°C`;
	}
	function display(a) {
		var hours = Math.trunc(a / 60);
		var minutes = a % 60;
		console.log(hours + ':' + minutes);
	}
	function timeConvertbus(a) {
		let arhour = Math.floor(a / 60);
		if (arhour > 24) {
			arhour = arhour - 24;
		}
		let armin = a % 60;
		let format = `${arhour}:${armin === 0 ? '00' : armin}:00`;
		function formatTime(timeString) {
			const [hourString, minute] = timeString.split(':');
			const hour = +hourString % 24;
			return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM');
		}
		return formatTime(format);
		console.log('first', armin);
	}

	console.log(location.state);

	let filterData = [];
	filterData =
		busSearch &&
		busSearch.result &&
		busSearch.result.availableTrips &&
		busSearch.result.availableTrips.length > 0 &&
		busSearch.result.availableTrips.slice().sort((a, b) => {
			let first = Array.isArray(a?.fares) ? a?.fares[0] : a.fares;
			let second = Array.isArray(b?.fares) ? b?.fares[0] : b.fares;
			return first - second;
		});
	console.log('fILTER', filterData);

	const handleBusType = (response) => {
		let ac = [];
		let nonac = [];
		let sleeper = [];
		let seater = [];
		let combine = [];
		if (
			value === 'AC' ||
			value === 'NONAC' ||
			value === 'SLEEPER' ||
			value === 'SEATER'
		) {
			setValue('');
			SetRule(false);
		} else {
			setValue(response);
			SetRule(true);
			if (response === 'AC') {
				busSearch.result &&
					busSearch.result.availableTrips &&
					busSearch.result.availableTrips.length > 0 &&
					busSearch.result.availableTrips.map((i, index) => {
						if (i.AC === 'true') {
							ac.push({
								...i,
							});
						}
					});
			} else if (response === 'NONAC') {
				busSearch.result &&
					busSearch.result.availableTrips &&
					busSearch.result.availableTrips.length > 0 &&
					busSearch.result.availableTrips.map((i, index) => {
						if (i.nonAC === 'true') {
							nonac.push({
								...i,
							});
						}
					});
			} else if (response === 'SLEEPER') {
				busSearch.result &&
					busSearch.result.availableTrips &&
					busSearch.result.availableTrips.length > 0 &&
					busSearch.result.availableTrips.map((i, index) => {
						if (i.sleeper === 'true') {
							sleeper.push({
								...i,
							});
						}
					});
			} else if (response === 'SEATER') {
				busSearch.result &&
					busSearch.result.availableTrips &&
					busSearch.result.availableTrips.length > 0 &&
					busSearch.result.availableTrips.map((i, index) => {
						if (i.seater === 'true') {
							seater.push({
								...i,
							});
						}
					});
			}
		}

		combine = [...ac, ...nonac, ...sleeper, ...seater];
		console.log('first');
		console.log('res', combine);
		setResult(combine);
	};
	const handleBusTimeFilter = () => {
		let midnight = [];
		let noon = [];
		busSearch.result &&
			// busSearch.result.availableTrips &&
			// busSearch.result.availableTrips.length > 0 &&
			// busSearch.result.availableTrips.map((i,index)=>{
			//   if(i.departureTime.length===4){
			//     if(6<i.departureTime.slice(0,2)<12){
			//       noon.push({
			//         ...i
			//       })
			//     }
			//   }
			// })
			busSearch.result &&
			busSearch.result.availableTrips &&
			busSearch.result.availableTrips.length > 0 &&
			busSearch.result.availableTrips.map((i, index) => {
				if (i.departureTime.length === 4) {
					if (0 <= i.departureTime.slice(0, 2) <= 6) {
						midnight.push({
							...i,
						});
					}
				}
			});
		console.log('midnight', midnight);
	};
	console.log('result', result);
	const fetchMoreData = () => {
		if (scrollVal >= filterData.length) {
			SethasMoreFilterData(false);
		}

		setTimeout(() => {
			SetScrollVal(scrollVal + 5);
		}, 500);
	};
	const fetchMoreDataResult = () => {
		if (scrollValResult >= filterData.length) {
			SethasMoreResult(false);
		}
		setTimeout(() => {
			SetScrollValResult(scrollValResult + 5);
		}, 500);
	};

	useEffect(() => {
		if (location.state) {
			dispatch(addBusSearchDispatch(location.state.formdata));
			dispatch(setSelectedTripID(null));
		}
	}, [dispatch]);

	//  if(busSearch &&
	//   busSearch?.result?.availableTrips)

	// console.log(location.state);
	console.log('value', value);
	// console.log(scrollVal);
	return (
		<>
			<HeaderSection />
			{loadingbus ? (
				<Linear />
			) : (
				<Box
					className={classes.searhform}
					style={{ backgroundPosition: matches2 ? '' : '1px -443px' }}>
					<Container>
						<BusListForm />
					</Container>
				</Box>
			)}
			<Box style={{ backgroundColor: 'rgb(238, 244, 253)' }}>
				<Container>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Grid container spacing={4} style={{ marginTop: 30 }}>
								<Hidden only={['xs', 'sm']}>
									<Grid
										item
										xs={3}
										lg={3}
										style={{
											background: 'rgb(255, 255, 255)',
											borderRadius: 5,
											height: '100vh',
											marginBottom: 50,
										}}>
										<Box
											display='flex'
											justifyContent='space-between'
											alignItems='center'>
											<span style={{ fontSize: 14, fontWeight: 600 }}>
												Filters
											</span>
											<span
												style={{ color: 'rgb(197, 197, 197)', fontSize: 14 }}>
												{/* Reset All */}
											</span>
										</Box>
										<Divider style={{ marginTop: 20 }} />
										<Box
											display='flex'
											justifyContent='space-between'
											mt={'20px'}
											alignItems='center'>
											<span style={{ fontSize: 14, fontWeight: 600 }}>
												Bus Type
											</span>
											<span
												style={{ color: 'rgb(197, 197, 197)', fontSize: 16 }}>
												{/* Reset All */}
											</span>
										</Box>
										<Box display='flex' mt={'10px'}>
											<Box
												onClick={() => handleBusType('AC')}
												style={{
													background:
														value === 'AC' ? '#0095ff47' : 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													padding: '0.7rem 0px',
													cursor: 'pointer',
													fontSize: 14,
												}}>
												<span>AC</span>
											</Box>
											<Box
												onClick={() => handleBusType('NONAC')}
												style={{
													background:
														value === 'NONAC'
															? '#0095ff47'
															: 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													marginLeft: 10,
													padding: '0.7rem 0px',
													cursor: 'pointer',
													fontSize: 14,
												}}>
												<span>Non-AC</span>
											</Box>
										</Box>
										<Box display='flex' mt={'10px'}>
											<Box
												onClick={() => handleBusType('SEATER')}
												style={{
													background:
														value === 'SEATER'
															? '#0095ff47'
															: 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													padding: '0.7rem 0px',
													cursor: 'pointer',
													fontSize: 14,
												}}>
												<span>Seater</span>
											</Box>
											<Box
												onClick={() => handleBusType('SLEEPER')}
												style={{
													background:
														value === 'SLEEPER'
															? '#0095ff47'
															: 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													marginLeft: 10,
													padding: '0.7rem 0px',
													cursor: 'pointer',
													fontSize: 14,
												}}>
												<span>Sleeper</span>
											</Box>
										</Box>
										<Divider style={{ marginTop: 20 }} />
										<Box
											display='flex'
											justifyContent='space-between'
											mt={'20px'}
											alignItems='center'>
											<span style={{ fontSize: 14, fontWeight: 600 }}>
												Departure Time
											</span>
											<span
												style={{ color: 'rgb(197, 197, 197)', fontSize: 16 }}>
												{/* Reset */}
											</span>
										</Box>
										<Box display='flex' mt={'10px'}>
											<Box
												onClick={handleBusTimeFilter}
												style={{
													background: 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													padding: '0.7rem 0px',
													fontSize: 14,
												}}>
												<span>12 midnight-6 AM</span>
											</Box>
											<Box
												style={{
													background: 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													marginLeft: 10,
													padding: '0.7rem 0px',
													fontSize: 14,
												}}>
												<span>6 AM-12noon</span>
											</Box>
										</Box>
										<Box display='flex' mt={'10px'}>
											<Box
												style={{
													background: 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													padding: '0.7rem 0px',
													fontSize: 14,
												}}>
												<span>12 noon - 6 PM</span>
											</Box>
											<Box
												style={{
													background: 'rgb(243, 246, 248)',
													width: '50%',
													textAlign: 'center',
													marginLeft: 10,
													fontSize: 14,
													padding: '0.7rem 0px',
												}}>
												<span>6 PM - 12 midnight</span>
											</Box>
										</Box>
										<Divider style={{ marginTop: 20 }} />
										<Box
											display='flex'
											justifyContent='space-between'
											mt={'20px'}
											alignItems='center'>
											<span style={{ fontSize: 14, fontWeight: 600 }}>
												Arrival Time
											</span>
											<span
												style={{ color: 'rgb(197, 197, 197)', fontSize: 16 }}>
												{/* Reset */}
											</span>
										</Box>
										<Box display='flex' mt={'10px'}>
											<Box
												style={{
													background: 'rgb(243, 246, 248)',
													width: '50%',
													fontSize: 14,
													textAlign: 'center',
													padding: '0.7rem 0px',
												}}>
												<span>12 midnight-6 AM</span>
											</Box>
											<Box
												style={{
													background: 'rgb(243, 246, 248)',
													width: '50%',
													fontSize: 14,
													textAlign: 'center',
													marginLeft: 10,
													padding: '0.7rem 0px',
												}}>
												<span>6 AM-12noon</span>
											</Box>
										</Box>
										<Box display='flex' mt={'10px'}>
											<Box
												style={{
													background: 'rgb(243, 246, 248)',
													width: '50%',
													fontSize: 14,
													textAlign: 'center',
													padding: '0.7rem 0px',
												}}>
												<span>12 noon - 6 PM</span>
											</Box>
											<Box
												style={{
													background: 'rgb(243, 246, 248)',
													width: '50%',
													fontSize: 14,
													textAlign: 'center',
													marginLeft: 10,
													padding: '0.7rem 0px',
												}}>
												<span>6 PM - 12 midnight</span>
											</Box>
										</Box>
									</Grid>
								</Hidden>
								<Grid item xs={12} md={9} lg={9}>
									{loadingbus ? (
										[0, 1, 2, 3, 4].map((i) => {
											return <SkeletonOneway />;
										})
									) : loadingbus ? (
										[0, 1, 2, 3, 4].map((i) => {
											return <SkeletonOneway />;
										})
									) : rule || (result && result.length > 0) ? (
										result && result.length > 0 ? (
											<InfiniteScroll
												dataLength={scrollValResult}
												next={fetchMoreDataResult}
												hasMore={hasMoreresult}
												loader={<h4>Loading...</h4>}
												endMessage={
													<p style={{ textAlign: 'center' }}>
														<b>No More Bus</b>
													</p>
												}>
												{result.map((item, index) => {
													return index < scrollValResult ? (
														matches ? (
															<></>
														) : (
															<>
																<Box
																	style={{
																		boxShadow:
																			'3px 3px 16px -4px rgb(0 0 0 / 30%)',
																		// padding: 8,
																		background: 'white',
																		paddingTop: '2px',
																		paddingLeft: '20px',
																		paddingRight: '20px',
																		paddingBottom: '13px',
																		borderRadius: 5,
																		marginTop: index == 0 ? '' : 10,
																	}}>
																	<Box
																		display='flex'
																		// style={{
																		// 	boxShadow: '3px 3px 16px -4px rgb(0 0 0 / 30%)',
																		// 	padding: 8,
																		// }}
																		justifyContent='space-between'
																		alignItems='center'
																		mt={'20px'}>
																		<Grid container>
																			<Grid item xs={12} lg={3}>
																				<Box
																					alignItems='center'
																					style={{ overflow: 'hidden' }}>
																					<TitleText
																						two
																						style={{
																							whiteSpace: 'nowrap',
																							overflow: 'hidden',
																							textOverflow: 'ellipsis',
																						}}>
																						{item.travels}
																					</TitleText>
																					<BodyText
																						textStyle={{
																							fontSize: 13,
																						}}>
																						{item.busType}
																					</BodyText>
																					<p>
																						<span
																							style={{
																								background: 'rgb(24, 161, 96)',
																								borderColor: 'rgb(24, 161, 96)',
																								color: 'white',
																								border: '1px solid',
																								paddingRight: '0.6rem',
																								paddingLeft: '0.6rem',
																								color: 'rgb(255, 255, 255)',
																								borderRadius: '0.3rem',
																								fontSize: '1.1rem',
																							}}>
																							{/*  */}
																						</span>
																					</p>
																				</Box>
																			</Grid>
																			<Grid
																				item
																				xs={12}
																				lg={5}
																				style={{ display: 'flex' }}>
																				<Box
																					style={{
																						display: 'flex',
																						justifyContent: 'space-between',
																						width: '90%',
																						alignItems: 'center',
																					}}>
																					<Box>
																						<TitleText three>
																							{timeConvertbus(
																								item.departureTime
																							)}
																							{/* {console.log("first",item.departureTime.length===3?item.departureTime.slice(0,1):'')} */}
																							{/* {item.departureTime.length === 4
																								? `${item.departureTime.slice(
																										0,
																										2
																								  )}:${item.departureTime.slice(
																										2,
																										4
																								  )}`
																								: `0${item.departureTime.slice(
																										0,
																										1
																								  )}:${item.departureTime.slice(
																										1,
																										3
																								  )}`} */}
																						</TitleText>
																						<BodyText
																							textStyle={{
																								fontSize: 13,
																							}}>
																							{selectedBus?.name?.split(' ')[0]}
																						</BodyText>
																					</Box>
																					<Box>
																						<span
																							style={{
																								marginLeft: 30,
																								marginRight: 30,
																							}}>
																							{item.duration}
																						</span>
																						<div className='ln'></div>
																					</Box>
																					<Box>
																						<TitleText three>
																							{' '}
																							{timeConvertbus(item.arrivalTime)}
																							{/* {item.arrivalTime.length === 4
																								? `${item.arrivalTime.slice(
																										0,
																										2
																								  )}:${item.arrivalTime.slice(
																										2,
																										4
																								  )}`
																								: `0${item.arrivalTime.slice(
																										0,
																										1
																								  )}:${item.arrivalTime.slice(
																										1,
																										3
																								  )}`} */}
																						</TitleText>
																						<BodyText
																							textStyle={{
																								fontSize: 13,
																							}}>
																							{
																								selectedDesBus?.name?.split(
																									' '
																								)[0]
																							}
																						</BodyText>
																					</Box>
																				</Box>
																			</Grid>
																			<Grid
																				item
																				xs={12}
																				lg={4}
																				style={{
																					display: 'flex',
																					justifyContent: 'space-between',
																					alignItems: 'center',
																				}}>
																				<Box alignItems='center' display='flex'>
																					<TitleText
																						textStyle={{
																							fontSize: 22,
																						}}>
																						₹{' '}
																						{Array.isArray(item?.fares)
																							? Number(
																									Math.round(item?.fares[0])
																							  )
																							: Number(Math.round(item.fares))}
																					</TitleText>
																				</Box>
																				<Box>
																					<BodyText
																						textStyle={{ fontSize: 12 }}>
																						Total {item.availableSeats} seats
																						left
																					</BodyText>
																					<Box
																						onClick={() => {
																							item.id === selectedTripID
																								? dispatch(
																										setSelectedTripID(null)
																								  )
																								: dispatch(
																										setSelectedTripID(item.id)
																								  );
																							let trip = {
																								tripid: item.id,
																							};
																							dispatch(
																								fetchSeatLayoutDispatch(trip)
																							);
																						}}
																						style={{
																							color: 'white',
																							background: 'rgb(0, 55, 114)',
																							padding: '8px 25px',
																							borderRadius: 8,
																							cursor: 'pointer',
																							boxShadow:
																								'3px 3px 12px -6px rgb(0 0 0 / 70%)',
																						}}>
																						<TitleText one>
																							Select Seat
																						</TitleText>
																					</Box>
																				</Box>
																			</Grid>
																		</Grid>
																	</Box>

																	{item.id === selectedTripID ? (
																		<>
																			<Box
																				display='flex'
																				justifyContent='space-between'>
																				<Showseat
																					seatLayout={seatLayout}
																					item={item}
																				/>
																			</Box>
																		</>
																	) : (
																		''
																	)}
																</Box>
															</>
														)
													) : (
														''
													);
												})}
											</InfiniteScroll>
										) : (
											<div
												style={{
													padding: '30px 15px',
													background: 'white',
													borderBottom: '1px solid #e6e6e6',
													textAlign: 'center',
												}}>
												<img src='https://daddyduckinnovationlab.files.wordpress.com/2020/06/bus.gif'></img>
												<p style={{ fontSize: '24px', textAlign: 'center' }}>
													Sorry No Bus Found !!
												</p>
											</div>
										)
									) : filterData && filterData.length > 0 ? (
										<InfiniteScroll
											dataLength={scrollVal}
											next={fetchMoreData}
											hasMore={hasMorefilterData}
											loader={
												<h4 style={{ textAlign: 'center' }}>Loading...</h4>
											}
											endMessage={
												<p style={{ textAlign: 'center' }}>
													<b>No More Bus</b>
												</p>
											}>
											{filterData.map((item, index) => {
												return index < scrollVal ? (
													matches ? (
														<>
															<Box
																style={{
																	boxShadow:
																		'3px 3px 16px -4px rgb(0 0 0 / 30%)',
																	// padding: 8,
																	background: 'white',
																	paddingTop: '2px',
																	paddingLeft: '20px',
																	paddingRight: '20px',
																	paddingBottom: '13px',
																	borderRadius: 5,
																	marginTop: index == 0 ? '' : 10,
																}}>
																<Box
																	display='flex'
																	// style={{
																	// 	boxShadow: '3px 3px 16px -4px rgb(0 0 0 / 30%)',
																	// 	padding: 8,
																	// }}
																	justifyContent='space-between'
																	alignItems='center'
																	mt={'20px'}>
																	<Grid container>
																		<Grid item xs={12} lg={3}>
																			<Box
																				alignItems='center'
																				style={{ overflow: 'hidden' }}>
																				<TitleText
																					two
																					style={{
																						whiteSpace: 'nowrap',
																						overflow: 'hidden',
																						textOverflow: 'ellipsis',
																					}}>
																					{item.travels}
																				</TitleText>
																				<BodyText
																					textStyle={{
																						fontSize: 13,
																					}}>
																					{item.busType}
																				</BodyText>
																				<p>
																					<span
																						style={{
																							background: 'rgb(24, 161, 96)',
																							borderColor: 'rgb(24, 161, 96)',
																							color: 'white',
																							border: '1px solid',
																							paddingRight: '0.6rem',
																							paddingLeft: '0.6rem',
																							color: 'rgb(255, 255, 255)',
																							borderRadius: '0.3rem',
																							fontSize: '1.1rem',
																						}}></span>
																				</p>
																			</Box>
																		</Grid>
																		<Grid
																			item
																			xs={12}
																			lg={5}
																			style={{ display: 'flex' }}>
																			<Box
																				style={{
																					display: 'flex',
																					justifyContent: 'space-between',
																					width: '90%',
																					alignItems: 'center',
																				}}>
																				<Box>
																					<TitleText three>
																						{timeConvertbus(item.departureTime)}
																					</TitleText>
																					<BodyText
																						textStyle={{
																							fontSize: 13,
																						}}>
																						{selectedBus?.name?.split(' ')[0]}
																					</BodyText>
																				</Box>
																				<Box>
																					<span
																						style={{
																							marginLeft: 30,
																							marginRight: 30,
																						}}>
																						{item.duration}
																					</span>
																					<div className='ln'></div>
																				</Box>
																				<Box>
																					<TitleText three>
																						{timeConvertbus(item.arrivalTime)}
																					</TitleText>
																					<BodyText
																						textStyle={{
																							fontSize: 13,
																						}}>
																						{
																							selectedDesBus?.name?.split(
																								' '
																							)[0]
																						}
																					</BodyText>
																				</Box>
																			</Box>
																		</Grid>
																		<Grid
																			item
																			xs={12}
																			lg={4}
																			style={{
																				display: 'flex',
																				justifyContent: 'space-between',
																				alignItems: 'center',
																			}}>
																			<Box alignItems='center' display='flex'>
																				<TitleText
																					textStyle={{
																						fontSize: 22,
																					}}>
																					₹
																					{Array.isArray(item?.fares)
																						? Number(Math.round(item?.fares[0]))
																						: Number(Math.round(item.fares))}
																				</TitleText>
																			</Box>
																			<Box>
																				<BodyText textStyle={{ fontSize: 12 }}>
																					Total {item.availableSeats} seats left
																				</BodyText>
																				<Box
																					onClick={() => {
																						item.id === selectedTripID
																							? dispatch(
																									setSelectedTripID(null)
																							  )
																							: dispatch(
																									setSelectedTripID(item.id)
																							  );
																						let trip = {
																							tripid: item.id,
																						};
																						dispatch(
																							fetchSeatLayoutDispatch(trip)
																						);
																					}}
																					style={{
																						color: 'white',
																						background: 'rgb(0, 55, 114)',
																						padding: '8px 25px',
																						borderRadius: 8,
																						cursor: 'pointer',
																						boxShadow:
																							'3px 3px 12px -6px rgb(0 0 0 / 70%)',
																					}}>
																					<TitleText one>Select Seat</TitleText>
																				</Box>
																			</Box>
																		</Grid>
																	</Grid>
																</Box>

																{item.id === selectedTripID ? (
																	<>
																		<Box display='flex'>
																			<Showseat
																				seatLayout={seatLayout}
																				item={item}
																			/>
																		</Box>
																	</>
																) : (
																	''
																)}
															</Box>
														</>
													) : (
														<>
															<Box
																style={{
																	boxShadow:
																		'3px 3px 16px -4px rgb(0 0 0 / 30%)',
																	// padding: 8,
																	background: 'white',
																	paddingTop: '2px',
																	paddingLeft: '20px',
																	paddingRight: '20px',
																	paddingBottom: '13px',
																	borderRadius: 5,
																	marginTop: index == 0 ? '' : 10,
																}}>
																<Box
																	display='flex'
																	// style={{
																	// 	boxShadow: '3px 3px 16px -4px rgb(0 0 0 / 30%)',
																	// 	padding: 8,
																	// }}
																	justifyContent='space-between'
																	alignItems='center'
																	mt={'20px'}>
																	<Grid container>
																		<Grid item xs={12} lg={3}>
																			<Box
																				alignItems='center'
																				style={{ overflow: 'hidden' }}>
																				<TitleText
																					two
																					style={{
																						whiteSpace: 'nowrap',
																						overflow: 'hidden',
																						textOverflow: 'ellipsis',
																					}}>
																					{item.travels}
																				</TitleText>
																				<BodyText
																					textStyle={{
																						fontSize: 13,
																					}}>
																					{item.busType}
																				</BodyText>
																				<p>
																					<span
																						style={{
																							background: 'rgb(24, 161, 96)',
																							borderColor: 'rgb(24, 161, 96)',
																							color: 'white',
																							border: '1px solid',
																							paddingRight: '0.6rem',
																							paddingLeft: '0.6rem',
																							color: 'rgb(255, 255, 255)',
																							borderRadius: '0.3rem',
																							fontSize: '1.1rem',
																						}}></span>
																				</p>
																			</Box>
																		</Grid>
																		<Grid
																			item
																			xs={12}
																			lg={5}
																			style={{ display: 'flex' }}>
																			<Box
																				style={{
																					display: 'flex',
																					justifyContent: 'space-between',
																					width: '90%',
																					alignItems: 'center',
																				}}>
																				<Box>
																					<TitleText three>
																						{timeConvertbus(item.departureTime)}
																					</TitleText>
																					<BodyText
																						textStyle={{
																							fontSize: 13,
																						}}>
																						{selectedBus?.name?.split(' ')[0]}
																					</BodyText>
																				</Box>
																				<Box>
																					<span
																						style={{
																							marginLeft: 30,
																							marginRight: 30,
																						}}>
																						{item.duration}
																					</span>
																					<div className='ln'></div>
																				</Box>
																				<Box>
																					<TitleText three>
																						{timeConvertbus(item.arrivalTime)}
																					</TitleText>
																					<BodyText
																						textStyle={{
																							fontSize: 13,
																						}}>
																						{
																							selectedDesBus?.name?.split(
																								' '
																							)[0]
																						}
																					</BodyText>
																				</Box>
																			</Box>
																		</Grid>
																		<Grid
																			item
																			xs={12}
																			lg={4}
																			style={{
																				display: 'flex',
																				justifyContent: 'space-between',
																				alignItems: 'center',
																			}}>
																			<Box alignItems='center' display='flex'>
																				<TitleText
																					textStyle={{
																						fontSize: 22,
																					}}>
																					{console.log(
																						'Fares',
																						Array.isArray(item?.fares)
																							? item?.fares[0]
																							: item.fares
																					)}{' '}
																					₹{' '}
																					{Array.isArray(item?.fares)
																						? Number(Math.round(item?.fares[0]))
																						: Number(Math.round(item.fares))}
																				</TitleText>
																			</Box>
																			<Box>
																				<BodyText textStyle={{ fontSize: 12 }}>
																					Total {item.availableSeats} seats left
																				</BodyText>
																				<Box
																					onClick={() => {
																						item.id === selectedTripID
																							? dispatch(
																									setSelectedTripID(null)
																							  )
																							: dispatch(
																									setSelectedTripID(item.id)
																							  );
																						let trip = {
																							tripid: item.id,
																						};
																						dispatch(
																							fetchSeatLayoutDispatch(trip)
																						);
																					}}
																					style={{
																						color: 'white',
																						background: 'rgb(0, 55, 114)',
																						padding: '8px 25px',
																						borderRadius: 8,
																						cursor: 'pointer',
																						boxShadow:
																							'3px 3px 12px -6px rgb(0 0 0 / 70%)',
																					}}>
																					<TitleText one>Select Seat</TitleText>
																				</Box>
																			</Box>
																		</Grid>
																	</Grid>
																</Box>

																{item.id === selectedTripID ? (
																	<>
																		<Box display='flex'>
																			<Showseat
																				seatLayout={seatLayout}
																				item={item}
																			/>
																		</Box>
																	</>
																) : (
																	''
																)}
															</Box>
														</>
													)
												) : (
													''
												);
											})}
										</InfiniteScroll>
									) : !loadingbus ? (
										<div
											style={{
												padding: '30px 15px',
												background: 'white',
												borderBottom: '1px solid #e6e6e6',
												textAlign: 'center',
											}}>
											<img
												src='https://daddyduckinnovationlab.files.wordpress.com/2020/06/bus.gif'
												height='300px'></img>
											<p style={{ fontSize: '24px', textAlign: 'center' }}>
												Sorry No Bus Found !!
											</p>
										</div>
									) : (
										[0, 1, 2, 3, 4].map((i) => {
											return <SkeletonOneway />;
										})
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<FlightDetailModal />
			<Footer />
			<Drawers />
			{layoutloading || loadingbus2 ? <LoaderModal /> : ''}
			<Login />
		</>
	);
};

export default BusList;
