import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BodyText from './BodyText';
import {
	makeStyles,
	Container,
	Box,
	Grid,
	TextField as TextField2,
} from '@material-ui/core';
import '../../main.css';
import LoadingButton from '@mui/lab/LoadingButton';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import { useHistory } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useLocation } from 'react-router-dom';
import HeaderSection from './HeaderSection';
import { toggleEditTicketModal } from '../../reducers/UiReducer';
import { fetchlogo } from '../../reducers/HomeReducer';
import EditTicketPriceModal from './EditTicketPriceModal';
import { FlightMailTicketDispatch } from '../../reducers/HomeReducer';
const useStyles = makeStyles((theme) => ({
	table: {
		background: '#fff',
		margin: '0px auto',
		width: '850px !important',
		padding: '30px 20px',
		webkitPrintColorAdjust: 'exact',
	},
	table2: {
		boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
		background: '#fff',
		borderSpacing: 0,
		padding: 0,
		margin: 0,
	},
	table3: {
		width: '100%',
		padding: 0,
		margin: 0,
	},
	row: {
		width: '60%',
		padding: '15px 40px',
	},
	row2: {
		width: '50%',
		border: '1px solid #333',
		borderLeft: 0,
		padding: '8px',
	},
	row3: {
		width: '50%',
		borderTop: '1px solid #333',
		borderBottom: '1px solid #333',
		padding: '5px',
	},
}));
const BusTicketDisplay = () => {
	const { ticket } = useSelector((state) => state.home);
	const { busbookinghistory } = useSelector((state) => state.bus);
	const { bookingDetail } = useSelector((state) => state.tripjack);
	const { etrav } = useSelector((state) => state);
	const { agentlogin, agentlogoget } = useSelector((state) => state.user);
	const { logo } = useSelector((state) => state.home);
	console.log('ticket', ticket);
	// useEffect(() => {
	//     dispatch(FlightTicketDispatch());
	// },[])
	const location = useLocation();
	const classes = useStyles();
	const [loading, setLoading] = React.useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	console.log('Location', location);
	useEffect(() => {
		let form = {};

		dispatch(fetchlogo(form));
	}, []);
	const handleClick = () => {
		console.log('Hello');
		// setLoading(true);
		let formMail = {
			orderId: location.state.orderID,
			userEmail: location.state.formData.email,
		};
		dispatch(FlightMailTicketDispatch(formMail));
		window.alert('Ticket Set Successfully');
		// setLoading(false);
	};
	const book = JSON.parse(location.state?.selectedPax);
	console.log('book', book);
	return (
		<>
			<HeaderSection id={1} />
			<Container style={{ marginTop: 100 }}>
				<LoadingButton
					id='back'
					onClick={() => history.push('/')}
					color='primary'
					loadingPosition='start'
					startIcon={<ArrowBackIosNewIcon />}
					variant='contained'>
					Back
				</LoadingButton>
			</Container>

			<table className={classes.table}>
				<tbody>
					<tr>
						<td>
							<table className={classes.table2}>
								<tbody>
									<tr>
										<td>
											<table className={classes.table3}>
												<tbody>
													<tr>
														<td className={classes.row}>
															<img
																src={logo?.result[0].logo}
																style={{
																	maxHeight: 50,
																	maxWidth: 150,
																}}></img>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td style={{ padding: 10 }}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Ticket Reservation
															</span>
															<span style={{ color: 'darkblue', fontSize: 13 }}>
																{' '}
																Please take a print of this ticket. A copy of
																the ticket has to be produced at the time of
																boarding. Please be present atleast 15 mins
																prior to time of departure at the boarding poin
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td
															style={{
																// paddingLeft: 7,
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Pax Details
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Ticket Number
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Seat Number
															</span>
														</td>
													</tr>
													{/* JSON.parse(selectbusbookingHistory?.bookingDetails) */}
													{
														location.state &&
														JSON.parse(location.state?.selectedPax).length > 0
															? JSON.parse(location.state?.selectedPax).map(
																	(item, index) => {
																		return (
																			<tr>
																				<td
																					style={{
																						padding: 8,
																						border: '1px solid lightblue',
																					}}>
																					<span>{`${item.name}`}</span>
																				</td>
																				<td
																					style={{
																						padding: 8,
																						border: '1px solid lightblue',
																					}}>
																					<span>
																						{/* {
																						location?.state?.bookingDetail
																							?.result.pnr
																					} */}
																					</span>
																				</td>
																				<td
																					style={{
																						padding: 8,
																						border: '1px solid lightblue',
																					}}>
																					{/* <span>{item.seatName}</span> */}
																				</td>
																			</tr>
																		);
																	}
															  )
															: ''
														// <tr>
														// 	<td
														// 		style={{
														// 			padding: 8,
														// 			border: '1px solid lightblue',
														// 		}}>
														// 		<span>{`${book?.inventoryItems?.passenger?.name}`}</span>
														// 	</td>
														// 	<td
														// 		style={{
														// 			padding: 8,
														// 			border: '1px solid lightblue',
														// 		}}>
														// 		<span>
														// 			{location?.state?.bookingDetail?.result.pnr}
														// 		</span>
														// 	</td>
														// 	<td
														// 		style={{
														// 			padding: 8,
														// 			border: '1px solid lightblue',
														// 		}}>
														// 		<span>{book?.inventoryItems?.seatName}</span>
														// 	</td>
														// </tr>
													}
												</tbody>
											</table>

											<table style={{ marginTop: 10 }}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																PNR:
															</span>
															<span style={{ marginLeft: 5 }}>{book?.pnr}</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Price:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.inventoryItems?.fare}
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																From:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.sourceCity}
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																To:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.destinationCity}
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Travels:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.travels}
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Bus Type:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.busType}
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Journey Date:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.doj?.split('T')[0]}
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Dep Time:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.pickupTime}
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table style={{ marginTop: 10 }}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Boarding Address:
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																// border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Location:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.pickupLocation}
															</span>
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: 8,
																// border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Landmark:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.pickupLocationLandmark}
															</span>
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: 8,
																// border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Address:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.pickUpLocationAddress}
															</span>
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: 8,
																// border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Boarding Time:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.pickupTime}
															</span>
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: 8,
																// border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Contact Number:
															</span>
															<span style={{ marginLeft: 5 }}>
																{book?.pickUpContactNo}
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											<table style={{ marginTop: 10 }}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																{/* Cancellation Detail */}
															</span>
														</td>
													</tr>
												</tbody>
											</table>
											{/* <table>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Cancellation Time
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Cancellation Charges
															</span>
														</td>
													</tr>
												</tbody>
											</table> */}
											{/* <table style={{ marginTop: 10 }}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Contact Details:
															</span>
														</td>
													</tr>
												</tbody>
											</table> */}
											<table>
												<tbody>
													<tr>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Phone:
															</span>
															<span style={{ marginLeft: 5 }}>
																{location?.state?.detail?.state.data.phone}
															</span>
														</td>
														<td
															style={{
																padding: 8,
																border: '1px solid lightblue',
															}}>
															<span
																style={{
																	color: 'darkblue',
																	fontWeight: 'bold',
																}}>
																Email:
															</span>
															<span style={{ marginLeft: 5 }}>
																{location?.state?.detail?.state.data.email}
															</span>
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<table
				className={classes.table}
				style={{ marginTop: 50, marginBottom: 50 }}>
				<tbody>
					<tr>
						<td>
							<LoadingButton
								id='myPrntbtn'
								onClick={() => window.print()}
								loadingPosition='start'
								startIcon={<PrintIcon />}
								variant='contained'>
								Print
							</LoadingButton>
						</td>
						{/* <td>
							<LoadingButton
								id='download'
								loadingPosition='start'
								startIcon={<DownloadForOfflineIcon />}
								variant='contained'>
								Download
							</LoadingButton>
						</td> */}
						{/* <td>
							<LoadingButton
								id='downloadwprice'
								loadingPosition='start'
								startIcon={<DownloadForOfflineIcon />}
								variant='contained'>
								Download without price
							</LoadingButton>
						</td> */}
						<td>
							<LoadingButton
								id='mail'
								// color='secondary'
								onClick={handleClick}
								// loading={loading}
								loadingPosition='start'
								startIcon={<EmailIcon />}
								variant='contained'>
								Mail
							</LoadingButton>
						</td>
						{/* <td>
							<LoadingButton
								id='editprice'
								// color='secondary'
								onClick={() => dispatch(toggleEditTicketModal(true))}
								// loading={loading}
								loadingPosition='start'
								startIcon={<EditIcon />}
								variant='contained'>
								Edit Price
							</LoadingButton>
						</td> */}
					</tr>
				</tbody>
			</table>
			{/* <Grid container spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}> <Box onClick={()=>history.push('/ticketroundtrip')} style={{textAlign: 'center',
    backgroundColor: '#1976d2',cursor:'pointer'}}>
        <h2 style={{color:'white'}}>Continue to Round Ticket</h2>
      </Box></Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      */}
			<EditTicketPriceModal />
		</>
	);
};

export default BusTicketDisplay;
