import React from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import TitleText from './TitleText';
import BodyText from './BodyText';
import Seat from '../../assets/images/seat.png';
import moment from 'moment';
import {
	toggleFlightModal,
	setFlightId,
	setEtravFlightFare,
} from '../../reducers/UiReducer';
import {
	fareRuleEtravSuccess,
	fareRuleSuccess,
	FlightFareRuleDispatch,
	FlightFareRuleEtravDispatch,
} from '../../reducers/HomeReducer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useHistory, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {
	fareRuleTripJackSuccess,
	FlightFareRuleTripJackDispatch,
} from '../../reducers/TripjackReducer';

const FDOnewayrow = ({ item, loading, convertH2M }) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const history = useHistory();
	const { selectedFDSector } = useSelector((state) => state.ui);
	const { fdresult } = useSelector((state) => state.promo);
	const { orderID, onewayflightdata, onewayflightdataEtrav } = useSelector(
		(state) => state.home
	);
	const location = useLocation();
	const dispatch = useDispatch();
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	const timedifference = (t1, t2) => {
		const date1 = new Date(t1);
		const date2 = new Date(t2);
		const diff = date2.getTime() - date1.getTime();
		return Math.abs(Math.floor(diff / 1000 / 60));
	};
	let text = 'How are you doing today?';
	// const myArray = item.arival_time.split(':');
	// const myArray2 = item.departure_time.split(':');
	// console.log('split', myArray[0] - myArray2[0]);
	// console.log('split2', Math.abs(myArray[1] - myArray2[1]));
	return (
		<>
			<Box
				display='flex'
				style={{
					boxShadow: '3px 3px 16px -4px rgb(0 0 0 / 30%)',
					padding: 8,
					background: 'white',
				}}
				justifyContent='space-between'
				alignItems='center'
				mt={'20px'}>
				<Box display='flex' alignItems='center'>
					{loading ? (
						<Skeleton variant='circle' width={40} height={40} />
					) : (
						<img
							src={`https://nitish.musafirbazar.com/static/media/${
								item.Supplier === 'TBO'
									? item.AirlineCode
									: item.Supplier === 'ETR'
									? item.Airline_Code
									: item.Supplier === 'MFB'
									? item.Segments[0].Airline.AirlineCode
									: item?.flight_number?.split(' ')[0]
							}.gif`}
							width='50px'
							height='25px'></img>
					)}

					<Box alignItems='center' ml={'5px'}>
						<TitleText
							three
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 500,
								color: '#3c3c3c',
								fontSize: 12,
							}}>
							{item.Supplier === 'TBO'
								? item.Segments[0].slice(0, 1).map((i) => {
										return i.Airline.AirlineName;
								  })
								: item.Supplier === 'ETR'
								? item.Segments[0].Airline_Name
								: item.Supplier === 'MFB'
								? item.Segments[0].Airline.AirlineName
								: item.airline}
						</TitleText>
						{loading ? (
							<Skeleton variant='text' width='60px' />
						) : (
							<BodyText
								three
								textStyle={{
									fontFamily: 'Roboto Serif, sans-serif',
									// color: '#8f8f8f',

									fontWeight: 600,
									fontSize: 12,
								}}>
								{item.Supplier === 'TBO'
									? `${item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.AirlineCode;
									  })} ${item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.FlightNumber;
									  })}`
									: item.Supplier === 'ETR'
									? `${item.Segments[0].Airline_Code} ${item.Segments[0].Flight_Number}`
									: item.Supplier === 'MFB'
									? `${item.Segments[0].Airline.AirlineCode}-${item.Segments[0].Airline.FlightNumber}`
									: item.flight_number}
							</BodyText>
						)}
					</Box>
				</Box>

				<Box>
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText
							three
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 500,
								fontSize: 12,
							}}>
							{item.Supplier === 'TBO'
								? item.FareRules[0].Origin
								: item.Supplier === 'ETR'
								? item.Origin
								: item.Supplier === 'MFB'
								? item.Segments[0].Origin.Airport.AirportCode
								: item.origin}
						</TitleText>
					)}
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText three textStyle={{ fontSize: 12 }}>
							{item.Supplier === 'TBO'
								? item.Segments[0].slice(0, 1).map((i) => {
										return i.Origin.DepTime.substr(11, 5);
								  })
								: item.Supplier === 'ETR'
								? item.Segments[0].Departure_DateTime.substr(11, 5)
								: item.Supplier === 'MFB'
								? item.Segments[0].Origin.DepTime.substr(11, 5)
								: item.departure_time}
							{/* {item.Supplier === 'TBO'
								? item.Supplier === 'ETR'
									? item.Segments[0].Departure_DateTime.substr(11, 5)
									: item.Segments[0].slice(0, 1).map((i) => {
											return i.Origin.DepTime.substr(11, 5);
									  })
								: item.departure_time} */}
						</TitleText>
					)}
				</Box>
				<Box alignItems='center' textAlign='center'>
					{loading ? (
						<Skeleton variant='text' width='100px' />
					) : (
						<BodyText
							three
							textStyle={{
								color: 'rgb(19 20 20)',
								fontSize: 12,
							}}>
							{/* {console.log(
								'Duration',
								// convertH2M(
								`${myArray[0] - myArray2[0]}hrs ${myArray[1] - myArray2[1]}min`
								// )
							)} */}
							{/* {console.log(
								'fddd',
								timedifference(item.departure_time, item.arival_time)
							)} */}
							{/* {timeConvert(
								timedifference(item.departure_time, item.arival_time)
							)} */}
							{/* {`${myArray[0]-myArray2[0]}hrs ${Math.abs(myArray[1]-myArray2[1])}min`} */}
							{/* {timeConvert(
								item.Airline_Code
									? item.Segments.length === 2
										? Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 0).map(
														(it) => it.Duration
													)[0]
												)
										  ) +
										  Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 1).map(
														(it) => it.Duration
													)[0]
												)
										  )
										: item.Segments.length === 1
										? Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 0).map(
														(it) => it.Duration
													)[0]
												)
										  )
										: item.Segments.length === 3
										? Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 0).map(
														(it) => it.Duration
													)[0]
												)
										  ) +
										  Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 1).map(
														(it) => it.Duration
													)[0]
												)
										  ) +
										  Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 2).map(
														(it) => it.Duration
													)[0]
												)
										  )
										: ''
									: item.AirlineCode
									? item.Segments[0].length == 2
										? Number(
												item.Segments[0]
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.Duration;
													})
										  ) +
										  Number(
												item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Duration;
													})
										  )
										: item.Segments[0].length == 1
										? item.Segments[0].map((i) => i.Duration)
										: item.Segments[0].length == 3
										? Number(
												item.Segments[0]
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.Duration;
													})
										  ) +
										  Number(
												item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Duration;
													})
										  ) +
										  Number(
												item.Segments[0]
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.Duration;
													})
										  )
										: ''
									: item.Supplier === 'TJK'
									? item.sI.length == 2
										? Number(
												item.sI
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.duration;
													})
										  ) +
										  Number(
												item.sI
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.duration;
													})
										  )
										: item.sI.length == 1
										? item.sI.map((i) => i.duration)
										: item.sI.length == 3
										? Number(
												item.sI
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.duration;
													})
										  ) +
										  Number(
												item.sI
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.duration;
													})
										  ) +
										  Number(
												item.sI
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.duration;
													})
										  )
										: ''
									: ''
							)} */}
						</BodyText>
					)}
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<BodyText
							two
							textStyle={{
								color: 'green',
								fontWeight: 'bold',
								fontSize: '12px',
							}}>
							Non-Stop
						</BodyText>
					)}
				</Box>
				<Box>
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText
							three
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 500,
								fontSize: 12,
							}}>
							{item.Supplier === 'TBO'
								? item.Segments[0].length == 2
									? item.Segments[0]
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.Destination.Airport.AirportCode;
											})
									: item.Segments[0].length == 3
									? item.Segments[0]
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.Destination.Airport.AirportCode;
											})
									: item.Segments[0].length == 1
									? item.Segments[0].map((it) => {
											return it.Destination.Airport.AirportCode;
									  })
									: ''
								: item.Supplier === 'ETR'
								? item.Segments.length === 1
									? item.Segments.filter((i, index) => index === 0).map(
											(it) => {
												return it.Destination;
											}
									  )
									: item.Segments.length === 2
									? item.Segments.filter((i, index) => index === 1).map(
											(it) => {
												return it.Destination;
											}
									  )
									: item.Segments.length === 3
									? item.Segments.filter((i, index) => index === 2).map(
											(it) => {
												return it.Destination;
											}
									  )
									: ''
								: item.Supplier === 'MFB'
								? item.Segments[0].Destination.Airport.AirportCode
								: item.destination}
						</TitleText>
					)}
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText three textStyle={{ fontSize: 12 }}>
							{item.Supplier === 'TBO'
								? item.Segments[0].length == 3
									? item.Segments[0]
											.filter((i, index) => index === 2)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: item.Segments[0].length == 2
									? item.Segments[0]
											.filter((i, index) => index === 1)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: item.Segments[0].length == 1
									? item.Segments[0]
											.filter((i, index) => index === 0)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: ''
								: item.Supplier === 'ETR'
								? item.Segments.length === 1
									? item.Segments.filter((i, index) => index === 0).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: item.Segments.length === 2
									? item.Segments.filter((i, index) => index === 1).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: item.Segments.length === 3
									? item.Segments.filter((i, index) => index === 2).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: ''
								: item.Supplier === 'MFB'
								? item.Segments[0].Destination.ArrTime.substr(11, 5)
								: item.arival_time}
						</TitleText>
					)}
				</Box>
				<Box alignItems='center' display='flex'>
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<Box>
							<TitleText
								textStyle={{
									fontFamily: 'Josefin Sans, sans-serif',
									fontWeight: 500,
									fontSize: 16,
								}}>
								<span style={{ marginRight: 5 }}>{'₹'}</span>
								{Number(
									Math.round(
										item.Supplier === 'TBO'
											? Number(
													Math.round(
														Number(
															Number(Math.round(item.Fare.Total_Amount)) -
																Number(Math.round(item.Fare.MFB_Discount))
														)
													)
											  )
											: item.Supplier === 'ETR'
											? Number(
													Math.round(
														Number(Math.round(item.Fares.Total_Amount))
													)
											  )
											: item.Supplier === 'MFB'
											? item.Fare.Total_Amount
											: item.totalFare
									)
								)}
							</TitleText>
							{/* {net ? (
								<span style={{ color: 'grey', fontSize: 12 }}>
									NET{' '}
									{item.Airline_Code
										? Number(
												Math.round(
													Number(
														Number(Math.round(item.Fares.Total_Amount)) -
															Number(
																Math.round(net ? item.Fares.agentMarkup : 0)
															)
													)
												)
										  )
										: item.AirlineCode
										? Number(
												Math.round(
													Number(
														Number(Math.round(item.Fare.Total_Amount)) -
															Number(Math.round(item.Fare.MFB_Discount)) -
															Number(
																Math.round(net ? item.Fare.agentMarkup : 0)
															)
													)
												)
										  )
										: ''}
								</span>
							) : (
								''
							)} */}
						</Box>
					)}

					{loading ? (
						<Skeleton variant='text' width='60px' height='60px' />
					) : (
						<>
							<Box
								onClick={() => {
									item.Supplier === 'AIQ'
										? history.push({
												pathname: '/summaryflightfd',
												state: {
													flightdetail: item,
													body: location.state.body,
													selectedSearch: selectedFDSector,
													orderID: fdresult.orderId,
												},
										  })
										: item.Supplier === 'MFB'
										? history.push({
												pathname: '/summaryflightfd',
												state: {
													flightdetail: item,
													body: location.state.body,
													selectedSearch: selectedFDSector,
													orderID: orderID.result,
												},
										  })
										: history.push({
												pathname: '/summaryflight',
												state: {
													OneflightData: item,
													body: location.state.body2,
													orderID: orderID.result,
													IDTBO:
														onewayflightdata &&
														onewayflightdata.result &&
														onewayflightdata.result.Response &&
														onewayflightdata.result.Response.TraceId,
													IDEtrav:
														onewayflightdataEtrav &&
														onewayflightdataEtrav.result &&
														onewayflightdataEtrav.result.Search_Key,
												},
										  });
								}}
								style={{
									color: 'white',
									background: 'rgb(0, 87, 94)',
									padding: '5px 12px',
									borderRadius: 5,
									marginLeft: 5,
									cursor: 'pointer',
									fontFamily: 'Josefin Sans, sans-serif',
									fontWeight: 500,
									boxShadow: '3px 3px 12px -6px rgb(0 0 0 / 70%)',
									fontSize: 12,
								}}>
								<span style={{ fontSize: 14 }}>Book Now</span>
							</Box>
							{/* {item.Airline_Code ? (
								<span>*</span>
							) : item.Supplier === 'TJK' ? (
								<span>**</span>
							) : (
								''
							)} */}
						</>
					)}
				</Box>
			</Box>

			<Box
				style={{
					padding: '6px 10px',
					background: '#f5f6f6',
					borderBottomLeftRadius: 4,
					borderBottomRightRadius: 4,
					borderTop: '1px solid #e2e2e2',
				}}
				justifyContent='space-between'
				alignItems='center'
				display='flex'>
				<span
					style={{
						color: 'white',
						marginLeft: 15,
						background: 'red',
						padding: '2px 10px',
						borderRadius: 5,
						fontSize: 10,
					}}>
					N
				</span>
			</Box>
		</>
	);
};

export default FDOnewayrow;
