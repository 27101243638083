import React, { useState, useEffect } from 'react';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Divider,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation} from 'react-router-dom';
import Drawers from '../../components/Drawers';
import {
	agentlogogetDispatch,
	walletBalanceAgentDispatch,
} from '../../reducers/UserReducer';
import Login from '../../components/reusableComponents/Login';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import Footer from './Footer';
import HotelSummaryForm from '../../form/HotelSummaryForm';
import ReviewHotelItem from './ReviewHotelItem';
import EaseBuzzHotelModal from '../../components/reusableComponents/EaseBuzzHotelModal';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiChip-sizeSmall': {
			height: 36,
		},
	},
	accodin: {
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-between',
		},
	},
	radio: {
		'& .MuiTypography-body1': {
			fontSize: 13,
		},
	},
}));
const SummaryHotel = () => {
	const [value, setValue] = React.useState('');
	const { agentlogin } = useSelector((state) => state.user);
	const theme = useTheme();
	const location = useLocation();
	console.log('first', location);
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const dispatch = useDispatch();
	console.log(value);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	function convertH2M(timeInHour) {
		console.log(timeInHour);
		var timeParts = timeInHour.split(':');
		return Number(timeParts[0]) * 60 + Number(timeParts[1]);
	}
	useEffect(() => {
		if (agentlogin && agentlogin.status === 200) {
			let logodata = {
				agent_id: agentlogin && agentlogin.result.result.id,
			};
			dispatch(agentlogogetDispatch(logodata));
			dispatch(
				walletBalanceAgentDispatch(
					agentlogin && agentlogin.result.result.email,
					agentlogin && agentlogin.result.result.id
				)
			);
		}
	}, []);

	return (
		<>
			<HeaderSection id={1} />
			<Box
				style={{
					backgroundColor: '#515b62',
					marginTop: 60,
					color: 'white',
					padding: 10,
				}}>
				<Container></Container>
			</Box>
			<Box style={{ background: '#e5eef4', paddingLeft: 30, paddingRight: 30 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={8}>
						<Box display='flex' justifyContent='space-between' mt={'30px'}>
							<Box>
								<TitleText
									textStyle={{
										fontFamily: 'unset',
										fontWeight: 500,
										fontSize: 22,
									}}>
									Review Your Booking
								</TitleText>
							</Box>
							<Box display='flex' alignItems='center'>
								{/* <i class="fa-solid fa-clock"></i> */}
								{/* <p style={{ marginLeft: 10 }}>Session Expires in</p> */}
								<Box display='flex' style={{ marginLeft: 5 }}>
									<div id='countdown-number' style={{ color: 'blue' }}></div>
								</Box>
							</Box>
						</Box>
						<Box
							style={{
								padding: 15,
								background: 'white',
								color: 'gray',
								border: '1px solid #e6e6e6',
								margin: '0 0 20px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
                                <ReviewHotelItem item={location.state.selectedHotel} i={location.state.item}/>
						</Box>
                        <Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<TitleText
								textStyle={{
									fontFamily: 'unset',
									fontWeight: 500,
									fontSize: 20,
								}}>
								Cancellation Policy
							</TitleText>
						
						</Box>
                        <Box
							style={{
								padding: 15,
								background: 'white',
								color: 'gray',
								border: '1px solid #e6e6e6',
								margin: '0 0 20px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
                                <p style={{fontFamily:'"Poppins", sans-serif'}}>{location.state.item.CancellationPolicy}</p>
                            </Box>
					
						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'>
							<TitleText
								textStyle={{
									fontFamily: 'unset',
									fontWeight: 500,
									fontSize: 20,
								}}>
								Contact Information
							</TitleText>
							
						</Box>
                       
					
							<HotelSummaryForm/>
						
                     
					</Grid>
					
					<Grid item xs={12} lg={4}>
						<Box style={{ position: 'sticky', top: 84 }}>
							<Box
								style={{
									background: 'white',
									padding: '10px 15px',
									border: '1px solid #e6e6e6',
									marginTop: '62px',
									boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
									borderRadius: '5px',
									// position: 'sticky',
									// top: 84,
									zIndex: 1,
								}}>
								<Grid container alignItems='center'>
									<Grid item xs={4}>
										Fare Details
									</Grid>
									<Grid item xs={8}>
										<Divider style={{ background: 'black' }} />
									</Grid>
								</Grid>
								<Box
									style={{
										background: 'rgb(166 185 255 / 17%)',
										padding: '5px 10px',
										margin: '10px 0 0',
										borderRadius: '15px',
									}}>
									<BodyText textStyle={{ fontSize: 13 }}>
										AMOUNT TO BE PAID
									</BodyText>
									<TitleText textStyle={{ fontSize: 18 }}>
									₹ {Number(Math.round(Number(location.state.item.Total_Amount)-Number(location.state.item.MFB_Discount)))}
									</TitleText>
									<span>(inclusive of all taxes.)</span>
								</Box>
								<Box display='flex' justifyContent='space-between' mt={'10px'}>
									<BodyText textStyle={{ fontSize: 13 }}>
										Basic Amount
										
									</BodyText>
									<TitleText textStyle={{ fontSize: 13 }}>
									₹ {Number(Math.round(Number(location.state.item.Basic_Amount)))}
									</TitleText>
								</Box>

								<Box display='flex' justifyContent='space-between'>
									<BodyText textStyle={{ fontSize: 13 }}>Total Taxes</BodyText>
									<TitleText textStyle={{ fontSize: 13 }}>
									₹ {Number(Math.round(location.state.item.Tax_Amount))}
									</TitleText>
								</Box>
								<Divider />
								{value ? (
									<Box
										display='flex'
										justifyContent='space-between'
										mt={'5px'}
										mb={'5px'}>
										<BodyText>Convenience Fees</BodyText>
										<TitleText two>Rs 200</TitleText>
									</Box>
								) : (
									''
								)}

								<Divider />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Footer />
			<EaseBuzzHotelModal/>
			<Drawers />
			<Login />
		</>
	);
};

export default SummaryHotel;
